import {
  REJECT_PENDING_REQUEST_API_REQUEST,
  REJECT_PENDING_REQUEST_API_SUCCESS,
  REJECT_PENDING_REQUEST_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function rejectPendingRequestRequest() {
  return {
    type: REJECT_PENDING_REQUEST_API_REQUEST,
    payload: true,
  };
}

function rejectPendingRequestSuccess(requests, params) {
  return {
    type: REJECT_PENDING_REQUEST_API_SUCCESS,
    payload: requests,
    params,
  };
}

function rejectPendingRequestError(err) {
  return {
    type: REJECT_PENDING_REQUEST_API_ERROR,
    payload: err,
  };
}

export function rejectPendingRequestAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(rejectPendingRequestRequest());
    return getRequestsApi
      .getRequestHistory(state, params)
      .then(data => {
        dispatch(rejectPendingRequestSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(rejectPendingRequestError(error));
        throw error;
        // return response;
      });
  };
}

export default { rejectPendingRequestAction };
