import { useDispatch, useSelector } from 'react-redux';
import { apiCallWizard } from '../../../actions/wizards';
import { getAccessToken } from '../../../storage/accessToken';
import { customerType, statusOk } from '../../../utils/variables';
import { getAllData, validateAccess } from '../functions';

export const useValidateAccess = inputValue => {
  const accessToken = getAccessToken();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const customerVerified =
    useSelector(state => state?.auth?.usertype === customerType) && accessToken;

  const valid = validateAccess(inputValue, isAuthenticated, customerVerified);

  return valid;
};

export const useOnChangeApiCalls = storeState => {
  const dispatch = useDispatch();
  // const storeState = useSelector(state => state);
  return ({ apiCalls, onErrorCall = () => {}, onSuccessCall = () => {} }) => {
    if (apiCalls && apiCalls.length > 0 && !apiCalls.includes(undefined)) {
      getAllData(apiCalls, apiCallWizard.apiCallWizardAction, dispatch, storeState)
        .then(resp => {
          // // console.log(resp);
          const responseTypeSuccess =
            resp && resp.length > 0
              ? resp.every(x => x?.responseType === statusOk || false)
              : false;
          // console.log('responseTypeSuccess: ', responseTypeSuccess);
          // // console.log('resetWizardData: ', resetWizardData);
          // setLoading(false);
          if (responseTypeSuccess || !resp) {
            onSuccessCall();
          } else {
            onErrorCall();
          }
        })
        .catch(e => {
          console.log('api call error: ', e);
          onErrorCall();
          // setLoading(false);
        });
    } else {
      onSuccessCall();
    }
  };
};
