import {
  GET_INVEST_REQUEST_SETTING_API_REQUEST,
  GET_INVEST_REQUEST_SETTING_API_SUCCESS,
  GET_INVEST_REQUEST_SETTING_API_ERROR,
} from '../../../constants/requestActionNames';
import { getInvestRequestSettingApi } from '../../../apis/requests';
import { getInvestRequestConfigUrl } from '../../../apis/requests/getInvestRequestSettingApi';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';

function getInvestRequestSettingRequest() {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_REQUEST,
    payload: true,
  };
}

function getInvestRequestSettingSuccess(data, params, cacheKey) {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_SUCCESS,
    payload: data,
    params,
    cacheKey,
  };
}

function getInvestRequestSettingError(err) {
  return {
    type: GET_INVEST_REQUEST_SETTING_API_ERROR,
    payload: err,
  };
}

export function getInvestRequestSettingAction(params) {
  // const response = getPayloadResponse();

  return (dispatch, getState) => {
    const state = getState();

    const cacheKey = generateCacheKey({
      path: getInvestRequestConfigUrl,
      cacheParams: params,
      state,
    });

    const { isCacheValid, cache } = checkCacheValid(getState, 'requests', cacheKey);
    if (isCacheValid) {
      dispatch(getInvestRequestSettingSuccess(cache, params, cacheKey));
      return Promise.resolve(cache);
    }
    // console.log(state);
    dispatch(getInvestRequestSettingRequest());
    return getInvestRequestSettingApi
      .getInvestRequestSetting(state, params)
      .then(data => {
        dispatch(getInvestRequestSettingSuccess(data, params, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getInvestRequestSettingError(error));
        throw error;
        // return response;
      });
  };
}

export default { getInvestRequestSettingAction };
