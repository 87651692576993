import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
// constants
import {
  UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY,
  GET_PENDING_CLAIMS_API_REQUEST,
  GET_PENDING_CLAIMS_API_SUCCESS,
  GET_PENDING_CLAIMS_API_ERROR,
  GET_NEXT_PENDING_CLAIMS_SUCCESS,
  RESET_PENDING_CLAIMS_SUCCESS,
  DELETE_PENDING_CLAIM_API_ERROR,
  DELETE_PENDING_CLAIM_API_REQUEST,
  DELETE_PENDING_CLAIM_API_SUCCESS,
  GET_PENDING_CLAIM_API_REQUEST,
  GET_PENDING_CLAIM_API_SUCCESS,
  GET_PENDING_CLAIM_API_ERROR,
  GET_NEXT_PENDING_CLAIM_SUCCESS,
  RESET_PARTIALLY_FILLED_CLAIM,
} from '../../constants/pendingClaimActionNames';
import { statusOk } from '../../utils/variables';
import { removeItemOnce } from '../../utils/functions';
import payloadData from './payloadData';
import { CLEAR_INCOMPLETE_CLAIMS_CACHE } from '../../constants/miscActionNames';
// utility
import { createPartialClaim } from '../../utils/functions/backend-to-frontend-mappers/claims';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  total: {},
  [DEFAULT_CACHE_KEY]: null,
  totalRows: 0,
  currentCacheKey: '',
};

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ claims: payloadData, count: 100000 }] };
};

const createPayload = (payloadArray, lobTypes, cacheKey, body) => {
  if (!Array.isArray(payloadArray)) return null;

  const newClaims = payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      return lobIndex;
    });

    const claim = {
      ...object,
      cacheKey,
      body,
      lob: lobObj?.lob,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      color: lobObj?.color,
    };
    return claim;
  });

  return newClaims;
};

function pendingClaimsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_CLAIMS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_PENDING_CLAIMS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      const cacheKey = action?.cacheKey;
      const response = action?.payload;

      const rowPayload = createPayload(
        response?.payload?.[0]?.results || [],
        lobTypes,
        cacheKey,
        body,
      );

      const newRows = rowPayload;

      // console.log('body: ', body);
      const newTotal = response?.payload[0]?.total || 0;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        searchKeys: [],
        allRows: newRows,
        count,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        // incomingRows,
        // claims: claimsArray,
      };
    }

    case GET_PENDING_CLAIMS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case GET_NEXT_PENDING_CLAIMS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case GET_PENDING_CLAIM_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_PENDING_CLAIM_API_SUCCESS: {
      const response = action?.payload?.payload;
      const individualPendingClaim = createPartialClaim(response)?.draft;
      return {
        ...state,
        isPending: false,
        isSuccess: true,
        isError: false,
        response: action.payload.payload,
        individualPendingClaim,
      };
    }
    case GET_PENDING_CLAIM_API_ERROR:
      return {
        ...state,
        isPending: false,
        isSuccess: false,
        isError: true,
        // signUpBioError: action.payload,
      };
    case GET_NEXT_PENDING_CLAIM_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;
      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    // case CLEAR__CLAIMS_CACHE: {
    //   return {
    //     ...state,
    //     [DEFAULT_CACHE_KEY]: null,
    //   };
    // }

    case RESET_PENDING_CLAIMS_SUCCESS: {
      return {
        ...state,
        total: initialState.total,
        allRows: initialState.allRows,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    // deleting a draft/pending-claim handlers
    case DELETE_PENDING_CLAIM_API_REQUEST: {
      return {
        ...state,
        isDeletePendingClaimPending: true,
        isDeletePendingClaimError: false,
        isDeletePendingClaimSuccess: false,
      };
    }
    case DELETE_PENDING_CLAIM_API_SUCCESS: {
      const deletedItem = action?.payload || {};
      deletedItem._id = deletedItem?._id || deletedItem?.claimId;
      // console.log('deleteDigitalSalesApplication: ', deleteDigitalSalesApplication);
      const existing = [...state.allRows];
      // console.log('existingApplications: ', existingApplications);
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(existing, deletedItem, '_id');
      // console.log(updatedList);
      // console.log("Updated List", updatedList);

      return {
        ...state,
        deletedItem,
        isDeletePendingClaimPending: false,
        isDeletePendingClaimError: false,
        isDeletePendingClaimSuccess: true,
        allRows: updatedList,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    case CLEAR_INCOMPLETE_CLAIMS_CACHE: {
      return {
        ...state,
        [DEFAULT_CACHE_KEY]: null,
        allRows: initialState.allRows,
        total: initialState.total,
      };
    }

    case RESET_PARTIALLY_FILLED_CLAIM: {
      return {
        ...state,
        response: null,
      };
    }

    case DELETE_PENDING_CLAIM_API_ERROR: {
      const deletedItem = action?.payload;
      return {
        ...state,
        isDeletePendingClaimPending: false,
        isDeletePendingClaimError: true,
        isDeletePendingClaimSuccess: false,
        deletedPendingClaim: deletedItem,
      };
    }
    // case SELECT_CLIENTS_SUCCESS: {
    //   return {
    //     ...state,
    //     [DEFAULT_CACHE_KEY]: null,
    //     total: initialState.total,
    //     allRows: initialState.allRows,
    //   };
    // }

    // case RESET_INFINITE_SCROLL_SUCCESS: {
    //   return {
    //     ...state,

    //     nextItemCount: 0,
    //     nextScrollCount: 0,
    //     nextStartIndex: 0,
    //   };
    // }

    // case GET_CLAIM_DETAILS_API_REQUEST:
    //   return {
    //     ...state,
    //     isDetailsPending: true,
    //     isDetailsError: false,
    //     isDetailsSuccess: false,
    //   };
    // case GET_CLAIM_DETAILS_API_SUCCESS: {
    //   const details = action?.payload;
    //   const newClaim = details?.payload;
    //   const { vehicleIdentifier, indexNo } = newClaim;
    //   const claimId = action?.params?.claimNo;
    //   const allRows = state?.allRows || [];
    //   const foundIndex = allRows?.findIndex(x => x?.claimNo === claimId);
    //   const claimRow = allRows[foundIndex];
    //   claimRow.vehicleIdentifier = vehicleIdentifier;
    //   allRows[foundIndex] = claimRow;
    //   // console.log('Vehicle Number', row);
    //   return {
    //     ...state,
    //     isDetailsPending: false,
    //     isDetailsError: false,
    //     isDetailsSuccess: true,
    //     details,
    //     identifier: vehicleIdentifier || indexNo,
    //     allRows,
    //   };
    // }
    // case GET_CLAIM_DETAILS_API_ERROR:
    //   return {
    //     ...state,
    //     isDetailsPending: false,
    //     isDetailsError: true,
    //     isDetailsSuccess: false,
    //   };

    default:
      return state;
  }
}

export default pendingClaimsReducer;

/*

    */
