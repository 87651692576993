/* eslint-disable no-nested-ternary */
import queryString from 'query-string';
import Fuse from 'fuse.js';
import { updateQueryHistory, fuseOptions, trimOneChar } from './functions';
import { uuidv4, getKeys, reduceKeys } from '../utils/functions';

const historyList = [];

export const portfolioInvestmentDetailOptions = (state, account) => {
  const { location } = state?.router || {};

  // const { parsed, history } = updateQueryHistory(historyList, location);
  // historyList = [...history];

  const parsed = queryString.parse(location?.search);
  // // console.log('location: ', location?.query);
  // // console.log('parsed: ', parsed2);

  // // console.log('holdings: ', holdings);
  const initialHoldings = account?.holdings || [];
  // // console.log('account: ', account);

  const holdings = initialHoldings?.filter(el => {
    return el != null && el?.quantity !== undefined; // && el.quantity > 0
  });

  const assetTypeValues = holdings
    ?.map(value => value?.assetType)
    ?.filter((value, index, _arr) => _arr.indexOf(value) === index);

  // // console.log('assetTypeValues: ', assetTypeValues);

  const currencyValues = holdings
    ?.map(value => value?.currency)
    ?.filter((value, index, _arr) => _arr.indexOf(value) === index);

  // // console.log('currencyValues: ', currencyValues);

  // // console.log('categoryArray: ', categoryArray);

  const currencyArray = Array.isArray(parsed?.holdingCurrency)
    ? parsed?.holdingCurrency
    : typeof parsed?.holdingCurrency === 'string' && parsed?.holdingCurrency.length > 0
    ? parsed?.holdingCurrency.split(',')
    : [...currencyValues];

  const assetTypeArray = Array.isArray(parsed?.assetType)
    ? parsed?.assetType
    : typeof parsed?.assetType === 'string' && parsed?.assetType.length > 0
    ? parsed?.assetType.split(',')
    : [...assetTypeValues];

  const options = {
    holdingCurrency: currencyArray || [],
    assetType: assetTypeArray || [],
    holdingCurrencies: currencyValues || [],
    assetTypes: assetTypeValues || [],
    search: parsed?.search || '',
    showFilters: {
      holdingCurrency: true,
      assetType: true,
    },
  };

  return options;
};

export const portfolioInvestmentDetailFilter = (state, account) => {
  // const { location } = state.router;
  const holdings = account?.holdings || [];
  const closingBalance = account?.closingBalance;
  const preferredCurrencyType = account?.reportingCurrency;
  // // console.log('account: ', account);
  // const parsed = queryString.parse(location.search);
  const options = portfolioInvestmentDetailOptions(state, account);
  // // console.log(options);
  // gives {lob:'Health', product:'id'}
  // e.assetType === type ||
  let filteredInvestments = [];
  if (options.holdingCurrency && options.holdingCurrency.length > 0) {
    filteredInvestments = options.holdingCurrency.map(currencyType => {
      let filteredHoldings = holdings.filter(
        holding => holding.currency?.toLowerCase() === currencyType?.toLowerCase(),
      );

      filteredHoldings = filteredHoldings.filter(e =>
        options.assetType.some(
          asset => asset?.toLowerCase() === e.assetType?.toLowerCase(),
        ),
      );

      if (options.search && options.search.length > 0 && filteredHoldings.length > 0) {
        const obj =
          filteredHoldings && filteredHoldings.length > 0 ? filteredHoldings[0] : {};
        // const reduceObj = reduceKeys(obj);

        const searchKeys = getKeys(obj);
        const fuse = new Fuse(filteredHoldings, fuseOptions(searchKeys));
        let searchResults = fuse.search(options.search);

        if (!searchResults || searchResults.length === 0) {
          options.search = `*${options.search}*`;
          searchResults = fuse.search(options.search); // .replace(/ /g, '|')
          options.search = trimOneChar(options.search, '*');
        }
        // // console.log('searchResults: ', searchResults);

        filteredHoldings = searchResults.map(result => result.item);
      }

      if (filteredHoldings.length === 0) return null;
      // console.log('filteredHoldings: ', filteredHoldings);
      const slimHoldings = filteredHoldings.filter(el => {
        return (
          el !== null &&
          el.quantity !== undefined &&
          el.valueHoldingCurrency !== 'N/A' &&
          el?.valuePreferredCurrency !== 'N/A'
        ); // && el.quantity > 0;
      });
      // console.log('slimHoldings: ', slimHoldings);
      const summaryAssets = options.assetType.map(asset => {
        const subFilteredHoldings = slimHoldings.filter(e => asset === e.assetType);

        const subTotalValueHoldingCurrency = subFilteredHoldings?.reduce(
          (acc, object) =>
            acc + object?.valueHoldingCurrency + object?.accruedInterestSecCurrency,
          0,
        );
        const subTotalValuePreferredCurrency = subFilteredHoldings?.reduce(
          (acc, object) =>
            acc + object?.valuePreferredCurrency + object?.accruedInterestPrefCurrency,
          0,
        );

        const subPercentOfClosingBalance = parseFloat(
          (subTotalValuePreferredCurrency / closingBalance) * 100,
        );

        return {
          currencyType,
          preferredCurrencyType,
          assetType: asset,
          subTotalValueHoldingCurrency,
          subTotalValuePreferredCurrency,
          subPercentOfClosingBalance,
          holdingsCount: subFilteredHoldings.length,
        };
      });

      const totalValueHoldingCurrency = slimHoldings?.reduce(
        (acc, object) =>
          acc + object?.valueHoldingCurrency + object?.accruedInterestSecCurrency,
        0,
      );
      const totalValuePreferredCurrency = slimHoldings?.reduce(
        (acc, object) =>
          acc + object?.valuePreferredCurrency + object?.accruedInterestPrefCurrency,
        0,
      );

      const percentOfClosingBalance = parseFloat(
        (totalValuePreferredCurrency / closingBalance) * 100,
      );
      // // console.log('percentOfClosingBalance: ', percentOfClosingBalance);
      const result = {
        id: uuidv4(),
        currencyType,
        preferredCurrencyType,
        holdings: slimHoldings,
        options,
        totalValueHoldingCurrency:
          slimHoldings?.length === 0 ? 'N/A' : totalValueHoldingCurrency,
        totalValuePreferredCurrency:
          slimHoldings?.length === 0 ? 'N/A' : totalValuePreferredCurrency,
        percentOfClosingBalance,
        summaryAssets,
        accountId: account?.accountId,
      };
      // // console.log('result: ', result);
      return result;
    });
    // console.log('filteredInvestments: ', filteredInvestments);
    filteredInvestments = filteredInvestments.filter(el => {
      return el != null && el?.totalValueHoldingCurrency !== 'N/A';
    });
  }
  return filteredInvestments;
};

export const portfolioInvestmentHoldingFilter = (assets, investment) => {
  // const { location } = state.router;
  const holdings = investment?.holdings;

  return holdings.filter(e =>
    assets.some(asset => asset?.toLowerCase() === e.assetType?.toLowerCase()),
  );
};

export const portfolioInvestmentHoldingFind = (state, account, securityCode) => {
  // const { location } = state.router;
  const holdings = account?.holdings || [];

  return holdings.find(
    e => securityCode?.toLowerCase() === e?.securityCode?.toLowerCase(),
  );
};

export default {
  portfolioInvestmentDetailOptions,
  portfolioInvestmentDetailFilter,
  portfolioInvestmentHoldingFilter,
  portfolioInvestmentHoldingFind,
};

// return options.mode.some(option => {

//     return e?.policyData.some(policy => {
//       return policy.mode === option;
//     });
//   })

// // console.log('typeArray: ', typeArray);

/*
  let filteredTypeArray = [];
  if (typeArray && categoryArray) {
    filteredTypeArray = typeArray.filter(type =>
      categoryArray.some(category => {
        if (category === assetTypeId) {
          return assetTypeValues.some(asset => asset === type);
        }
        if (category === currencyId) {
          return currencyValues.some(currency => currency === type);
        }
        return false;
      }),
    );
  }
  */

// // console.log('filteredTypeArray: ', filteredTypeArray);

/*
  const types = categoryArray.map(category => {
    if (category === assetTypeId && assetTypeValues) {
      return assetTypeValues.map(asset => asset);
    }
    if (category === currencyId && currencyValues) {
      return currencyValues.map(currency => currency);
    }
    return false;
  });
  */

// // console.log('types: ', types);
// types: types.flat() || [],
