export const countriesWithAlpha2 = [
    {
        name: 'Dutch',
        value: 'NL',
    },
    {
        name: 'Afghan',
        value: 'AF',
    },
    {
        name: 'Albanian',
        value: 'AL',
    },
    {
        name: 'Algerian',
        value: 'DZ',
    },
    {
        name: 'American Samoan',
        value: 'AS',
    },
    {
        name: 'Andorran',
        value: 'AD',
    },
    {
        name: 'Angolan',
        value: 'AO',
    },
    {
        name: 'Anguillian',
        value: 'AI',
    },
    {
        name: 'Antarctican',
        value: 'AQ',
    },
    {
        name: 'Antiguan, Barbudan',
        value: 'AG',
    },
    {
        name: 'Argentine',
        value: 'AR',
    },
    {
        name: 'Armenian',
        value: 'AM',
    },
    {
        name: 'Australian',
        value: 'AU',
    },
    {
        name: 'Austrian',
        value: 'AT',
    },
    {
        name: 'Azerbaijani',
        value: 'AZ',
    },
    {
        name: 'Bahamian',
        value: 'BS',
    },
    {
        name: 'Bahraini',
        value: 'BH',
    },
    {
        name: 'Bangladeshi',
        value: 'BD',
    },
    {
        name: 'Barbadian',
        value: 'BB',
    },
    {
        name: 'Belarusian',
        value: 'BY',
    },
    {
        name: 'Belgian',
        value: 'BE',
    },
    {
        name: 'Belizean',
        value: 'BZ',
    },
    {
        name: 'Beninese',
        value: 'BJ',
    },
    {
        name: 'Bermudian',
        value: 'BM',
    },
    {
        name: 'Bhutanese',
        value: 'BT',
    },
    {
        name: 'Bolivian',
        value: 'BO',
    },
    {
        name: 'Bosnian, Herzegovinian',
        value: 'BA',
    },
    {
        name: 'Motswana',
        value: 'BW',
    },
    {
        name: 'Brazilian',
        value: 'BR',
    },
    {
        name: 'Bruneian',
        value: 'BN',
    },
    {
        name: 'Bulgarian',
        value: 'BG',
    },
    {
        name: 'Burkinabe',
        value: 'BF',
    },
    {
        name: 'Burundian',
        value: 'BI',
    },
    {
        name: 'Cambodian',
        value: 'KH',
    },
    {
        name: 'Cameroonian',
        value: 'CM',
    },
    {
        name: 'Canadian',
        value: 'CA',
    },
    {
        name: 'Cape Verdian',
        value: 'CV',
    },
    {
        name: 'Caymanian',
        value: 'KY',
    },
    {
        name: 'Central African',
        value: 'CF',
    },
    {
        name: 'Chadian',
        value: 'TD',
    },
    {
        name: 'Chilean',
        value: 'CL',
    },
    {
        name: 'Chinese',
        value: 'CN',
    },
    {
        name: 'Christmas Islander',
        value: 'CX',
    },
    {
        name: 'Cocos Islander',
        value: 'CC',
    },
    {
        name: 'Colombian',
        value: 'CO',
    },
    {
        name: 'Comoran',
        value: 'KM',
    },
    {
        name: 'Cook Islander',
        value: 'CK',
    },
    {
        name: 'Costa Rican',
        value: 'CR',
    },
    {
        name: 'Croatian',
        value: 'HR',
    },
    {
        name: 'Cuban',
        value: 'CU',
    },
    {
        name: 'Cypriot',
        value: 'CY',
    },
    {
        name: 'Czech',
        value: 'CZ',
    },
    {
        name: 'Congolese',
        value: 'CD',
    },
    {
        name: 'Danish',
        value: 'DK',
    },
    {
        name: 'Djibouti',
        value: 'DJ',
    },
    {
        name: 'Dominican',
        value: 'DO',
    },
    {
        name: 'Ecuadorean',
        value: 'EC',
    },
    {
        name: 'Egyptian',
        value: 'EG',
    },
    {
        name: 'Salvadoran',
        value: 'SV',
    },
    {
        name: 'Equatorial Guinean',
        value: 'GQ',
    },
    {
        name: 'Eritrean',
        value: 'ER',
    },
    {
        name: 'Estonian',
        value: 'EE',
    },
    {
        name: 'Swazi',
        value: 'SZ',
    },
    {
        name: 'Ethiopian',
        value: 'ET',
    },
    {
        name: 'Falkland Islander',
        value: 'FK',
    },
    {
        name: 'Faroese',
        value: 'FO',
    },
    {
        name: 'Fijian',
        value: 'FJ',
    },
    {
        name: 'Finnish',
        value: 'FI',
    },
    {
        name: 'French',
        value: 'FR',
    },
    {
        name: 'Guianan',
        value: 'GF',
    },
    {
        name: 'French Polynesian',
        value: 'PF',
    },
    {
        name: 'Gabonese',
        value: 'GA',
    },
    {
        name: 'Gambian',
        value: 'GM',
    },
    {
        name: 'Georgian',
        value: 'GE',
    },
    {
        name: 'German',
        value: 'DE',
    },
    {
        name: 'Ghanaian',
        value: 'GH',
    },
    {
        name: 'Gibraltar',
        value: 'GI',
    },
    {
        name: 'Greek',
        value: 'GR',
    },
    {
        name: 'Greenlandic',
        value: 'GL',
    },
    {
        name: 'Grenadian',
        value: 'GD',
    },
    {
        name: 'Guadeloupian',
        value: 'GP',
    },
    {
        name: 'Guamanian',
        value: 'GU',
    },
    {
        name: 'Guatemalan',
        value: 'GT',
    },
    {
        name: 'Channel Islander',
        value: 'GG',
    },
    {
        name: 'Guinean',
        value: 'GN',
    },
    {
        name: 'Guinea-Bissauan',
        value: 'GW',
    },
    {
        name: 'Guyanese',
        value: 'GY',
    },
    {
        name: 'Haitian',
        value: 'HT',
    },
    {
        name: 'Heard and McDonald Islander',
        value: 'HM',
    },
    {
        name: 'Honduran',
        value: 'HN',
    },
    {
        name: 'Hong Konger',
        value: 'HK',
    },
    {
        name: 'Hungarian',
        value: 'HU',
    },
    {
        name: 'Icelander',
        value: 'IS',
    },
    {
        name: 'Indian',
        value: 'IN',
    },
    {
        name: 'Indonesian',
        value: 'ID',
    },
    {
        name: 'Iranian',
        value: 'IR',
    },
    {
        name: 'Iraqi',
        value: 'IQ',
    },
    {
        name: 'Irish',
        value: 'IE',
    },
    {
        name: 'Manx',
        value: 'IM',
    },
    {
        name: 'Israeli',
        value: 'IL',
    },
    {
        name: 'Italian',
        value: 'IT',
    },
    {
        name: 'Ivorian',
        value: 'CI',
    },
    {
        name: 'Jamaican',
        value: 'JM',
    },
    {
        name: 'Japanese',
        value: 'JP',
    },
    {
        name: 'Jordanian',
        value: 'JO',
    },
    {
        name: 'Kazakhstani',
        value: 'KZ',
    },
    {
        name: 'Kenyan',
        value: 'KE',
    },
    {
        name: 'I-Kiribati',
        value: 'KI',
    },
    {
        name: 'Kosovar',
        value: 'XK',
    },
    {
        name: 'Kuwaiti',
        value: 'KW',
    },
    {
        name: 'Kirghiz',
        value: 'KG',
    },
    {
        name: 'Laotian',
        value: 'LA',
    },
    {
        name: 'Latvian',
        value: 'LV',
    },
    {
        name: 'Lebanese',
        value: 'LB',
    },
    {
        name: 'Mosotho',
        value: 'LS',
    },
    {
        name: 'Liberian',
        value: 'LR',
    },
    {
        name: 'Libyan',
        value: 'LY',
    },
    {
        name: 'Liechtensteiner',
        value: 'LI',
    },
    {
        name: 'Lithuanian',
        value: 'LT',
    },
    {
        name: 'Luxembourger',
        value: 'LU',
    },
    {
        name: 'Macanese',
        value: 'MO',
    },
    {
        name: 'Malagasy',
        value: 'MG',
    },
    {
        name: 'Malawian',
        value: 'MW',
    },
    {
        name: 'Malaysian',
        value: 'MY',
    },
    {
        name: 'Maldivan',
        value: 'MV',
    },
    {
        name: 'Malian',
        value: 'ML',
    },
    {
        name: 'Maltese',
        value: 'MT',
    },
    {
        name: 'Marshallese',
        value: 'MH',
    },
    {
        name: 'Mauritanian',
        value: 'MR',
    },
    {
        name: 'Mauritian',
        value: 'MU',
    },
    {
        name: 'Mahoran',
        value: 'YT',
    },
    {
        name: 'Mexican',
        value: 'MX',
    },
    {
        name: 'Micronesian',
        value: 'FM',
    },
    {
        name: 'Moldovan',
        value: 'MD',
    },
    {
        name: 'Monegasque',
        value: 'MC',
    },
    {
        name: 'Mongolian',
        value: 'MN',
    },
    {
        name: 'Montenegrin',
        value: 'ME',
    },
    {
        name: 'Montserratian',
        value: 'MS',
    },
    {
        name: 'Moroccan',
        value: 'MA',
    },
    {
        name: 'Mozambican',
        value: 'MZ',
    },
    {
        name: 'Burmese',
        value: 'MM',
    },
    {
        name: 'Namibian',
        value: 'NA',
    },
    {
        name: 'Nauruan',
        value: 'NR',
    },
    {
        name: 'Nepalese',
        value: 'NP',
    },
    {
        name: 'New Caledonian',
        value: 'NC',
    },
    {
        name: 'New Zealander',
        value: 'NZ',
    },
    {
        name: 'Nicaraguan',
        value: 'NI',
    },
    {
        name: 'Nigerien',
        value: 'NE',
    },
    {
        name: 'Nigerian',
        value: 'NG',
    },
    {
        name: 'Niuean',
        value: 'NU',
    },
    {
        name: 'Norfolk Islander',
        value: 'NF',
    },
    {
        name: 'North Korean',
        value: 'KP',
    },
    {
        name: 'Macedonian',
        value: 'MK',
    },
    {
        name: 'Norwegian',
        value: 'NO',
    },
    {
        name: 'Omani',
        value: 'OM',
    },
    {
        name: 'Pakistani',
        value: 'PK',
    },
    {
        name: 'Palauan',
        value: 'PW',
    },
    {
        name: 'Palestinian',
        value: 'PS',
    },
    {
        name: 'Panamanian',
        value: 'PA',
    },
    {
        name: 'Papua New Guinean',
        value: 'PG',
    },
    {
        name: 'Paraguayan',
        value: 'PY',
    },
    {
        name: 'Peruvian',
        value: 'PE',
    },
    {
        name: 'Filipino',
        value: 'PH',
    },
    {
        name: 'Pitcairn Islander',
        value: 'PN',
    },
    {
        name: 'Polish',
        value: 'PL',
    },
    {
        name: 'Portuguese',
        value: 'PT',
    },
    {
        name: 'Puerto Rican',
        value: 'PR',
    },
    {
        name: 'Qatari',
        value: 'QA',
    },
    {
        name: 'Romanian',
        value: 'RO',
    },
    {
        name: 'Russian',
        value: 'RU',
    },
    {
        name: 'Rwandan',
        value: 'RW',
    },
    {
        name: 'Saint Barthélemy Islander',
        value: 'BL',
    },
    {
        name: 'Saint Helenian',
        value: 'SH',
    },
    {
        name: 'Kittitian or Nevisian',
        value: 'KN',
    },
    {
        name: 'Saint Lucian',
        value: 'LC',
    },
    {
        name: 'Saint Vincentian',
        value: 'VC',
    },
    {
        name: 'Samoan',
        value: 'WS',
    },
    {
        name: 'Sammarinese',
        value: 'SM',
    },
    {
        name: 'Saudi Arabian',
        value: 'SA',
    },
    {
        name: 'Senegalese',
        value: 'SN',
    },
    {
        name: 'Serbian',
        value: 'RS',
    },
    {
        name: 'Seychellois',
        value: 'SC',
    },
    {
        name: 'Sierra Leonean',
        value: 'SL',
    },
    {
        name: 'Singaporean',
        value: 'SG',
    },
    {
        name: 'Slovak',
        value: 'SK',
    },
    {
        name: 'Slovene',
        value: 'SI',
    },
    {
        name: 'Solomon Islander',
        value: 'SB',
    },
    {
        name: 'Somali',
        value: 'SO',
    },
    {
        name: 'South African',
        value: 'ZA',
    },
    {
        name: 'South Georgian South Sandwich Islander',
        value: 'GS',
    },
    {
        name: 'South Korean',
        value: 'KR',
    },
    {
        name: 'South Sudanese',
        value: 'SS',
    },
    {
        name: 'Spanish',
        value: 'ES',
    },
    {
        name: 'Sri Lankan',
        value: 'LK',
    },
    {
        name: 'Sudanese',
        value: 'SD',
    },
    {
        name: 'Surinamer',
        value: 'SR',
    },
    {
        name: 'Swedish',
        value: 'SE',
    },
    {
        name: 'Swiss',
        value: 'CH',
    },
    {
        name: 'Syrian',
        value: 'SY',
    },
    {
        name: 'Sao Tomean',
        value: 'ST',
    },
    {
        name: 'Taiwanese',
        value: 'TW',
    },
    {
        name: 'Tadzhik',
        value: 'TJ',
    },
    {
        name: 'Tanzanian',
        value: 'TZ',
    },
    {
        name: 'Thai',
        value: 'TH',
    },
    {
        name: 'East Timorese',
        value: 'TL',
    },
    {
        name: 'Togolese',
        value: 'TG',
    },
    {
        name: 'Tokelauan',
        value: 'TK',
    },
    {
        name: 'Tongan',
        value: 'TO',
    },
    {
        name: 'Trinidadian',
        value: 'TT',
    },
    {
        name: 'Tunisian',
        value: 'TN',
    },
    {
        name: 'Turkish',
        value: 'TR',
    },
    {
        name: 'Turkmen',
        value: 'TM',
    },
    {
        name: 'Turks and Caicos Islander',
        value: 'TC',
    },
    {
        name: 'Tuvaluan',
        value: 'TV',
    },
    {
        name: 'Ugandan',
        value: 'UG',
    },
    {
        name: 'Ukrainian',
        value: 'UA',
    },
    {
        name: 'Emirati',
        value: 'AE',
    },
    {
        name: 'British',
        value: 'GB',
    },
    {
        name: 'American',
        value: 'US',
    },
    {
        name: 'Virgin Islander',
        value: 'VI',
    },
    {
        name: 'Uruguayan',
        value: 'UY',
    },
    {
        name: 'Uzbekistani',
        value: 'UZ',
    },
    {
        name: 'Ni-Vanuatu',
        value: 'VU',
    },
    {
        name: 'Vatican',
        value: 'VA',
    },
    {
        name: 'Venezuelan',
        value: 'VE',
    },
    {
        name: 'Vietnamese',
        value: 'VN',
    },
    {
        name: 'Wallis and Futuna Islander',
        value: 'WF',
    },
    {
        name: 'Sahrawi',
        value: 'EH',
    },
    {
        name: 'Yemeni',
        value: 'YE',
    },
    {
        name: 'Zambian',
        value: 'ZM',
    },
    {
        name: 'Zimbabwean',
        value: 'ZW',
    },
    {
        name: 'Ålandish',
        value: 'AX',
    },
]