import {
  GET_INVESTMENT_CALCULATIONS_API_REQUEST,
  GET_INVESTMENT_CALCULATIONS_API_SUCCESS,
  GET_INVESTMENT_CALCULATIONS_API_ERROR,
} from '../../../constants/portfolio/investmentActionNames';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getInvestmentCalculationsUrl } from '../../../apis/portfolio/getInvestmentsApi';

// import { api } from '../../../utils/variables';
import { getInvestmentsApi } from '../../../apis/portfolio';

function getInvestmentCalculationsError(err, params) {
  return {
    type: GET_INVESTMENT_CALCULATIONS_API_ERROR,
    payload: err,
    params,
  };
}

function getInvestmentCalculationsRequest(data, params) {
  return {
    type: GET_INVESTMENT_CALCULATIONS_API_REQUEST,
    payload: data,
    params,
  };
}
function getInvestmentCalculationsSuccess(data, params, cacheKey) {
  return {
    type: GET_INVESTMENT_CALCULATIONS_API_SUCCESS,
    payload: data,
    params,
    cacheKey,
  };
}

export function getInvestmentCalculationsAction(params, forceReload = false) {
  // const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    const cacheKey = generateCacheKey({
      path: getInvestmentCalculationsUrl,
      cacheParams: params,
      state,
    });

    const { isCacheValid } = checkCacheValid(getState, 'investments', cacheKey);
    if (isCacheValid && !forceReload) {
      dispatch(getInvestmentCalculationsSuccess(null, params, cacheKey));
      return Promise.resolve(null);
    }

    dispatch(getInvestmentCalculationsRequest(true, params));
    return getInvestmentsApi
      .getInvestmentCalculations(state, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getInvestmentCalculationsSuccess(data, params, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getInvestmentCalculationsError(error, params));
        throw error;
      });
  };
}

export default {
  getInvestmentCalculationsAction,
};
