import { routerGetCall } from '../../axios';

export const getInsuranceUrl = `/accounts/viewPortfolio/insurance`;
export const getInsuranceSummaryUrl = `/accounts/policies/summary`;
export const singlePolicyUrl = `/accounts/policies`;

const getInsurances = (state, params) => {
  return routerGetCall(state, getInsuranceUrl, params);
};

const getSingleInsurance = (state, params) => {
  const url = `${singlePolicyUrl}/${params.trimPolicyId}`;
  return routerGetCall(state, url);
};

const getInsuranceFilterSettings = (state, params) => {
  const url = getInsuranceSummaryUrl;
  return routerGetCall(state, url, params);
};

const getPoliciesSummary = (state, params) => {
  const url = getInsuranceSummaryUrl;
  return routerGetCall(state, url, params);
};

export default {
  getInsurances,
  getInsuranceFilterSettings,
  getSingleInsurance,
  getPoliciesSummary,
};
