import {
  CLEAR_PORTFOLIO_CACHE,
  CLEAR_RECEIPTS_CACHE,
  CLEAR_QUOTES_CACHE,
  CLEAR_CLAIMS_CACHE,
  CLEAR_INCOMPLETE_CLAIMS_CACHE,
  CLEAR_APPLICATIONS_CACHE,
  CLEAR_CART_CACHE,
  CLEAR_REQUESTS_CACHE,
  CLEAR_RECURRING_PAYMENTS_CACHE,
  CLEAR_PAYMENT_CARDS_CACHE,
} from '../../../constants/miscActionNames';

export function clearPortfolioCache() {
  return {
    type: CLEAR_PORTFOLIO_CACHE,
  };
}
export function clearReceiptsCache() {
  return {
    type: CLEAR_RECEIPTS_CACHE,
  };
}
export function clearQuotesCache() {
  return {
    type: CLEAR_QUOTES_CACHE,
  };
}
export function clearClaimsCache() {
  return {
    type: CLEAR_CLAIMS_CACHE,
  };
}
export function clearIncompleteClaimsCache() {
  return {
    type: CLEAR_INCOMPLETE_CLAIMS_CACHE,
  };
}
export function clearApplicationsCache() {
  return {
    type: CLEAR_APPLICATIONS_CACHE,
  };
}
export function clearCartCache() {
  return {
    type: CLEAR_CART_CACHE,
  };
}

export function clearRequestsCache() {
  return {
    type: CLEAR_REQUESTS_CACHE,
  };
}

export function clearRecurringPaymentsCache() {
  return {
    type: CLEAR_RECURRING_PAYMENTS_CACHE,
  };
}

export function clearPaymentCardsCache() {
  return {
    type: CLEAR_PAYMENT_CARDS_CACHE,
  };
}

export function triggerClearCache(type) {
  if (type === 'cart') {
    return clearCartCache();
  }
  if (type === 'portfolio') {
    return clearPortfolioCache();
  }
  if (type === 'claims') {
    return clearClaimsCache();
  }
  if (type === 'quotes') {
    return clearQuotesCache();
  }
  if (type === 'receipts') {
    return clearReceiptsCache();
  }
  if (type === 'applications') {
    return clearApplicationsCache();
  }
  if (type === 'partialClaims') {
    return clearIncompleteClaimsCache();
  }
  if (type === 'requests') {
    return clearRequestsCache();
  }
  if (type === 'recurringPayments') {
    return clearRecurringPaymentsCache();
  }
  if (type === 'paymentCards') {
    return clearPaymentCardsCache();
  }

  return clearPortfolioCache();
}

export default {
  clearPortfolioCache,
  clearReceiptsCache,
  clearQuotesCache,
  clearClaimsCache,
  clearApplicationsCache,
  clearCartCache,
  triggerClearCache,
  clearIncompleteClaimsCache,
  clearRequestsCache,
  clearRecurringPaymentsCache,
  clearPaymentCardsCache,
};
