import React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon, Button, Typography, Paper, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useRouter } from '../../../hooks';
import { createRelativeUrl, uuidv4, getRef } from '../../../utils/functions';
import styles from './style';
import {
  agentType,
  claimsIndexName,
  quotesIndexName,
  portfolioIndexName,
  partialClaimsIndexName,
  investmentLob,
  otherLob,
} from '../../../utils/variables';
import routes from '../../../utils/variables/routes';

const LobChipList = ({ name, totals = {}, onCustomClick }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const theme = useTheme();
  const lobCategories = useSelector(state => state.locations.lobCategories);
  const yourLocation = useSelector(state => state.locations.yourLocation);
  const alpha2 = yourLocation?.alpha2;

  // const insuranceFilterSettings = useSelector(
  //  state => state.insurance.insuranceFilterSettings,
  // );
  const usertype = useSelector(state => state.auth.usertype);
  const router = useRouter();
  const parsed = router?.query || {};
  const { lob } = parsed;

  let allPathname = null;
  let pathname = null;
  if (name === portfolioIndexName) {
    allPathname = routes.portfolio.portfolioSummary;
    pathname = routes.portfolio.portfolioLobSummary;
  } else if (name === claimsIndexName) {
    allPathname = routes.claims.index;
    pathname = routes.claims.index;
  } else if (name === quotesIndexName) {
    allPathname = routes.quotes.index;
    pathname = routes.quotes.index;
  } else if (name === partialClaimsIndexName) {
    allPathname = routes?.partiallyFilledClaims?.index;
    pathname = routes?.partiallyFilledClaims?.index;
  }

  let filteredLobCategories = [];

  if (name === portfolioIndexName) {
    if (usertype === agentType) {
      filteredLobCategories = lobCategories.filter(x => x.isAvailableForAgentPortfolio);
    } else {
      filteredLobCategories = lobCategories.filter(
        x => x.isAvailableForCustomerPortfolio,
      );
    }
  } else if (name === claimsIndexName) {
    if (usertype === agentType) {
      filteredLobCategories = lobCategories.filter(x => x.isAvailableForAgentClaims);
    } else {
      filteredLobCategories = lobCategories.filter(x => x.isAvailableForCustomerClaims);
    }
    // console.log('filteredLobCategories: ', filteredLobCategories);
  } else if (name === quotesIndexName) {
    if (usertype === agentType) {
      filteredLobCategories = lobCategories.filter(x => x.isAvailableForAgentQuotes);
    } else {
      filteredLobCategories = lobCategories.filter(x => x.isAvailableForCustomerQuotes);
    }
  } else if (name === partialClaimsIndexName) {
    if (usertype === agentType) {
      filteredLobCategories = lobCategories.filter(
        x => x.isAvailableForAgentPartialClaims,
      );
    } else {
      filteredLobCategories = lobCategories.filter(
        x => x.isAvailableForCustomerPartialClaims,
      );
    }
    // console.log('filteredLobCategories: ', filteredLobCategories);
  }

  let fullTotal = 0;

  Object.values(totals)?.map(x => {
    if (x) {
      fullTotal += x;
    }
    return x;
  });

  filteredLobCategories.unshift({
    title: 'All',
    lob: 'all',
    color: 'background.chip',
    total: fullTotal,
    // total: getRef(insuranceFilterSettings, 'totalPolicies'),
  });

  const handleChange = data => {
    if (onCustomClick) {
      onCustomClick();
    }
  };

  return (
    <Paper component="ul" classes={{ root: classes.root }} elevation={0}>
      {filteredLobCategories.map(lobCategory => {
        const lobColor = getRef(theme.palette, lobCategory?.color);

        const chipClasses = useStyles({
          color: lobColor,
          contrastColor: theme.palette.text.bright,
        });
        if (lobCategory?.lob !== 'all') {
          lobCategory.total =
            totals[`${lobCategory?.lob}_${alpha2}`] || totals[lobCategory?.lob] || 0;
        }
        if (
          (lobCategory?.hideIfEmpty || usertype === agentType) &&
          lobCategory?.total === 0
        ) {
          return null;
        }

        let finalUrl = '';
        const newParsed = {};
        newParsed.lob = lobCategory?.lob;
        let path = pathname;
        let queryObject = { ...parsed, ...newParsed };
        if (queryObject?.startIndex) {
          delete queryObject.startIndex;
        }
        if (queryObject?.itemCount) {
          delete queryObject.itemCount;
        }
        if (queryObject?.scrollKey) {
          delete queryObject.scrollKey;
        }
        if (lobCategory?.lob === 'all') {
          path = allPathname;
          queryObject = {};
        }
        const relativeUrl = createRelativeUrl({
          path,
          queryObject,
        });

        finalUrl = relativeUrl;

        return (
          <li key={uuidv4()}>
            <Chip
              // icon={icon}
              component={RouterLink}
              to={finalUrl}
              label={
                lobCategory?.total
                  ? `${lobCategory?.title} (${lobCategory?.total})`
                  : `${lobCategory?.title}`
              }
              onClick={() => handleChange(lobCategory)}
              classes={{
                root: classnames(chipClasses.chip, {
                  [chipClasses.active]: lob === lobCategory?.lob,
                  [chipClasses.inActive]: lob !== lobCategory?.lob,
                }),
              }}
            />
          </li>
        );
      })}
    </Paper>
  );
};

LobChipList.defaultProps = {
  allPathname: '/portfolio/summary',
  pathname: '/portfolio/lob-summary',
};

export default LobChipList;
