import {
  CANCEL_PENDING_REQUEST_API_REQUEST,
  CANCEL_PENDING_REQUEST_API_SUCCESS,
  CANCEL_PENDING_REQUEST_API_ERROR,
} from '../../../constants/requestActionNames';
import { submitPendingRequestsApi } from '../../../apis/pendingRequests';

function cancelPendingRequestRequest() {
  return {
    type: CANCEL_PENDING_REQUEST_API_REQUEST,
    payload: true,
  };
}

function cancelPendingRequestSuccess(requests, params) {
  return {
    type: CANCEL_PENDING_REQUEST_API_SUCCESS,
    payload: requests,
    params,
  };
}

function cancelPendingRequestError(err) {
  return {
    type: CANCEL_PENDING_REQUEST_API_ERROR,
    payload: err,
  };
}

export function cancelPendingRequestAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(cancelPendingRequestRequest());
    return submitPendingRequestsApi
      .cancelPendingRequest(state, params)
      .then(data => {
        dispatch(cancelPendingRequestSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(cancelPendingRequestError(error));
        throw error;
        // return response;
      });
  };
}

export default { cancelPendingRequestAction };
