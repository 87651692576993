const locationStyles = theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  reverseColor: {
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.secondary.main,
    },
  },
  countryName: {
    // marginLeft: theme.spacing(1),
    textAlign: 'left',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    verticalAlign: 'middle',
    display: 'inline',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },

  countryDropdown: {
    // paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    textAlign: 'center',

    // marginTop: theme.spacing(4),
  },
  none: {
    display: 'none',
  },
  inlineBlock: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  dropdownStyle: {
    textAlign: 'center',
  },
  inputRoot: {
    padding: '0px 0px 0px 0px !important',
    textAlign: '-webkit-center',
  },
  flag: {
    height: '25px',
    margin: theme.spacing(1),

    borderRadius: theme.shape.borderRadius,

    width: '30px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  avatar: {
    height: '35px !important',
    width: '35px !important',
  },
  avatarMenuItem: {
    marginRight: theme.spacing(1),
  },
  flagButton: {
    height: '55px !important',
    // borderRadius: '6px !important',
    // backgroundSize: '60px 100px',
    // width: '35px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    boxShadow: `0px ${theme.spacing(1)} ${theme.spacing(2)} #00000029`,
  },

  formControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    float: 'left',
    paddingRight: '0px !important',
  },
  menuItem: {
    textAlign: 'center',
    float: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '250px',
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '350px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '450px',
    },
  },
  hideIcon: {
    display: 'none',
  },
  hideIconPadding: {
    padding: '0px !important',
    height: '40px !important',
  },
});

export default locationStyles;
