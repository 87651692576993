import React from 'react';
import { Link as RouterLink, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Grid,
  // ButtonBase,
  Button,
  Stack,
  Card,
  Chip,
  Skeleton,
  CardActions,
  CardContent,
  Icon,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import routes from '../../../utils/variables/routes';
import { investmentLob, domain, agentType } from '../../../utils/variables';
import { addWizard } from '../../../actions/wizards';
import { useFeatureToggle, useRouter, useSmall, useVariables } from '../../../hooks';
import styles from './style';
// import { formatImageUrlFromStrapi } from '../../../utils/functions';
import { ImageBox } from '../../common';
import { isValidHttpUrl, getActionButtons } from '../../../utils/functions';

const ProductCard = ({ product, loading, templateSettings }) => {
  // console.log('product: ', product);
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  const location = useLocation();
  const router = useRouter();
  const dispatch = useDispatch();
  const doFeatureToggle = useFeatureToggle();
  const theme = useTheme();
  const isSmall = useSmall();
  const variables = useVariables();

  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );
  const referralId = useSelector(state => {
    return state.temp?.referralDetails?.referralId;
  });
  const fatumSessionId = useSelector(state => {
    return state.auth.fatumSessionId;
  });

  // loading = true;
  // const productImage =
  // /product?.featuredImage_pwa || product?.parentProduct?.featuredImage_pwa;

  const productImage =
    product?.featuredImage_pwa ||
    (product?.parentProducts && product?.parentProducts[0]?.featuredImage_pwa);
  // console.log('productImage: ', productImage);
  const lobCategories = product?.lobCategories;
  let isInvest = false;

  const icons = lobCategories?.map(x => {
    if (x?.lob === investmentLob) isInvest = true;
    return x?.materialIcon;
  });

  const onButtonClick = async () => {
    dispatch(addWizard.addLastEntryPointAction(router?.asPath));
  };

  const externalParams = doFeatureToggle('useFatumLogin')
    ? {
        sessionid: fatumSessionId || null,
        '360_referrer': referralId || null,
        productCode: product?.productCode || null,
      }
    : {};
  // console.log('externalParams: ', externalParams);

  const buttons = getActionButtons({
    variables,
    product,
    doFeatureToggle,
    isInvest,
    buttonEachRow: templateSettings.cardButtonEachRow,
    classes,
    onButtonClick,
    loading,
    externalParams,
    isAgent,
  });

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="row" spacing={2}>
          {templateSettings?.showImage && (
            <Grid item lg={templateSettings?.imageGridSize} className={classes.zGrid}>
              {loading ? (
                <Skeleton variant="rect" height={190} />
              ) : (
                <ImageBox
                  {...productImage}
                  isImageStyleGlobal
                  imageStyle={{ height: '190px' }}
                  fixedSettings={{
                    screenSize: { sm: 'sm', md: 'sm', lg: 'sm' },
                    fit: { sm: 'width', md: 'width', lg: 'width' },
                  }}
                  imageBorderRadius={theme.shape.borderRadius}
                />
              )}
            </Grid>
          )}
          <Grid item lg={templateSettings?.contentGridSize} className={classes.zGrid}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              direction="column"
              className={classes.gridPad}
            >
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Typography
                    gutterBottom
                    textAlign="left"
                    variant="body1"
                    color="text.main"
                    component="div"
                    fontWeight="600"
                  >
                    {product?.title}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                ) : (
                  <Stack direction="row" spacing={1}>
                    {product?.tags &&
                      product?.tags?.map(tag => (
                        <Chip
                          label={tag?.title || ''}
                          color={tag?.color || undefined}
                          variant="outlined"
                          size="small"
                          icon={tag?.icon && <Icon data-nosnippet>{tag?.icon}</Icon>}
                        />
                      ))}
                  </Stack>
                )}
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={20}
                      width="90%"
                      style={{ marginBottom: 6 }}
                    />
                  </>
                ) : (
                  <Typography
                    variant="subTitle2"
                    align="left"
                    gutterBottom
                    color="text.dark"
                    className={classes.description}
                  >
                    {product?.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions} sx={{ pt: 2, mt: 'auto' }}>
        <Grid container justifyContent="flex-end" spacing={1}>
          {buttons}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
