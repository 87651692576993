import {
  ADD_PRECERT_ERROR,
  ADD_PRECERT_REQUEST,
  ADD_PRECERT_SUCCESS,
  GET_PENDING_PRECERTS_ERROR,
  GET_PENDING_PRECERTS_SUCCESS,
  GET_PENDING_PRECERTS_REQUEST,
  GET_PRECERT_BY_ID_ERROR,
  GET_PRECERT_BY_ID_SUCCESS,
  GET_PRECERT_BY_ID_REQUEST,
  DELETE_PENDING_PRECERT_REQUEST,
  DELETE_PENDING_PRECERT_SUCCESS,
  DELETE_PENDING_PRECERT_ERROR,
  UPDATE_PENDING_PRECERTS_CURRENT_CACHE_KEY,
} from '../../constants/preCertActionNames';
import { statusOk } from '../../utils/variables';
import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import { removeItemOnce } from '../../utils/functions';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  isAddPrecertError: false,
  isAddPrecertSuccess: false,
  isAddPrecertPending: false,
  isDeletePendingPrecertPending: false,
  isDeletePendingPrecertError: true,
  isDeletePendingPrecertSuccess: false,
  deletedPendingPrecert: null,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  total: {},
  totalRows: 0,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
};

function precertsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRECERT_BY_ID_REQUEST: {
      return {
        ...state,
        isDetailSuccess: false,
        isDetailError: false,
        isDetailPending: true,
      };
    }
    case GET_PRECERT_BY_ID_SUCCESS: {
      return {
        ...state,
        isDetailSuccess: true,
        isDetailError: false,
        isDetailPending: false,
        precertResponse: action.payload,
      };
    }
    case GET_PRECERT_BY_ID_ERROR: {
      return {
        ...state,
        isDetailSuccess: false,
        isDetailError: true,
        isDetailPending: false,
      };
    }
    case GET_PENDING_PRECERTS_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isPending: true,
      };
    }
    case GET_PENDING_PRECERTS_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const rowPayload = action?.payload?.payload?.results || [];
      const newRows = rowPayload;
      const body = action?.body;
      const lob = body?.lob;
      const cacheKey = action?.cacheKey;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        searchKeys: [],
        allRows: newRows,
        count,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        pendingPrecerts: action?.payload?.payload?.results,
      };
    }
    case GET_PENDING_PRECERTS_ERROR: {
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isPending: false,
        error: action?.payload,
      };
    }

    case UPDATE_PENDING_PRECERTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case ADD_PRECERT_REQUEST: {
      return {
        ...state,
        isAddPrecertSuccess: false,
        isAddPrecertError: false,
        isAddPrecertPending: true,
      };
    }
    case ADD_PRECERT_SUCCESS: {
      return {
        ...state,
        isAddPrecertSuccess: true,
        isAddPrecertError: false,
        isAddPrecertPending: false,
        precertResponse: action.payload,
      };
    }
    case ADD_PRECERT_ERROR:
      return {
        ...state,
        isAddPrecertError: true,
        isAddPrecertSuccess: false,
        isAddPrecertPending: false,
      };
    case DELETE_PENDING_PRECERT_REQUEST: {
      return {
        ...state,
        isDeletePendingPrecertPending: true,
        isDeletePendingPrecertError: false,
        isDeletePendingPrecertSuccess: false,
      };
    }
    case DELETE_PENDING_PRECERT_SUCCESS: {
      const deletedItem = action?.payload || {};
      deletedItem._id = deletedItem?._id || deletedItem?.claimId;
      const existing = [...state.allRows];
      const updatedList = removeItemOnce(existing, deletedItem, '_id');

      return {
        ...state,
        deletedItem,
        isDeletePendingPrecertPending: false,
        isDeletePendingPrecertError: false,
        isDeletePendingPrecertSuccess: true,
        allRows: updatedList,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    case DELETE_PENDING_PRECERT_ERROR: {
      const deletedItem = action?.payload;
      return {
        ...state,
        isDeletePendingPrecertPending: false,
        isDeletePendingPrecertError: true,
        isDeletePendingPrecertSuccess: false,
        deletedPendingPrecert: deletedItem,
      };
    }
    default:
      return state;
  }
}

export default precertsReducer;
