import { routerPostCall } from '../../axios';

export const acceptQuotesUrl = `/accounts/quotes/accept`;

const acceptQuotes = (state, params) => {
    const url = acceptQuotesUrl;
    return routerPostCall(state, url, params);
};

export default { acceptQuotes };
