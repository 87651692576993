import { format, parse, parseISO } from 'date-fns';
import { isString } from './stringManipulation';
import { dutchTemplate, templateStorage } from '../variables';
import Cookies from '../../cookies/cookies';

export const isValidDate = d => {
  return d && d instanceof Date && !Number.isNaN(d) && d.toString() !== 'Invalid Date';
};

export const isIsoDate = str => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

export const hasNoTime = str => {
  return str.includes('T00:00:00.000Z');
};

export const hasTime = str => {
  return str.includes('T');
};

export const dateToString = (date, dateformat = '') => {
  const templateName = Cookies.getCookie(templateStorage);
  const isDutchRegion = templateName === dutchTemplate;
  if (!isValidDate(date)) return date;
  if (isDutchRegion) {
    dateformat = dateformat || 'dd-MM-yyyy';
  } else {
    dateformat = dateformat || 'yyyy-MM-dd';
  }
  return format(date, dateformat);
};

export const addDays = (date, days) => {
  const today = new Date();
  today.setDate(today.getDate() + days);
  return today;
};

export const dateTimeToString = (date, dateTimeFormat = 'yyyy-MM-dd hh:mm aaa') => {
  const templateName = Cookies.getCookie(templateStorage);
  const isDutchRegion = templateName === dutchTemplate;
  if (!isValidDate(date)) return date;
  if (isDutchRegion) {
    dateTimeFormat = 'dd-MM-yyyy HH:mm:ss';
  }
  return format(date, dateTimeFormat);
};

export const dateTimeToReadableString = date => {
  if (!isValidDate(date)) return date;
  return format(date, 'MMM do, yyyy pp');
};

export const dateToReadableString = date => {
  if (!isValidDate(date)) return date;
  return format(date, 'MMM do, yyyy');
};

export const getFormattedDate = date => {
  const dateFormat = localStorage.getItem('dateFormat');
  const defaultFormat = 'MMM do, yyyy pp';
  if (dateFormat) {
    return format(date, dateFormat);
  }
  return format(date, defaultFormat);
};

export const stringToDate = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  if (!hasNoTime(stringDate) && isIsoDate(stringDate)) return isoStringToDate(stringDate);
  let newStringDate = stringDate;
  if (isIsoDate(stringDate)) {
    newStringDate = newStringDate.split('T')[0];
  }
  const parsedDate = parse(newStringDate, 'yyyy-MM-dd', new Date());
  // if (parsedDate.toString() === 'Invalid Date') parsedDate = new Date();
  if (!isValidDate(parsedDate)) return new Date();
  return parsedDate;
};

export const stringToAddOnDate = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  const parsedDate = parse(stringDate, 'MM/dd/yy', new Date());
  // if (parsedDate.toString() === 'Invalid Date') parsedDate = new Date();
  if (!isValidDate(parsedDate)) return new Date();
  return parsedDate;
};

export const stringToDateTime = stringDateTime => {
  if (!stringDateTime || !isString(stringDateTime)) return stringDateTime;
  const parsedDate = parse(stringDateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
  // if (parsedDate.toString() === 'Invalid Date') parsedDate = new Date();
  if (!isValidDate(parsedDate)) return new Date();
  return parsedDate;
};

export const stringFacebookDobToDate = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  const parsedDate = parse(stringDate, 'd/MM/yyyy', new Date());
  // // console.log('parsedDate: ', parsedDate);
  // if (parsedDate.toString() === 'Invalid Date') parsedDate = new Date();
  if (!isValidDate(parsedDate)) return new Date();
  return parsedDate;
};

export const stringQuoteToDate = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  const parsedDate = parse(stringDate, 'M/d/yyyy', new Date());
  // // console.log('parsedDate: ', parsedDate);
  // if (parsedDate.toString() === 'Invalid Date') parsedDate = new Date();
  if (!isValidDate(parsedDate)) return new Date();
  return parsedDate;
};

export const stringGoogleDobToDate = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  const date = new Date(stringDate);
  // if (!date || date.toString() === 'Invalid Date') return new Date();
  if (!isValidDate(date)) return new Date();
  // const localDate = new Date();
  // console.log(date.getTimezoneOffset());
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const isoStringToDob = stringDate => {
  if (!stringDate || !isString(stringDate)) return stringDate;
  const date = new Date(stringDate);
  // if (!date || date.toString() === 'Invalid Date') return new Date();
  if (!isValidDate(date)) return new Date();
  // const localDate = new Date();
  // console.log(date.getTimezoneOffset());
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const isoStringToDate = isoString => {
  if (!isoString || !isString(isoString)) return isoString;
  if (hasNoTime(isoString)) return stringToDate(isoString);
  let parsedDateTime = parseISO(isoString);
  // // console.log('parsedDateTime:', parsedDateTime);
  // if (!parsedDateTime || parsedDateTime.toString() === 'Invalid Date')
  if (!isValidDate(parsedDateTime)) parsedDateTime = isoString;
  // // console.log('parsedDateTime2:', parsedDateTime);
  // // console.log(parsedTime); // 2019-10-25T08:10:00.000Z
  // const formattedTime = format(parsedTime, 'yyyy-MM-dd kk:mm:ss');
  // // console.log(formattedTime); // 2019-10-25 16:10:00 <-- 8 HOURS OFF!
  // return formattedTime;
  return parsedDateTime;
};

export const stringToIsoDate = dateString => {
  if (!dateString || !isString(dateString)) return dateString;
  if (!hasTime(dateString)) {
    // console.log('dateString: ', dateString);

    // if (!date || date.toString() === 'Invalid Date') return new Date();
    const nowDate = new Date();
    const isoString = dateToIsoString(nowDate);
    // console.log('isoString: ', isoString);
    const timestamp = isoString.split('T')[1];
    // console.log('timestamp: ', timestamp);
    const date = new Date(`${dateString}T${timestamp}`);
    // console.log('date: ', date);
    if (!isValidDate(date)) return nowDate;
    // const localDate = new Date();

    return date;
  }
  if (hasNoTime(dateString)) return stringToDate(dateString);

  let parsedDateTime = parseISO(dateString);
  // // console.log('parsedDateTime:', parsedDateTime);
  // if (!parsedDateTime || parsedDateTime.toString() === 'Invalid Date')
  if (!isValidDate(parsedDateTime)) parsedDateTime = dateString;
  // // console.log('parsedDateTime2:', parsedDateTime);
  // // console.log(parsedTime); // 2019-10-25T08:10:00.000Z
  // const formattedTime = format(parsedTime, 'yyyy-MM-dd kk:mm:ss');
  // // console.log(formattedTime); // 2019-10-25 16:10:00 <-- 8 HOURS OFF!
  // return formattedTime;
  return parsedDateTime;
};

export const dateToIsoString = date => {
  if (!isValidDate(date)) return date;
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

export const formatDateToString = date => {
  if (!isValidDate(date)) return null;
  return format(date, 'MMM dd, yyyy');
};

export const getAgeFromDate = dob => {
  const convertDate = dob?.toString();
  const today = new Date();
  const birthDate = stringToDate(convertDate);
  if (!birthDate) return null;
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age;
};

export const getAgeFromNumber = year => {
  const today = new Date();
  if (!year) return null;
  const age = today.getFullYear() - Number(year);

  return age;
};

export const secondsToHms = seconds => {
  if (!seconds) return '';

  let duration = seconds;
  const hours = duration / 3600;
  duration %= 3600;

  let min = parseInt(duration / 60, 10);
  duration %= 60;

  let sec = parseInt(duration, 10);

  if (sec < 10) {
    sec = `0${sec}`;
  }
  if (min < 10) {
    min = `0${min}`;
  }

  if (parseInt(hours, 10) > 0) {
    return `${parseInt(hours, 10)}h ${min}m ${sec}s`;
  }
  if (min == 0) {
    return `${sec}s`;
  }

  return `${min}m ${sec}s`;
};

export const getMonths = stringDate => {
  const convertDate = stringDate?.toString();
  const todayDate = new Date();
  const lastDate = stringToDate(convertDate);
  if (!lastDate) return null;
  let months;
  months = (todayDate.getFullYear() - lastDate.getFullYear()) * 12;
  months -= lastDate.getMonth();
  months += todayDate.getMonth();
  return months <= 0 ? 0 : months;
};

export const getYearMonthString = stringDate => {
  const convertDate = stringDate?.toString();
  const todayDate = new Date();
  const lastDate = stringToDate(convertDate);
  if (!lastDate) return null;
  const allMonths =
    todayDate.getMonth() -
    lastDate.getMonth() +
    12 * (todayDate.getFullYear() - lastDate.getFullYear());
  let allYears = todayDate.getFullYear() - lastDate.getFullYear();
  let partialMonths = todayDate.getMonth() - lastDate.getMonth();
  if (partialMonths < 0) {
    allYears--;
    partialMonths += 12;
  }
  const total = `${allYears} years and ${partialMonths} months`;
  // const totalMonths = `A total of ${allMonths} between the dates.`;
  return total;
};

export const convertTo12Hr = time => {
  // Check correct time format and split into components
  let newTime = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (newTime.length > 1) {
    // If time format correct
    newTime = newTime.slice(1); // Remove full string match value
    newTime[5] = +newTime[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    newTime[0] = +newTime[0] % 12 || 12; // Adjust hours
  }
  return newTime.join(''); // return adjusted time or original string
};

export const getYearFromDate = stringDate => {
  const convertDate = stringDate?.toString();
  const lastDate = stringToDate(convertDate);
  if (!lastDate) return null;
  return lastDate.getFullYear();
};

export const nth = d => {
  const dString = String(d);
  const last = +dString.slice(-2);
  if (last > 3 && last < 21) return 'th';
  switch (last % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const generatePaymentDates = () => {
  const dayArray = [...Array(28).keys()];
  const numbers = dayArray.map(i => ({
    value: i + 1,
    title: `${i + 1}${nth(i + 1)}`,
  }));

  return numbers;
};

export const getDaysFromDates = (startDate, endDate) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Convert time difference from milliseconds to days and add one for inclusiveness
  const daysDifference = timeDifference / (1000 * 3600 * 24) + 1;
  return daysDifference;
};

export const isCurrentMonth = dateTimeString => {
  const parts = dateTimeString.split(/[- :]/);

  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  const currentdate = new Date();
  const curMonth = currentdate.getMonth() + 1;
  const curYear = currentdate.getFullYear();

  if (curMonth === month && year === curYear) {
    console.log('in this month');
    return true;
  }
  console.log('not in this month');
  return false;
};
