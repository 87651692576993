import {
  GET_REQUEST_HISTORY_DETAILS_API_REQUEST,
  GET_REQUEST_HISTORY_DETAILS_API_SUCCESS,
  GET_REQUEST_HISTORY_DETAILS_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function getRequestHistoryDetailsRequest() {
  return {
    type: GET_REQUEST_HISTORY_DETAILS_API_REQUEST,
    payload: true,
  };
}

function getRequestHistoryDetailsSuccess(requests, params) {
  return {
    type: GET_REQUEST_HISTORY_DETAILS_API_SUCCESS,
    payload: requests,
    params,
  };
}

function getRequestHistoryDetailsError(err) {
  return {
    type: GET_REQUEST_HISTORY_DETAILS_API_ERROR,
    payload: err,
  };
}

export function getRequestHistoryDetailsAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(getRequestHistoryDetailsRequest());
    return getRequestsApi
      .getRequestHistoryDetails(state, params)
      .then(data => {
        dispatch(getRequestHistoryDetailsSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getRequestHistoryDetailsError(error));
        throw error;
        // return response;
      });
  };
}

export default { getRequestHistoryDetailsAction };
