import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_RECURRING_PAYMENT_DETAILS_REQUEST,
  GET_RECURRING_PAYMENT_DETAILS_SUCCESS,
  GET_RECURRING_PAYMENT_DETAILS_ERROR,
  GET_RECURRING_PAYMENTS_REQUEST,
  GET_RECURRING_PAYMENTS_SUCCESS,
  GET_RECURRING_PAYMENTS_ERROR,
  ADD_RECURRING_PAYMENT_REQUEST,
  ADD_RECURRING_PAYMENT_SUCCESS,
  ADD_RECURRING_PAYMENT_ERROR,
  UPDATE_RECURRING_PAYMENT_REQUEST,
  UPDATE_RECURRING_PAYMENT_SUCCESS,
  UPDATE_RECURRING_PAYMENT_ERROR,
  DELETE_RECURRING_PAYMENT_REQUEST,
  DELETE_RECURRING_PAYMENT_SUCCESS,
  DELETE_RECURRING_PAYMENT_ERROR,
  PAUSE_RECURRING_PAYMENT_REQUEST,
  PAUSE_RECURRING_PAYMENT_SUCCESS,
  PAUSE_RECURRING_PAYMENT_ERROR,
  ACTIVATE_RECURRING_PAYMENT_REQUEST,
  ACTIVATE_RECURRING_PAYMENT_SUCCESS,
  ACTIVATE_RECURRING_PAYMENT_ERROR,
} from '../../constants/recurringPaymentActionNames';
import { CLEAR_RECURRING_PAYMENTS_CACHE } from '../../constants/miscActionNames';
import {
  stringToDate,
  isoStringToDate,
  dateToString,
  toTitleCase,
  dateToReadableString,
  formatDataTableValue,
} from '../../utils/functions';
import { persistKey } from '../../utils/variables';

export const initialState = {
  error: null,
  isAutoPayPoliciesSuccess: false,
  isAutoPayPoliciesError: false,
  isAutoPayPoliciesPending: false,
  autoPayPolicies: [],
  autoPayPoliciesResponse: null,
  isAutoPayDetailsSuccess: false,
  isAutoPayDetailsError: false,
  isAutoPayDetailsPending: false,
  autoPayDetailsResponse: null,
  isAutoPayAddSuccess: false,
  isAutoPayAddError: false,
  isAutoPayAddPending: false,
  autoPayAddResponse: null,
  isAutoPayUpdateSuccess: false,
  isAutoPayUpdateError: false,
  isAutoPayUpdatePending: false,
  autoPayUpdateResponse: null,
  isAutoPayDeleteSuccess: false,
  isAutoPayDeleteError: false,
  isAutoPayDeletePending: false,
  autoPayDeleteResponse: null,
  isAutoPayPauseSuccess: false,
  isAutoPayPauseError: false,
  isAutoPayPausePending: false,
  autoPayPauseResponse: null,
  isAutoPayActivateSuccess: false,
  isAutoPayActivateError: false,
  isAutoPayActivatePending: false,
  autoPayActivateResponse: null,
  autoPay: null,
  [DEFAULT_CACHE_KEY]: null,
};

const nth = d => {
  const last = +String(d).slice(-2);
  if (last > 3 && last < 21) return 'th';
  const remainder = last % 10;
  if (remainder === 1) return 'st';
  if (remainder === 2) return 'nd';
  if (remainder === 3) return 'rd';
  return 'th';
};

const createPayload = payloadArray => {
  if (!Array.isArray(payloadArray)) return [];

  const autoPayPolicies = payloadArray?.map((object, index) => {
    const position = index;
    let isAutoPayDateOutdated = false;
    const nextAutoPayDate = isoStringToDate(object?.nextPaymentAttempt);
    if (nextAutoPayDate) {
      const todaysDate = new Date();
      nextAutoPayDate.setHours(0, 0, 0, 0);
      todaysDate.setHours(0, 0, 0, 0);
      isAutoPayDateOutdated = todaysDate >= nextAutoPayDate;
    }

    const amount = object?.amount;
    const currency = object?.currency;

    const { dataValue: finalAmountDue } = formatDataTableValue({
      isNumeric: !Number.isNaN(amount),
      isCurrency: true,
      isLocalize: !Number.isNaN(amount),
      inputValue: amount,
      units: currency,
      showDataUnits: true,
    });

    const autoPay = {
      ...object,
      index: position,
      id: object?._id,
      paymentId: object?._id,
      nextAmountFormatDue: finalAmountDue,
      nextReadableRenewDate: dateToReadableString(
        isoStringToDate(object?.nextPaymentAttempt),
      ),
      nextRenewDate: dateToString(isoStringToDate(object?.nextPaymentAttempt)),
      lastPaid: dateToString(isoStringToDate(object?.lastPaid)),
      frequency: object?.frequency && toTitleCase(object?.frequency),
      status: object?.status && toTitleCase(object?.status),
      createdAt: dateToString(isoStringToDate(object?.createdAt)),
      isActive: object?.status === 'active',
      isAutoPayDateOutdated,
    };

    return autoPay;
  });

  return autoPayPolicies;
};

function recurringPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RECURRING_PAYMENT_DETAILS_REQUEST: {
      return {
        ...state,
        isAutoPayDetailsSuccess: false,
        isAutoPayDetailsError: false,
        isAutoPayDetailsPending: true,
      };
    }
    case GET_RECURRING_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isAutoPayDetailsSuccess: true,
        isAutoPayDetailsError: false,
        isAutoPayDetailsPending: false,
        autoPayDetailsResponse: action?.payload,
      };
    }
    case GET_RECURRING_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        isAutoPayDetailsSuccess: false,
        isAutoPayDetailsError: true,
        isAutoPayDetailsPending: false,
        error: action?.payload,
      };
    case GET_RECURRING_PAYMENTS_REQUEST: {
      return {
        ...state,
        isAutoPayPoliciesSuccess: false,
        isAutoPayPoliciesError: false,
        isAutoPayPoliciesPending: true,
      };
    }
    case GET_RECURRING_PAYMENTS_SUCCESS: {
      const response = action?.payload;
      const cacheKey = action?.cacheKey;
      return {
        ...state,
        isAutoPayPoliciesSuccess: true,
        isAutoPayPoliciesError: false,
        isAutoPayPoliciesPending: false,
        autoPayPolicies: createPayload(response?.payload),
        autoPayPoliciesResponse: response,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_RECURRING_PAYMENTS_ERROR:
      return {
        ...state,
        isAutoPayPoliciesSuccess: false,
        isAutoPayPoliciesError: true,
        isAutoPayPoliciesPending: false,
        error: action?.payload?.payload || action?.payload,
      };
    case ADD_RECURRING_PAYMENT_REQUEST: {
      return {
        ...state,
        isAutoPayAddSuccess: false,
        isAutoPayAddError: false,
        isAutoPayAddPending: true,
      };
    }
    case ADD_RECURRING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isAutoPayAddSuccess: true,
        isAutoPayAddError: false,
        isAutoPayAddPending: false,
        autoPayAddResponse: action?.payload,
        autoPay: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case ADD_RECURRING_PAYMENT_ERROR:
      return {
        ...state,
        isAutoPayAddSuccess: false,
        isAutoPayAddError: true,
        isAutoPayAddPending: false,
        error: action?.payload,
      };
    case UPDATE_RECURRING_PAYMENT_REQUEST: {
      return {
        ...state,
        isAutoPayUpdateSuccess: false,
        isAutoPayUpdateError: false,
        isAutoPayUpdatePending: true,
      };
    }
    case UPDATE_RECURRING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isAutoPayUpdateSuccess: true,
        isAutoPayUpdateError: false,
        isAutoPayUpdatePending: false,
        autoPayUpdateResponse: action?.payload,
        autoPay: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case UPDATE_RECURRING_PAYMENT_ERROR:
      return {
        ...state,
        isAutoPayUpdateSuccess: true,
        isAutoPayUpdateError: false,
        isAutoPayUpdatePending: false,
        error: action?.payload,
      };
    case PAUSE_RECURRING_PAYMENT_REQUEST: {
      return {
        ...state,
        isAutoPayPauseSuccess: false,
        isAutoPayPauseError: false,
        isAutoPayPausePending: true,
      };
    }
    case PAUSE_RECURRING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isAutoPayPauseSuccess: true,
        isAutoPayPauseError: false,
        isAutoPayPausePending: false,
        autoPayPauseResponse: action?.payload,
        autoPay: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case PAUSE_RECURRING_PAYMENT_ERROR:
      return {
        ...state,
        isAutoPayPauseSuccess: false,
        isAutoPayPauseError: true,
        isAutoPayPausePending: false,
        error: action?.payload,
      };
    case DELETE_RECURRING_PAYMENT_REQUEST: {
      return {
        ...state,
        isAutoPayDeleteSuccess: false,
        isAutoPayDeleteError: false,
        isAutoPayDeletePending: true,
      };
    }
    case DELETE_RECURRING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isAutoPayDeleteSuccess: true,
        isAutoPayDeleteError: false,
        isAutoPayDeletePending: false,
        autoPayDeleteResponse: action?.payload,
        [DEFAULT_CACHE_KEY]: null,
        autoPay: null,
      };
    }
    case DELETE_RECURRING_PAYMENT_ERROR:
      return {
        ...state,
        isAutoPayDeleteSuccess: false,
        isAutoPayDeleteError: true,
        isAutoPayDeletePending: false,
        error: null,
      };

    case ACTIVATE_RECURRING_PAYMENT_REQUEST: {
      return {
        ...state,
        isAutoPayActivateSuccess: false,
        isAutoPayActivateError: false,
        isAutoPayActivatePending: true,
      };
    }
    case ACTIVATE_RECURRING_PAYMENT_SUCCESS: {
      return {
        ...state,
        isAutoPayActivateSuccess: true,
        isAutoPayActivateError: false,
        isAutoPayActivatePending: false,
        autoPayActivateResponse: action?.payload,
        autoPay: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case ACTIVATE_RECURRING_PAYMENT_ERROR: {
      return {
        ...state,
        isAutoPayActivateSuccess: false,
        isAutoPayActivateError: true,
        isAutoPayActivatePending: false,
        error: action?.payload,
      };
    }

    case CLEAR_RECURRING_PAYMENTS_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        autoPayPolicies: initialState.autoPayPolicies,
        autoPay: initialState.autoPay,
      };

    default:
      return state;
  }
}

export default recurringPaymentsReducer;
