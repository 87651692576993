export default theme => ({
  content: ({ videoHeight }) => ({
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    scrollbarX: 'scroll',
    width: '100%',
    wordBreak: 'break-word',
    padding: '0px',
    textAlign: 'left',
    '& .ql-tooltip': {
      display: 'none',
    },
    '& .ql-editor,.ql-video': {
      textAlign: 'left',
      whiteSpace: 'initial',
      lineHeight: '2.6rem !important',
      padding: '0px',

      height: videoHeight?.lg || 'auto',
      [theme.breakpoints.down('sm')]: {
        height: videoHeight?.md || 'auto',
      },
      [theme.breakpoints.down('xs')]: {
        height: videoHeight?.sm || 'auto',
      },
    },
    '& .ql-container': {
      fontFamily: `${theme.typography.fontFamily}`,
      color: `${theme.palette.text.dark}`,
      padding: '0px',
      fontSize: `1rem`,
    },
    '& p': {
      // fontSize: '1rem !important',
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& ul li::marker': {
      fontColor: `${theme.palette.primary.main} !important`,
      fontSize: '1.2em',
    },
  }),
  dutchContent: props => ({
    scrollbarX: 'scroll',
    width: '100%',
    wordBreak: 'break-word',
    padding: '0px',
    textAlign: 'left',
    '& .ql-editor': {
      textAlign: 'left',
      whiteSpace: 'initial',
      [theme.breakpoints.up('xs')]: {
        lineHeight: '1rem !important',
      },
      [theme.breakpoints.up('md')]: {
        lineHeight: '1.5rem !important',
      },
      [theme.breakpoints.up('lg')]: {
        lineHeight: '1.9rem !important',
      },
      [theme.breakpoints.up('xl')]: {
        lineHeight: '2.5rem !important',
      },
      padding: '0px',
    },
    '& .ql-container': {
      fontFamily: `${theme.typography.fontFamily}`,
      color: `${props.fontColor}`,
      padding: '0px',
      [theme.breakpoints.up('xs')]: {
        fontSize: `0.75rem`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `0.8rem`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `1.25rem`,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: `1.5rem`,
      },
    },
    '& p': {
      fontSize: '1rem !important',
      marginTop: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& ul li::marker': {
      fontColor: `${theme.palette.primary.main} !important`,
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
    },
  }),
});
