// import queryString from 'query-string';
import queryString from 'query-string';
import Fuse from 'fuse.js';
import {
  stringToDate,
  isoStringToDate,
  addItemOnce,
  parseBool,
} from '../utils/functions';
// import initialLobTypes from '../utils/variables/lobTypes.json';
import { fuseOptions, trimOneChar } from './functions';
import { filterClaimStatuses, filterInsuredItemStatuses } from './data';
import {
  claimsIndexName,
  partialClaimsIndexName,
  quotesIndexName,
  insuredItemsIndexName,
  domain,
  investmentLob,
  clientPoliciesIndexName,
  agentType,
} from '../utils/variables';

const historyList = [];

export const listOptions = ({
  state,
  name,
  infiniteScroll = false,
  lobTypes,
  fixedItemCount,
  filterStatus,
  router,
}) => {
  const { location } = state?.router || router;
  const { lobCategories = [] } = state?.locations;

  const { nextStartIndex, nextItemCount, nextScrollCount, currentCacheKey } = state[name];
  const newLobTypes = lobTypes ? [...lobTypes] : [...lobCategories];

  const parsed = queryString.parse(location.search);
  // console.log('parsed: ', parsed);
  // const { parsed, history } = updateQueryHistory(historyList, location);
  // historyList = [...history];

  // const defaultDate = new Date();

  // eslint-disable-next-line no-nested-ternary
  const lobArray = Array.isArray(parsed?.lob)
    ? parsed?.lob
    : typeof parsed?.lob === 'string' && parsed?.lob.length > 0
    ? parsed?.lob.split(',')
    : null;

  const currencyArray = Array.isArray(parsed?.currency)
    ? parsed?.currency
    : typeof parsed?.currency === 'string' && parsed?.currency.length > 0
    ? parsed?.currency.split(',')
    : null;

  // eslint-disable-next-line no-nested-ternary
  const filterStatusArray = Array.isArray(parsed?.status)
    ? parsed?.status
    : typeof parsed?.status === 'string' && parsed?.status.length > 0
    ? parsed?.status.split(',')
    : null;

  const defaultDateStart = null; // new Date(2010, 0, 1);
  const defaultDateEnd = null; // new Date();
  const itemsPerPageOptions = [3, 6, 12, 24, 48, 60];
  const initialCount = fixedItemCount || itemsPerPageOptions[1];

  const defaultItemCount = initialCount;
  const startIndex =
    infiniteScroll && nextStartIndex
      ? nextStartIndex
      : parseInt(parsed?.startIndex, 10) || 0;
  const itemCount =
    infiniteScroll && nextItemCount
      ? nextItemCount
      : parseInt(parsed?.itemCount, 10) || initialCount;
  const scrollCount =
    infiniteScroll && nextScrollCount
      ? nextScrollCount
      : parseInt(parsed?.scrollCount, 10) || initialCount;

  let statuses = null;
  if (name === claimsIndexName) {
    statuses = filterClaimStatuses;
  } else if (name === insuredItemsIndexName) {
    statuses = filterInsuredItemStatuses;
  }

  const filterCurrencies = [];
  const result = state[name]?.allRows?.filter(function(item, index) {
    if (
      item.filterCurrency != undefined &&
      filterCurrencies.filter(x => x === item.filterCurrency).length == 0
    ) {
      filterCurrencies.push(item.filterCurrency);
    }
  });
  // filterCurrencies.push('none');
  const options = {
    quote_type: parsed?.quote_type,
    quote_state: parsed?.quote_state,
    lob: lobArray || [],
    startDate: parsed?.startDate
      ? name === clientPoliciesIndexName ||
        name === claimsIndexName ||
        name === partialClaimsIndexName
        ? isoStringToDate(parsed?.startDate)
        : stringToDate(parsed?.startDate)
      : defaultDateStart,
    endDate: parsed?.endDate
      ? name === clientPoliciesIndexName ||
        name === claimsIndexName ||
        name === partialClaimsIndexName
        ? isoStringToDate(parsed?.endDate)
        : stringToDate(parsed?.endDate)
      : defaultDateEnd,
    lobTypes: newLobTypes || [],
    status: filterStatusArray || [],
    statuses,
    itemsPerPageOptions,
    startIndex,
    itemCount,
    scrollCount,
    infiniteScroll,
    search: parsed?.search || '',
    batchStatus: parsed?.batchStatus || '',
    currentStatus: parsed?.currentStatus || filterStatus,
    filterId: parsed?.filterId || '',
    currencies: filterCurrencies || [],
    currency: currencyArray || [],
    currentCacheKey,
    initialCount,
    defaultItemCount,
    indexName: name,
    showFilters: {
      date: true,
      lob: true,
      currency: true,
      status: true,
    },
  };

  return options;
};

export const listFind = (state, id, name) => {
  // const { location } = state.router;

  const { allRows } = state[name];
  // // console.log('All Rows', allRows);
  // // console.log('id', id);
  // const policyId = match?.parms?.policy;

  const finalRow = allRows.find(row => {
    // // console.log('id: ', id, row?.slug);
    return row?.slug?.toString() === id;
  });
  // // console.log('finalRow', finalRow);
  /** const filteredPolicies = claim.policyData.filter(
     policy => policy.policyNumber === policyId,
    );
    if (!filteredPolicies || filteredPolicies.length === 0) {
      return claim && claim.id === id;
    }
    const newClaim = { ...claim, policyData: filteredPolicies };
    return newClaim && claim.id === id;
     */

  return finalRow;
};

export const listItemFind = (state, match, name) => {
  // const { location } = state.router;

  const { allRows } = state[name];
  // // console.log('All Rows', allRows);

  const id = match?.params?.id;
  const itemId = match?.params?.itemId;
  // // console.log('id', id);
  // // console.log('ITem ID', itemId);
  // const policyId = match?.parms?.policy;

  const finalRow = allRows.find(row => {
    // // console.log('id: ', id, row?.slug);
    return row?.slug?.toString() === id && row?.itemSlug?.toString() === itemId;
  });
  // // console.log('finalRow', finalRow);
  /** const filteredPolicies = claim.policyData.filter(
     policy => policy.policyNumber === policyId,
    );
    if (!filteredPolicies || filteredPolicies.length === 0) {
      return claim && claim.id === id;
    }
    const newClaim = { ...claim, policyData: filteredPolicies };
    return newClaim && claim.id === id;
     */

  return finalRow;
};

export const listItemFilter = ({
  state,
  name,
  infiniteScroll,
  match,
  fixedItemCount,
}) => {
  // const { allRows = [] } = state[name] || { allRows: [] };
  // // console.log('Claims Selector');
  const { allRows, searchKeys, currentCacheKey } = state[name];

  const options = listOptions({ state, name, infiniteScroll, fixedItemCount });
  // // console.log('All Rows', allRows);
  // // console.log('Options', options);
  // gives {lob:'Health', product:'id'}
  let filteredRows = [...allRows];
  filteredRows = filteredRows.filter(e => e?.cacheKey === currentCacheKey);
  // // console.log('obj: ', obj);
  // // console.log('getEntries(allRows[0]): ', Object.keys(obj));

  // // console.log('filterOptions: ', filterOptions);
  // // console.log('filteredRows: ', filteredRows);
  if (options.filterId && options.filterId.length > 0) {
    // // console.log('options.policyId: ', options.policyId);

    filteredRows = filteredRows.filter(
      row => row.filterId && row.filterId === options.filterId,
    );
  }

  if (options.lob && options.lob.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterLob ||
        options.lob.some(option => e.filterLob === option || option === 'all'),
    );
  }
  // // console.log('Filtered Rows', filteredRows);
  // // console.log('options.startDate: ', options.startDate);
  // // console.log('options.endDate: ', options.endDate);
  if (options.startDate && options.endDate) {
    filteredRows = filteredRows.filter(
      row =>
        !row?.filterDate ||
        (row.filterDate &&
          options.startDate <= stringToDate(row?.filterDate) &&
          options.endDate >= stringToDate(row?.filterDate)),
    );
  }
  // // console.log('Filtered Rows', filteredRows);

  if (options.status && options.status.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterStatus ||
        options?.status?.some(
          option => option?.toLowerCase() === e?.filterStatus?.toLowerCase(),
        ),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  const id = match?.params?.id;
  // // console.log('List ID', id);
  if (id && id.length > 0) {
    filteredRows = filteredRows.filter(row => row?.slug === id);
    options.filterId = id;
  }
  // // console.log('Filtered Rows', filteredRows);

  let notPerfectScore = true;

  if (options.search && options.search.length > 0 && filteredRows.length > 0) {
    // const obj = filteredRows && filteredRows.length > 0 ? filteredRows[0] : {};

    const fuse = new Fuse(filteredRows, fuseOptions(searchKeys));
    let searchResults = fuse.search(options.search);
    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // .replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }
    // console.log('searchResults: ', searchResults);
    filteredRows = searchResults.map(result => {
      if (result?.score === 0) {
        notPerfectScore = false;
      }
      return result.item;
    });
  }
  if (!options.search.length === 0 || options.search.length === 0) {
    notPerfectScore = false;
  }

  // console.log('Filtered Rows', filteredRows);
  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  if (options.filterDate) {
    filteredRows.sort((a, b) => {
      if (a.filterDate === b.filterDate) {
        // Price is only important when cities are the same
        return b.itemSlug - a.itemSlug;
      }
      return stringToDate(a.filterDate) > stringToDate(b.filterDate) ? 1 : -1;
    });
  }
  // // console.log('Filtered Rows', filteredRows);
  const filterCount = filteredRows.length;
  const newStartIndex = options.infiniteScroll ? 0 : options.startIndex;
  // // console.log('options.infiniteScroll: ', options.infiniteScroll);
  const newItemCount = options.infiniteScroll
    ? options.scrollCount
    : options.itemCount + options.startIndex;
  const finalRows = filteredRows.slice(newStartIndex, newItemCount);
  // options.startIndex = newStartIndex;

  // // console.log('finalRows: ', finalRows);

  // // console.log('filteredClaims: ', filteredClaims);
  return { rows: finalRows, options, filterCount, filteredRows, notPerfectScore };
};

export const listFilter = ({
  bypassFiltering,
  state,
  name,
  infiniteScroll,
  lobTypes,
  opts,
  fixedItemCount,
}) => {
  // console.log("bypassFiltering", bypassFiltering);
  // // console.log('infiniteScroll: ', infiniteScroll);
  // const { allRows = [] } = state[name] || { allRows: [] };
  // // console.log('Claims Selector');
  const { allRows, searchKeys, currentCacheKey } = state[name];
  const options = listOptions({
    state,
    name,
    infiniteScroll,
    lobTypes,
    fixedItemCount,
  });
  // console.log('All Rows', allRows);
  // console.log('Options', options);
  // gives {lob:'Health', product:'id'}
  let filteredRows = [...allRows];

  // // console.log('obj: ', obj);
  // // console.log('getEntries(allRows[0]): ', Object.keys(obj));

  filteredRows = filteredRows.filter(e => e?.cacheKey === currentCacheKey);

  // // console.log('filterOptions: ', filterOptions);
  // console.log('filteredRows: ', filteredRows);
  if (options.filterId && options.filterId.length > 0) {
    // // console.log('options.policyId: ', options.policyId);

    filteredRows = filteredRows.filter(
      row => row.filterId && row.filterId === options.filterId,
    );
  }

  if (options.lob && options.lob.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterLob ||
        options.lob.some(
          option =>
            e.filterLob?.toLowerCase() === option?.toLowerCase() ||
            option?.toLowerCase() === 'all',
        ),
    );
  }

  if (options.currency && options.currency.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterCurrency ||
        (e?.filterCurrency &&
          options.currency.some(
            option => e.filterCurrency?.toLowerCase() === option?.toLowerCase(),
          )),
    );
  }

  // console.log('Filtered Rows', filteredRows);

  if (options.startDate && options.endDate) {
    // console.log(options);

    filteredRows = filteredRows.filter(
      row =>
        !row?.filterDate ||
        (row.filterDate &&
          options.startDate <= stringToDate(row?.filterDate) &&
          options.endDate >= stringToDate(row?.filterDate)),
    );
  }

  // console.log('Filtered Rows', filteredRows);
  if (options?.status && options.status.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        !e?.filterStatus ||
        options?.status?.some(
          option =>
            option?.toLowerCase() === e?.filterStatus?.toLowerCase() ||
            option?.toLowerCase() === 'all',
        ),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  if (options?.currentStatus && options.currentStatus.length > 0) {
    filteredRows = filteredRows.filter(
      e =>
        e?.body?.currentStatus?.toLowerCase() === options?.currentStatus?.toLowerCase(),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  if (options?.quote_type && options.quote_type.length > 0) {
    filteredRows = filteredRows.filter(
      e => e?.type?.toLowerCase() === options?.quote_type?.toLowerCase(),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  if (options?.quote_state && options.quote_state.length > 0) {
    filteredRows = filteredRows.filter(
      e => e?.state?.toLowerCase() === options?.quote_state?.toLowerCase(),
    );
    // // console.log('searchInsurances: ', searchInsurances);
  }

  // // console.log('Filtered Rows', filteredRows);
  let notPerfectScore = true;

  if (options?.search && options.search.length > 0 && filteredRows.length > 0) {
    // const obj = filteredRows && filteredRows.length > 0 ? filteredRows[0] : {};

    const fuse = new Fuse(filteredRows, fuseOptions(searchKeys));
    let searchResults = fuse.search(options.search);
    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // .replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }
    // console.log('searchResults: ', searchResults);
    filteredRows = searchResults.map(result => {
      if (result?.matches && result.matches.length > 0) {
        notPerfectScore = false;
      }
      return result.item;
    });
  }
  if (!options.search.length === 0 || options.search.length === 0) {
    notPerfectScore = false;
  }

  const filterCount = filteredRows.length;
  // console.log('Filtered Rows', filteredRows);
  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  if (options.filterDate) {
    filteredRows.sort((a, b) => {
      if (a.filterDate === b.filterDate) {
        // Price is only important when cities are the same
        return b.slug - a.slug;
      }
      return stringToDate(a.filterDate) > stringToDate(b.filterDate) ? 1 : -1;
    });
  }

  // // console.log('Filtered Rows', filteredRows);

  const newStartIndex = options.infiniteScroll ? 0 : options.startIndex;
  // console.log('newStartIndex: ', newStartIndex);
  // console.log('options.infiniteScroll: ', options.infiniteScroll);
  const newItemCount = options.infiniteScroll
    ? options.scrollCount
    : options.itemCount + options.startIndex;
  // console.log('options.itemCount: ', options.itemCount);
  // console.log('newItemCount: ', newItemCount);
  let finalRows = null;
  // console.log('filteredRows: ', filteredRows);
  if (bypassFiltering) {
    finalRows = filteredRows;
  } else {
    finalRows = filteredRows.slice(newStartIndex, newItemCount);
  }
  // console.log('filteredRows: ', filteredRows);
  // console.log('filteredRows: ', filteredRows);
  // console.log('finalRows: ', finalRows);
  // options.startIndex = newStartIndex;

  // // console.log('finalRows: ', finalRows);

  // // console.log('filteredClaims: ', filteredClaims);
  return { rows: finalRows, options, filterCount, filteredRows, notPerfectScore };
};

export const listFilterRemote = ({
  state,
  name,
  infiniteScroll,
  isMobile,
  lobTypes,
  fixedItemCount,
  filterStatus,
  router,
}) => {
  // // console.log('infiniteScroll: ', infiniteScroll);
  // const { allRows = [] } = state[name] || { allRows: [] };
  // // console.log('Claims Selector');

  const { allRows, searchKeys, count = 0, currentCacheKey } = state[name];

  const options = listOptions({
    state,
    name,
    infiniteScroll,
    lobTypes,
    fixedItemCount,
    filterStatus,
    router,
  });
  // // console.log('All Rows', allRows);
  // console.log('Options', options);
  // gives {lob:'Health', product:'id'}
  // console.log('allRows: ', allRows);
  return {
    rows: allRows, // finalInsurances,
    filterCount: count[currentCacheKey],
    // searchInsurances: finalSearchInsurances,
    count, // filteredInsurances.length,
    options,
    allCount: count[currentCacheKey],
    // filteredInsurances,
  };
};

export default {
  listFilterRemote,
  listFilter,
  listOptions,
  listFind,
  listItemFilter,
  listItemFind,
};

/*
 // // console.log('filteredRows: ', filteredRows);
  if (options.lob && options.lob.length > 0) {
    filteredRows = filteredRows.filter(
      e => e.lob === options.lob || options.lob === 'all',
    );
  }
  */
