import {
  UPDATE_RECEIPTS_CURRENT_CACHE_KEY,
  GET_RECEIPTS_API_REQUEST,
  GET_RECEIPTS_API_SUCCESS,
  GET_RECEIPTS_API_ERROR,
  GET_NEXT_RECEIPTS_SUCCESS,
} from '../../../constants/receiptActionNames';

import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getReceiptsUrl } from '../../../apis/receipts/getReceiptsApi';
import { getReceiptsApi } from '../../../apis/receipts';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_RECEIPTS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getReceiptsRequest() {
  return {
    type: GET_RECEIPTS_API_REQUEST,
    payload: true,
  };
}
function getReceiptsSuccess(receipts, body, cacheKey) {
  return {
    type: GET_RECEIPTS_API_SUCCESS,
    payload: receipts,
    body,
    cacheKey,
  };
}

function getReceiptsError(err) {
  return {
    type: GET_RECEIPTS_API_ERROR,
    payload: err,
  };
}

export function getReceiptsAction(params, body) {
  return (dispatch, getState) => {
    const state = getState();

    const cacheParams = {
      ...params,
      infiniteScroll: body?.infiniteScroll,
      alpha2: body?.alpha2,
    };

    const cacheKey = generateCacheKey({
      path: getReceiptsUrl,
      cacheParams,
      state,
    });

    const { isCacheValid, cache } = checkCacheValid(getState, 'receipts', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(getReceiptsSuccess(cache, body, cacheKey));
      return Promise.resolve(cache);
    }

    dispatch(getReceiptsRequest());
    return getReceiptsApi
      .getReceipts(state, params)
      .then(data => {
        dispatch(getReceiptsSuccess(data, body, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getReceiptsError(error));
        throw error;
      });
  };
}

function getNextReceiptsSuccess(prod) {
  return {
    type: GET_NEXT_RECEIPTS_SUCCESS,
    payload: prod,
  };
}

export function getNextReceiptsAction(data) {
  return getNextReceiptsSuccess(data);
}

export default {
  getReceiptsAction,
  getNextReceiptsAction,
};
