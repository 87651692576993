import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_REQUESTS_API_REQUEST,
  GET_REQUESTS_API_SUCCESS,
  GET_REQUESTS_API_ERROR,
  GET_NEXT_REQUESTS_SUCCESS,
  GET_REQUEST_HISTORY_API_REQUEST,
  GET_REQUEST_HISTORY_API_SUCCESS,
  GET_REQUEST_HISTORY_API_ERROR,
  SUBMIT_REQUEST_API_REQUEST,
  SUBMIT_REQUEST_API_ERROR,
  SUBMIT_REQUEST_API_SUCCESS,
  GET_REQUEST_HISTORY_DETAILS_API_REQUEST,
  GET_REQUEST_HISTORY_DETAILS_API_SUCCESS,
  GET_REQUEST_HISTORY_DETAILS_API_ERROR,
  GET_PENDING_REQUEST_HISTORY_API_REQUEST,
  GET_PENDING_REQUEST_HISTORY_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_API_ERROR,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR,
  APPROVE_PENDING_REQUEST_API_REQUEST,
  APPROVE_PENDING_REQUEST_API_SUCCESS,
  APPROVE_PENDING_REQUEST_API_ERROR,
  REJECT_PENDING_REQUEST_API_REQUEST,
  REJECT_PENDING_REQUEST_API_SUCCESS,
  REJECT_PENDING_REQUEST_API_ERROR,
  CANCEL_PENDING_REQUEST_API_REQUEST,
  CANCEL_PENDING_REQUEST_API_SUCCESS,
  CANCEL_PENDING_REQUEST_API_ERROR,
  GET_INVEST_REQUEST_SETTING_API_REQUEST,
  GET_INVEST_REQUEST_SETTING_API_SUCCESS,
  GET_INVEST_REQUEST_SETTING_API_ERROR,
} from '../../constants/requestActionNames';

import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';

import payloadData from './payloadData';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  toTitleCase,
  dateTimeToString,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

// parseInt(startIndex + index, 10)
//
const createPayload = (payloadArray, lobTypes) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex =
        object?.lob?.toLowerCase().indexOf(lobObject.lob) > -1 ||
        object?.coverage?.toLowerCase().indexOf(lobObject.lob);
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex > -1;
    });
    const position = index;
    return {
      index: position,
      id: object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      slug: object?._id?.split('_')[1],
      filterDate: dateToString(isoStringToDate(object?.reportDate)),
      lob: lobObj?.lob,
      icon: lobObj?.icon,
      filterId: object?.requestNumber,
    };
  });
};

const createTypeFromOperation = operation => {
  if (
    operation === 'submit-request-investment-deposit-pw' ||
    operation === 'submit-request-investment-deposit-mf'
  ) {
    return 'Deposit';
  }
  if (
    operation === 'submit-request-investment-transfer-pw' ||
    operation === 'submit-request-investment-transfer-mf'
  ) {
    return 'Transfer';
  }
  if (
    operation === 'submit-request-investment-withdrawal-pw' ||
    operation === 'submit-request-investment-withdrawal-mf'
  ) {
    return 'Withdrawal';
  }
  if (
    operation === 'submit-request-investment-switch-pw' ||
    operation === 'submit-request-investment-switch-mf'
  ) {
    return 'Switch';
  }
};

const createNewRequestPayload = (payloadArray, startIndex = 0) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const position = startIndex + index;
    return {
      ...object,
      index: position,
      lob: object?.bu,
      operation: object?.operation,
      type: createTypeFromOperation(object?.operation),
      updatedAt: dateToString(isoStringToDate(object?.updatedAt)),
      id: object?._id,
      data: object?.data,
      territory: object?.territory,
      currentStatus: object?.data?.status,

      // additionalDetails: object?.additionalDetails,
      // amount: object?.amount,
      // // clientEmail: object?.clientEmail,
      // // dateOfBirth: object?.dateOfBirth,
      // // email: object?.email,
      // // firstName: object?.firstName,
      // // lastName: object?.lastName,
      // // mobile: object?.mobile,
      // // territory:object?.territory,
      // currency: object?.currency,
      // paymentType: object?.paymentType,
      // sourceAccount: object?.sourceAccount,
      // sourceFund: object?.sourceFund,
      // depositType: object?.depositType,
      // depositTypeDetails: object?.depositTypeDetails,
      // destinationAccount: object?.destinationAccount,
      // destinationFund: object?.destinationFund,
      // fullTransfer: object?.fullTransfer,
      // fullSwitch: object?.fullSwitch,

      // date: dateToString(isoStringToDate(object?.createdOn)),
      // createdOn: object?.createdOn,
      // status: 'Submitted',
      // entity: { entityId: 12342, entityType: 'Claim' },
    };
  });
};

const createStatusHistory = (payloadArray, startIndex = 0) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const position = startIndex + index;
    return {
      ...object,
      index: position,
      status: toTitleCase(object?.status),
      statusDescription: object?.statusDescription,
      updatedBy: object?.updatedBy,
      updatedAt: dateToString(isoStringToDate(object?.updatedAt)),
    };
  });
};

const createNewPendingRequestPayload = (payloadArray, startIndex = 0) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    const position = startIndex + index;
    return {
      ...object,
      index: position,
      id: object?._id,
      c360: object?.c360Id,
      masterId: object?.masterId,
      lob: object?.businessUnit,
      requestType: object?.requestType,
      territory: object?.territory,
      currentStatus: object?.currentStatus,
      updatedAt: dateToString(isoStringToDate(object?.updatedAt)),
      data: object?.data,
      entity: object?.entity,
      statusHistory: createStatusHistory(object?.statusHistory),
      type: createTypeFromOperation(object?.data?.operation),
    };
  });
};

const createPendingRequestDetailsPayload = payloadArray => {
  return {
    ...payloadArray,
    type: createTypeFromOperation(payloadArray?.requestType || payloadArray?.operation),
    date: dateToString(isoStringToDate(payloadArray?.updatedAt)),
    destinationAccount: payloadArray?.data?.destinationAccount,
    destinationFund: payloadArray?.data?.destinationFund,
    depositType: payloadArray?.data?.depositType,
    amount: payloadArray?.data?.amount,
    currency: payloadArray?.data?.currency,
    paymentType: payloadArray?.data?.paymentType,
    additionalDetails: payloadArray?.data?.additionalDetails,
    performedBy: payloadArray?.data?.email,
    sourceAccount: payloadArray?.data?.sourceAccount,
    sourceFund: payloadArray?.data?.sourceFund,
    fullTransfer: payloadArray?.data?.fullTransfer?.toString(),
    fullSwitch: payloadArray?.data?.fullSwitch?.toString(),
  };
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  // pageItemCount: 5,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  // startIndex: 0,
  isRequestHistoryPending: false,
  isRequestHistoryError: false,
  isRequestHistorySuccess: false,
  requestHistoryResponse: null,
  newRequestHistoryResponse: null,
  isSubmitRequestPending: false,
  isSubmitRequestError: false,
  isSubmitRequestSuccess: false,
  submitRequestResponse: null,
  submitRequestError: null,
  isRequestHistoryDetailsPending: false,
  isRequestHistoryDetailsSuccess: false,
  isRequestHistoryDetailsError: false,
  requestHistoryDetailsResponse: null,
  isPendingRequestHistoryPending: false,
  isPendingRequestHistorySuccess: false,
  isPendingRequestHistoryError: false,
  pendingRequestHistoryResponse: null,
  isPendingRequestHistoryDetailsRequest: false,
  isPendingRequestHistoryDetailsSuccess: false,
  isPendingRequestHistoryDetailsError: false,
  pendingRequestHistoryDetailsResponse: null,
  isApprovePendingRequestPending: false,
  isApprovePendingRequestSuccess: false,
  isApprovePendingRequestError: false,
  approvePendingRequestResponse: null,
  isRejectPendingRequestPending: false,
  isRejectPendingRequestSuccess: false,
  isRejectPendingRequestError: false,
  rejectPendingRequestResponse: null,
  isCancelPendingRequestPending: false,
  isCancelPendingRequestSuccess: false,
  isCancelPendingRequestError: false,
  cancelPendingRequestResponse: null,
  isInvestRequestSettingPending: false,
  isInvestRequestSettingError: false,
  isInvestRequestSettingSuccess: false,
  investRequestSettingResponse: null,
  investRequestSettingError: null,
  investRequestKeys: {},
  currentFilterCacheKey: '',
  [DEFAULT_CACHE_KEY]: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ requests: payloadData, count: 100000 }] };
};

function requestsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REQUESTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_REQUESTS_API_SUCCESS: {
      const response = action?.payload;
      const lobTypes = action?.lobTypes;
      const rowInfo =
        response?.payload && response?.payload.length > 0 ? response?.payload[0] : null;
      const initialRows = rowInfo?.requests;
      // // console.log('initialRequests: ', initialRequests);
      // const startIndex = action?.params?.offset;
      const allRows = createPayload(initialRows, lobTypes) || initialState?.allRows;
      const isError = response?.responseType !== statusOk;
      const count = allRows?.length || 0;

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        // response,
        // rows,
        count,
        // startIndex,
        allRows,
      };
    }

    case GET_REQUESTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case GET_NEXT_REQUESTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }
    case GET_REQUEST_HISTORY_API_REQUEST:
      return {
        ...state,
        isRequestHistoryPending: true,
        isRequestHistoryError: false,
        isRequestHistorySuccess: false,
        // error: action.payload,
      };
    case GET_REQUEST_HISTORY_API_SUCCESS: {
      const newPayload = createNewRequestPayload(action?.payload?.payload);
      return {
        ...state,
        isRequestHistoryPending: false,
        isRequestHistoryError: false,
        isRequestHistorySuccess: true,
        requestHistoryResponse: action?.payload?.payload,
        newRequestHistoryResponse: newPayload,
      };
    }
    case GET_REQUEST_HISTORY_API_ERROR:
      return {
        ...state,
        isRequestHistoryPending: false,
        isRequestHistoryError: true,
        isRequestHistorySuccess: false,
        // error: action.payload,
      };

    case SUBMIT_REQUEST_API_REQUEST: {
      return {
        ...state,
        isSubmitRequestPending: true,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: false,
      };
    }
    case SUBMIT_REQUEST_API_SUCCESS: {
      const response = action?.payload;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: true,
        submitRequestResponse: response,
      };
    }
    case SUBMIT_REQUEST_API_ERROR: {
      const error = action?.payload;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: true,
        isSubmitRequestSuccess: false,
        submitRequestError: error,
      };
    }
    case GET_REQUEST_HISTORY_DETAILS_API_REQUEST:
      return {
        ...state,
        isRequestHistoryDetailsPending: true,
        isRequestHistoryDetailsError: false,
        isRequestHistoryDetailsSuccess: false,
      };
    case GET_REQUEST_HISTORY_DETAILS_API_SUCCESS: {
      const newPayload = createPendingRequestDetailsPayload(action?.payload?.payload);
      return {
        ...state,
        isRequestHistoryDetailsPending: false,
        isRequestHistoryDetailsError: false,
        isRequestHistoryDetailsSuccess: true,
        requestHistoryDetailsResponse: newPayload,
      };
    }
    case GET_REQUEST_HISTORY_DETAILS_API_ERROR:
      return {
        ...state,
        isRequestHistoryDetailsPending: false,
        isRequestHistoryDetailsError: true,
        isRequestHistoryDetailsSuccess: false,
      };
    case GET_PENDING_REQUEST_HISTORY_API_REQUEST:
      return {
        ...state,
        isPendingRequestHistoryPending: true,
        isPendingRequestHistoryError: false,
        isPendingRequestHistorySuccess: false,
        approvePendingRequestResponse: null,
      };
    case GET_PENDING_REQUEST_HISTORY_API_SUCCESS: {
      const newPayload = createNewPendingRequestPayload(action?.payload?.payload);
      return {
        ...state,
        isPendingRequestHistoryPending: false,
        isPendingRequestHistoryError: false,
        isPendingRequestHistorySuccess: true,
        pendingRequestHistoryResponse: action.payload,
        newRequestHistoryResponse: newPayload,
      };
    }
    case GET_PENDING_REQUEST_HISTORY_API_ERROR:
      return {
        ...state,
        isPendingRequestHistoryPending: false,
        isPendingRequestHistoryError: true,
        isPendingRequestHistorySuccess: false,
      };
    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST:
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: true,
        isPendingRequestHistoryDetailsError: false,
        isPendingRequestHistoryDetailsSuccess: false,
        approvePendingRequestResponse: null,
      };
    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS: {
      const newPayload = createPendingRequestDetailsPayload(action?.payload?.payload);
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: false,
        isPendingRequestHistoryDetailsError: false,
        isPendingRequestHistoryDetailsSuccess: true,
        pendingRequestHistoryDetailsResponse: newPayload,
      };
    }
    case GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR:
      return {
        ...state,
        isPendingRequestHistoryDetailsPending: false,
        isPendingRequestHistoryDetailsError: true,
        isPendingRequestHistoryDetailsSuccess: false,
      };
    case APPROVE_PENDING_REQUEST_API_REQUEST:
      return {
        ...state,
        isApprovePendingRequestPending: true,
        isApprovePendingRequestError: false,
        isApprovePendingRequestSuccess: false,
      };
    case APPROVE_PENDING_REQUEST_API_SUCCESS: {
      return {
        ...state,
        isApprovePendingRequestPending: false,
        isApprovePendingRequestError: false,
        isApprovePendingRequestSuccess: true,
        approvePendingRequestResponse: action.payload,
      };
    }
    case APPROVE_PENDING_REQUEST_API_ERROR:
      return {
        ...state,
        isApprovePendingRequestPending: false,
        isApprovePendingRequestError: true,
        isApprovePendingRequestSuccess: false,
        approvePendingRequestResponse: action.payload,
      };
    case REJECT_PENDING_REQUEST_API_REQUEST:
      return {
        ...state,
        isRejectPendingRequestPending: true,
        isRejectPendingRequestError: false,
        isRejectPendingRequestSuccess: false,
      };
    case REJECT_PENDING_REQUEST_API_SUCCESS: {
      return {
        ...state,
        isRejectPendingRequestPending: false,
        isRejectPendingRequestError: false,
        isRejectPendingRequestSuccess: true,
        rejectPendingRequestResponse: action.payload,
      };
    }
    case REJECT_PENDING_REQUEST_API_ERROR:
      return {
        ...state,
        isRejectPendingRequestPending: false,
        isRejectPendingRequestError: true,
        isRejectPendingRequestSuccess: false,
      };

    case CANCEL_PENDING_REQUEST_API_REQUEST:
      return {
        ...state,
        isCancelPendingRequestPending: true,
        isCancelPendingRequestError: false,
        isCancelPendingRequestSuccess: false,
      };
    case CANCEL_PENDING_REQUEST_API_SUCCESS: {
      return {
        ...state,
        isCancelPendingRequestPending: false,
        isCancelPendingRequestError: false,
        isCancelPendingRequestSuccess: true,
        cancelPendingRequestResponse: action.payload,
      };
    }
    case CANCEL_PENDING_REQUEST_API_ERROR:
      return {
        ...state,
        isCancelPendingRequestPending: false,
        isCancelPendingRequestError: true,
        isCancelPendingRequestSuccess: false,
      };

    case GET_INVEST_REQUEST_SETTING_API_REQUEST: {
      return {
        ...state,
        isInvestRequestSettingPending: true,
        isInvestRequestSettingError: false,
        isInvestRequestSettingSuccess: false,
        investRequestKeys: null,
      };
    }
    case GET_INVEST_REQUEST_SETTING_API_SUCCESS: {
      const response = action?.payload;
      const cacheKey = action?.cacheKey;
      return {
        ...state,
        isInvestRequestSettingPending: false,
        isInvestRequestSettingError: false,
        isInvestRequestSettingSuccess: true,
        investRequestSettingResponse: response,
        investRequestKeys: response?.payload?.requestKeys,
        currentFilterCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_INVEST_REQUEST_SETTING_API_ERROR: {
      const error = action?.payload;

      return {
        ...state,
        isInvestRequestSettingPending: false,
        isInvestRequestSettingError: true,
        isInvestRequestSettingSuccess: false,
        investRequestSettingError: error,
      };
    }
    default:
      return state;
  }
}

export default requestsReducer;
