import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  Paper,
  TextField,
  IconButton,
  Chip,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Divider,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Clear as ClearIcon } from '@mui/icons-material';
import classnames from 'classnames';
import {
  dateToString,
  isoStringToDate,
  stringToDate,
  stringToIsoDate,
  createRelativeUrl,
  dateToIsoString,
} from '../../../utils/functions';
import { resetInsurance } from '../../../actions/portfolio';
import { resetClaims } from '../../../actions/claims';
import { resetTempState, updateTempState } from '../../../actions/temp';
import {
  claimsIndexName,
  quotesIndexName,
  requestsIndexName,
  receiptsIndexName,
  insuredItemsIndexName,
  insuranceIndexName,
  clientPoliciesIndexName,
  agentType,
  healthLob,
  dutchTemplate,
} from '../../../utils/variables';
import { useRouter, useVariables } from '../../../hooks';
import styles from './style';

const useStyles = makeStyles(styles);

function getStyles(name, value, theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

const FilterComponent = () => {
  // console.log('filterOptions: ', filterOptions);
  // debugger;
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();
  const query = router?.query;
  const filterOptions = useSelector(state => state.temp.filterOptions);
  const userType = useSelector(state => state?.auth?.usertype);

  const [holdingCurrency, setHoldingCurrency] = React.useState(
    filterOptions?.holdingCurrency,
  );
  const [assetType, setAssetType] = React.useState(filterOptions?.assetType || null);
  const [category, setCategory] = React.useState(filterOptions?.lob || []);
  const [currency, setCurrency] = React.useState(filterOptions?.currency || []);
  const [status, setStatus] = React.useState(filterOptions?.status || []);
  const [startDate, setStartDate] = React.useState(filterOptions?.startDate);
  const [endDate, setEndDate] = React.useState(filterOptions?.endDate);

  let insuranceFilterSettings = useSelector(
    state => state?.insurance?.insuranceFilterSettings,
  );

  const handleChangeCategory = useCallback(_value => event => {
    let value = _value;
    const index = category?.indexOf(value);
    // console.log('Value', value);
    if (index > -1) {
      category.splice(index, 1);
      value = [...category];
    } else {
      value = [...category, value];
    }
    setCategory(value);
  });

  const handleChangeCurrency = useCallback(_value => event => {
    let value = _value;
    const index = currency?.indexOf(value);
    // console.log('Value', value);
    if (index > -1) {
      currency.splice(index, 1);
      value = [...currency];
    } else {
      value = [...currency, value];
    }
    setCurrency(value);
  });

  const handleChangeStatus = useCallback(_value => event => {
    let value = _value;
    if (value) {
      const index = status?.indexOf(value);
      // console.log('Value', value);
      if (index > -1) {
        status.splice(index, 1);
        value = [...status];
      } else {
        value = [...status, value];
      }
    }
    setStatus(value);
  });

  const handleStartDate = value => {
    // console.log('date: ', value);
    const newStringDate = value;
    let newDate = null;
    if (
      filterOptions?.indexName === insuranceIndexName ||
      filterOptions?.indexName === clientPoliciesIndexName ||
      filterOptions?.indexName === claimsIndexName
    ) {
      newDate = stringToIsoDate(newStringDate);
    } else {
      newDate = stringToDate(newStringDate);
    }
    setStartDate(newDate);
  };

  const handleEndDate = value => {
    // console.log('date: ', value);
    const newStringDate = value;
    let newDate = null;
    if (
      filterOptions?.indexName === insuranceIndexName ||
      filterOptions?.indexName === clientPoliciesIndexName ||
      filterOptions?.indexName === claimsIndexName
    ) {
      newDate = stringToIsoDate(newStringDate);
    } else {
      newDate = stringToDate(newStringDate);
    }
    setEndDate(newDate);
  };

  const handleAssetTypeChange = event => {
    const { value } = event.target;
    setAssetType(value);

    // // console.log('parsed: ', parsed);
  };

  const handleHoldingCurrencyChange = event => {
    const { value } = event.target;
    setHoldingCurrency(value);
  };

  const onClear = () => {
    setAssetType(null);
    setCurrency([]);
    setStatus([]);
    setStartDate('');
    setEndDate('');
    if (userType !== agentType) {
      // category (lob) need not to reset for agent
      setCategory([]);
    }
  };
  const onSubmit = () => {
    // router.storePush(`${router.pathname}?currency=${currency}&assetType=${assetType}`);
    if (
      filterOptions?.indexName === insuranceIndexName ||
      filterOptions?.indexName === clientPoliciesIndexName
    ) {
      dispatch(resetInsurance.resetInsuranceAction());
    }
    if (filterOptions?.indexName === claimsIndexName) {
      dispatch(resetClaims.resetClaimsAction());
    }
    const newQuery = {
      ...query,
    };
    let stringStartDate = null;
    let stringEndDate = null;
    if (
      indexName === insuranceIndexName ||
      indexName === clientPoliciesIndexName ||
      indexName === claimsIndexName
    ) {
      stringStartDate = dateToIsoString(startDate);
      stringEndDate = dateToIsoString(endDate);
    } else {
      stringStartDate = dateToString(startDate, 'yyyy-MM-dd');
      stringEndDate = dateToString(endDate, 'yyyy-MM-dd');
    }

    const params = {
      ...query,
      startIndex: 0,
      currency: (currency && currency?.toString()) || undefined,
      lob: (category && category?.toString()) || undefined,
      status: (status && status?.toString()) || undefined,
      startDate: (stringStartDate && stringStartDate?.toString()) || undefined,
      endDate: (stringEndDate && stringEndDate?.toString()) || undefined,
      holdingCurrency: (holdingCurrency && holdingCurrency?.toString()) || undefined,
      assetType: (assetType && assetType?.toString()) || undefined,
    };

    const relativeUrl = createRelativeUrl({
      path: router.pathname,
      queryObject: params,
    });
    // console.log('relativeUrl: ', relativeUrl);
    router.push(relativeUrl);
    dispatch(updateTempState.updateTempStateAction({ filterOpen: false }));
    // console.log('parsed: ', parsed);
  };

  const indexName = filterOptions?.indexName;

  if (indexName !== insuranceIndexName) {
    insuranceFilterSettings = null;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.currencies.length > 0) {
    const filteredCurrencies = insuranceFilterSettings?.currencies || [];
    /*
    filterOptions?.currencies?.filter(cls =>
      insuranceFilterSettings?.currencies.includes(cls),
    );
    */
    if (filteredCurrencies && filterOptions)
      filterOptions.currencies = filteredCurrencies;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.statuses.length > 0) {
    const filteredStatuses = insuranceFilterSettings?.statuses || [];
    /*
      filterOptions?.statuses?.filter(cls =>
      insuranceFilterSettings?.statuses.includes(cls.status),
    );
    */
    if (filteredStatuses && filterOptions) filterOptions.statuses = filteredStatuses;
  }
  if (insuranceFilterSettings && insuranceFilterSettings?.policyTypes.length > 0) {
    const filteredPolicyTypes = filterOptions?.lobTypes?.filter(cls =>
      insuranceFilterSettings?.policyTypes.includes(cls.lob),
    );
    if (filteredPolicyTypes && filterOptions)
      filterOptions.lobTypes = filteredPolicyTypes;
  }

  let filterDateType = 'Date Range';
  if (indexName === quotesIndexName) {
    filterDateType = 'Created Date Range';
  }
  if (indexName === claimsIndexName) {
    filterDateType = 'Date Range';
  }
  if (indexName === requestsIndexName) {
    filterDateType = 'Created Date Range';
  }
  if (indexName === receiptsIndexName) {
    filterDateType = 'Paid Date Range';
  }
  if (indexName === insuredItemsIndexName) {
    filterDateType = 'Added On Date Range';
  }
  if (indexName === insuranceIndexName) {
    filterDateType = 'Date Range';
  }
  return (
    <Paper elevation={0} className={classes.paper}>
      {filterOptions?.showFilters?.date && (
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            className={classnames(classes.gridItem, classes.gridItemHeader)}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{filterDateType}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Grid container justifyContent="center" alignItems="center">
              <TextField
                id="start"
                label="Start Date"
                type="date"
                placeholder="YYYY-MM-DD"
                fullWidth
                value={dateToString(startDate, 'yyyy-MM-dd')}
                onChange={event => handleStartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: false,
                  endAdornment: (
                    <IconButton
                      style={{ padding: 5, marginLeft: 5 }}
                      edge="end"
                      size="small"
                      disabled={!startDate}
                      onClick={() => handleStartDate('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  className: classes.datePicker,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.gridItem}>
            <Grid container justifyContent="center" alignItems="center">
              <TextField
                id="end"
                label="End Date"
                placeholder="YYYY-MM-DD"
                type="date"
                value={dateToString(endDate, 'yyyy-MM-dd')}
                onChange={event => handleEndDate(event.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: false,
                  endAdornment: (
                    <IconButton
                      style={{ padding: 5, marginLeft: 5 }}
                      edge="end"
                      size="small"
                      disabled={!endDate}
                      onClick={() => handleEndDate('')}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  className: classes.datePicker,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      {filterOptions?.showFilters?.lob &&
      filterOptions.lobTypes &&
      filterOptions.lobTypes.length > 1 ? (
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            className={classnames(classes.gridItem, classes.gridItemHeader)}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Product Type</Typography>
          </Grid>
          {filterOptions.lobTypes.map(item => {
            if (!item) return null;
            return (
              <Grid key={item.id} item xs={4} className={classes.gridItem}>
                <Button
                  fullWidth
                  className={classes.btn}
                  variant={
                    category && category.indexOf(item.lob) > -1 ? 'contained' : 'outlined'
                  }
                  color="primary"
                  onClick={handleChangeCategory(item.lob)}
                  disableElevation
                >
                  {item.title}
                </Button>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ) : null}
      {filterOptions?.showFilters?.currency &&
        filterOptions.currencies &&
        filterOptions.currencies.length > 0 && (
          <Grid container direction="row" spacing={1}>
            <Grid
              item
              xs={12}
              className={classnames(classes.gridItem, classes.gridItemHeader)}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Currency</Typography>
            </Grid>
            {filterOptions.currencies.map(item => {
              if (!item) return null;
              return (
                <Grid key={item} item xs={4} className={classes.gridItem}>
                  <Button
                    fullWidth
                    className={classes.btn}
                    variant={
                      currency && currency.indexOf(item) > -1 ? 'contained' : 'outlined'
                    }
                    color="primary"
                    onClick={handleChangeCurrency(item)}
                    disableElevation
                  >
                    {item}
                  </Button>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        )}
      {filterOptions?.showFilters?.status &&
        filterOptions.statuses &&
        filterOptions.statuses.length > 0 && (
          <Grid container direction="row" spacing={1}>
            <Grid
              item
              xs={12}
              className={classnames(classes.gridItem, classes.gridItemHeader)}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Status</Typography>
            </Grid>
            {filterOptions.statuses.map(item => {
              if (!item) return null;
              return (
                <Grid item xs={4} className={classes.gridItem} key={item}>
                  <Button
                    fullWidth
                    className={classes.btn}
                    variant={
                      status && status.indexOf(item) > -1 ? 'contained' : 'outlined'
                    }
                    color="primary"
                    onClick={handleChangeStatus(item)}
                    disableElevation
                  >
                    {item}
                  </Button>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        )}

      {filterOptions?.showFilters?.holdingCurrency && (
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Security Currency</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <InputLabel id="label-1">Selected Currencies</InputLabel>
              <Select
                labelId="label-1"
                id="chip-1"
                multiple
                fullWidth
                value={holdingCurrency}
                onChange={handleHoldingCurrencyChange}
                label="Selected Currencies"
                // input={<Input id="select-multiple-chip-2" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => {
                      if (!value) return null;
                      return <Chip key={value} label={value} className={classes.chip} />;
                    })}
                  </div>
                )}
              >
                {filterOptions.holdingCurrencies.map(item => (
                  <MenuItem
                    key={item}
                    value={item}
                    style={getStyles(item, assetType, theme)}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      {filterOptions?.showFilters?.assetType && (
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'bold' }}>Asset Type</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <InputLabel id="label-2">Selected Assets</InputLabel>
              <Select
                labelId="label-2"
                id="chip-2"
                multiple
                value={assetType}
                onChange={handleAssetTypeChange}
                fullWidth
                label="Selected Assets"
                // input={<Input id="select-multiple-chip-2" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => {
                      if (!value) return null;
                      return <Chip key={value} label={value} className={classes.chip} />;
                    })}
                  </div>
                )}
              >
                {filterOptions.assetTypes.map(item => {
                  if (!item) return null;
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                      style={getStyles(item, assetType, theme)}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={4}>
          <Button
            onClick={onClear}
            className={classes.secondaryButton}
            fullWidth
            size="large"
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            onClick={onSubmit}
            className={classes.primaryButton}
            fullWidth
            size="large"
          >
            Filter
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterComponent;
