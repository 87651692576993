import { Grid, Typography, Box, IconButton, Icon, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './style';
import { useVariables } from '../../../hooks';

const useStyles = makeStyles(styles);
const HelpCard = ({ show }) => {
  const classes = useStyles();
  const variables = useVariables();
  const contactInfo = variables?.contactNumbers?.customer_service;

  if (!show) {
    return null;
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={3} sm={2} md={2.5} lg={2} align="center">
        <img alt="profile_img" src="/images/contactInfo.png" className={classes.image} />
      </Grid>
      <Grid item xs={7.5} sm={8} md={9} lg={9}>
        <Typography className={classes.title} align="left" color="primary" variant="h6">
          Need help?
        </Typography>
        <Typography color="primary" align="left" className={classes.helpText}>
          Delighted to assist you! Feel free to reach out to us. Contact us here, and
          we'll be more than happy to help!
        </Typography>
        <Grid container xs={12} className={classes.contactContainer}>
          <Grid item xs={10} sm={6} md={6} lg={6} className={classes.contactItem}>
            <WhatsAppIcon className={classes.contactIcon} />
            <Typography className={classes.contactText} align="left">
              {contactInfo?.phone || 'xxxxxxxxx'}
            </Typography>
          </Grid>
          <Grid item xs={5} sm={3} md={3} lg={3} className={classes.contactItem}>
            <ChatOutlinedIcon className={classes.chatIcon} />
            <Typography variant="p" className={classes.chatText}>
              Chat
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpCard;
