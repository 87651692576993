import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  UPDATE_CLAIMS_CURRENT_CACHE_KEY,
  GET_CLAIMS_API_REQUEST,
  GET_CLAIMS_API_SUCCESS,
  GET_CLAIMS_API_ERROR,
  GET_NEXT_CLAIMS_SUCCESS,
  GET_CLAIM_DETAILS_API_REQUEST,
  GET_CLAIM_DETAILS_API_SUCCESS,
  GET_CLAIM_DETAILS_API_ERROR,
  RESET_CLAIMS_SUCCESS,
  GET_INSURANCE_DETAILS_REQUEST,
  GET_INSURANCE_DETAILS_SUCCESS,
  GET_INSURANCE_DETAILS_ERROR,
  GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST,
  GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS,
  GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR,
  UPDATE_GLOC_CLAIM_FIELD,
  SAVE_HEALTH_CLAIM_SUCCESS,
  SAVE_HEALTH_CLAIM_REQUEST,
  SAVE_HEALTH_CLAIM_ERROR,
  SUBMIT_HEALTH_CLAIM_REQUEST,
  SUBMIT_HEALTH_CLAIM_SUCCESS,
  SUBMIT_HEALTH_CLAIM_ERROR,
  UPDATE_GLOC_CLAIM_MULTIPLE_FIELDS,
  RESET_HEALTH_CLAIMS,
  TOGGLE_INSTRUCTION_MODAL,
  IS_HEALTH_CLAIMS_FORM_DIRTY,
} from '../../constants/claimActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';
import {
  CLEAR_CLAIMS_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../constants/miscActionNames';
import { statusOk } from '../../utils/variables';

import payloadData from './payloadData';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  stringToDateTime,
  dateTimeToString,
  generateSearchWords,
  getKeys,
  getAllKeys,
  uniq,
} from '../../utils/functions';

// Rename keys in an array

const createBreakDown = (payloadArray, claim) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      id: index,
      breakDownId: index,
      currency: claim?.currency,
      serviceDate: dateToString(isoStringToDate(object?.fromServiceDate)),
      dateClaimEntry: dateToString(isoStringToDate(object?.createdDate)),
      receivedDate: dateToString(isoStringToDate(object?.receivedDate)),
      description: object?.description || '-',
      status: object?.claimProcedureStatus || '-',
      totalCharged: object?.submittedAmount,
      totalPatientResp: object.patient_responsibility,
      totalPaid: object?.paidAmount,
      totalDeductible: object?.deductibleAmount,
      diagnosis: object?.diagnosis || null,
      procedure: object?.procedure || null,
      provider: object?.provider || null,
      fromDate: dateToString(isoStringToDate(object?.fromDate)),
      toDate: dateToString(isoStringToDate(object?.toDate)),
      chequeNumber: object?.checkNumber || '-',
      chequeDate: dateToString(isoStringToDate(object?.checkDate)),
      remarks: object?.remarks || null,
      productName: object?.productName?.split(' ')[0],
      payee: object?.payee || '-',
    };
  });
};

// Check data returned from backend API to ensure correct mapping
const createIncident = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      id: index,
      locationOfLoss: object?.locationOfLoss,
      reportedAt: object?.reportedAt || '-',
      narrative: object?.narrative || '-',
      dateOfLoss: dateToString(isoStringToDate(object?.dateOfLoss)) || '-',
    };
  });
};

// Check data returned from backend API to ensure correct mapping
const createClaimant = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      id: index,
      name: object?.name || '-',
      date: dateToString(isoStringToDate(object?.dateReported)) || '-',
      type: object?.type || '-',
    };
  });
};

const createClaimStatus = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.map((object, index) => {
    return {
      id: index,
      item: object?.item || null,
      date: object?.date || '-',
      status: object?.status || '-',
    };
  });
};

const createIdentifier = row => {
  return {
    vehicleIdentifier: row?.vehicleIdentifier || row?.indexNo || '-',
  };
};

// parseInt(startIndex + index, 10)
//
let searchKeys = [];

const convertStatus = originalStatus => {
  let newStatus = 'Submitted';
  if (
    originalStatus?.toUpperCase() === 'APPROVED' ||
    originalStatus?.toUpperCase() === 'PEND' ||
    originalStatus?.toUpperCase() === 'ENTERED' ||
    originalStatus?.toUpperCase() === 'OPENED' ||
    originalStatus?.toUpperCase() === 'REOPENED'
  ) {
    newStatus = 'Processing';
  }
  if (
    originalStatus?.toUpperCase() === 'CLOSED' ||
    originalStatus?.toUpperCase() === 'FINALISED'
  ) {
    newStatus = 'Completed';
  }
  return newStatus;
};

const createPayload = (payloadArray, lobTypes, cacheKey, body) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newClaims = payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex;
    });
    // console.log('lobObj: ', lobObj);
    const claim = {
      ...object,
      index: uuidv4(),
      id: object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      slug: object?.claimNo || object?._id?.split('_')[1], // object?._id?.split('_')[1] || object?._id,
      lob: lobObj?.lob,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      color: lobObj?.color,
      patientName: object?.name || '',
      clientFirstName: object?.name?.includes(' ')
        ? object?.name?.split(' ')[0]
        : object?.name,
      clientLastName: object?.name?.includes(' ') ? object?.name?.split(' ')[1] : '',
      clientFullNameNoSpace: `${object?.name} ${object?.name}`?.replace(/ /g, ''),
      healthProductName: object?.displayName,
      productName: object?.productName,
      claimNum: object?.claimNo || object?._id?.split('_')?.[1],
      policyNum: object?.policyNo || object?.coverage?.split('/')?.[1],
      displayNum: object?.coverage?.split('/')?.[2]?.split('-')?.[0],
      // policyNumberMotor: object?.policyNo,
      cardNum: object?.coverage?.split('/')[2],
      type: object?.type,
      name: object?.name,
      insured: object?.insuredName,
      insuredNoSpace: object?.insuredName?.replace(/ /g, ''),
      reference: object?.reference,
      vehicleNumber: object?.vehicleNumber,
      vehicleNumberNoSpace: object?.vehicleNumber?.replace(/ /g, ''),
      currency: object?.currency,
      createdDate: dateToString(isoStringToDate(object?.createdDate)),
      reportDate: dateToString(isoStringToDate(object?.reportDate)), // dateReported: dateToString(isoStringToDate(object?.reportDate)),
      dateOfLoss: dateToString(isoStringToDate(object?.dateOfLoss)),
      serviceDate: dateToString(isoStringToDate(object?.serviceDate)),
      healthServiceDate: dateToString(isoStringToDate(object?.fromServiceDate)),
      dateClaimEntry: dateToString(isoStringToDate(object?.receiveDate)),
      patientResponsibility: object?.claimDetails?.reduce(
        (acc, currentObject) => acc + parseFloat(currentObject?.patient_responsibility),
        0,
      ),
      totalCharged: object?.claimDetails?.reduce(
        (acc, currentObject) => acc + parseFloat(currentObject?.charge),
        0,
      ),
      totalDeductible: object?.claimDetails?.reduce(
        (acc, currentObject) => acc + parseFloat(currentObject?.deductibleAmount),
        0,
      ),
      totalPaid: object?.claimDetails?.reduce(
        (acc, currentObject) => acc + parseFloat(currentObject?.paidAmount),
        0,
      ),
      totalPatientResp: object?.claimDetails?.reduce(
        (acc, currentObject) => acc + parseFloat(currentObject?.patient_responsibility),
        0,
      ),
      status: object?.statusTitle,
      originalStatus: object?.status,
      locationOfLoss: object?.incidentLocation,
      reportedAt: object?.reportedAt,
      narrative: object?.narrative,
      agentBroker: object?.agent,
      claimantData: createClaimant(object?.claimants),
      claimStatus: createClaimStatus(object?.claimItemsList, object),
      breakDown: createBreakDown(object?.claimDetails, object),
      // claimNumber: object?._id?.split('_')[1] || object?._id,
      policyNumber: object?.policyNo, // object?.coverage?.split('/')[1],

      dateReported: dateToString(isoStringToDate(object?.reportDate)),

      provider:
        object?.claimDetails && object?.claimDetails.length > 0
          ? object?.claimDetails[0]?.provider
          : '',
      cardNumber: object?.coverage?.split('/')[2] || '',
      procedure:
        object?.claimDetails && object?.claimDetails.length > 0
          ? object?.claimDetails[0]?.procedure
          : '',

      // {object?.claimDetails && object?.claimDetails.length > 0
      //   ? object?.claimDetails[0]?.status
      //   : ''},
      paymentInfo: object?.paymentInfo,
      claimType: object?.claimType,
      statusMotor: object?.statusTitle,
      coverage: object?.coverage,
      owner: object?.owner,
      patientNum: object?.patient,
      amount: object?.amount,
      comments: object?.comments,
      payor: object?.payor,

      filterId: object?.policyNo,
      filterDate: dateToString(
        isoStringToDate(object?.fromServiceDate || object?.dateOfLoss),
      ),
      filterStatus: object?.statusTitle,
      filterLob: object?.lob,
      // incidentData: createIncident(object?.incidentData),
      // ...searchWords,
      claimDetails: object?.claimDetails,

      address: object?.address?.length > 0 ? object?.address : '--',
      claimAmount: object?.claimAmount ?? '--',
      phoneNumber:
        object?.phoneNumber?.length > 0
          ? `+${object?.insured.contactNumbers[0].extension} ${object.phoneNumber}`
          : '--',
      dateOfBirth: dateToString(isoStringToDate(object?.dateOfBirth), 'MM dd yyyy'),
      relation: object?.relation,

      organisation: object.organisation,
      totalClaims: object?.transmittalDetails?.length,
      claimSubmittedDate: dateToString(
        isoStringToDate(object?.claimSubmittedDate),
        'MM dd yyyy',
      ),
      submittedDate: dateToString(isoStringToDate(object?.submittedDate), 'MM dd yyyy'),
      transmittalForm: object?.transmittalForm ?? {},
      batchClaimForm: object?.uploadedFiles,
      documentId: object?.claimProcessors?.wms?.documentId,
      batchId: object?.batchId ?? '--',
      cacheKey,
      body,
    };

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);
    const newKeys = getKeys(claim);

    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    // 'claimNum', 'policyNum'; "insured" "dateOfLoss"
    // // console.log('searchKeys: ', searchKeys);

    return claim;
  });

  /*
  newClaims.sort((a, b) => {
    if (a.filterDate === b.filterDate) {
      return 1;
    }
    return b.filterDate >= a.filterDate ? 1 : -1;
  });
  */

  return newClaims;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  total: {},
  isInsurancesPending: false,
  isInsurancesError: false,
  isInsurancesSuccess: false,
  insurances: [],
  isUserDetailsPending: false,
  isUserDetailsError: false,
  isUserDetailsSuccess: false,
  healthClaimId: '',
  saveHealthClaimRequest: false,
  saveHealthClaimSuccess: false,
  saveHealthClaimError: false,
  submitHealthClaimRequest: false,
  submitHealthClaimSuccess: false,
  submitHealthClaimError: false,
  showInstructionsModal: false,
  individualInsuranceDetails: {
    members: [],
    title: '',
  },
  isHealthClaimsFormDirty: false,
  healthClaimForm: {
    policy: '',
    claimRecipient: 'self',
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    policyName: '',
    address: '',
    selectMember: '',
    phoneNumber: '',
    claimAmount: '',
    relation: 'self',
    claimForm: [],
    supportingDocuments: [],
    userConfirmation: false,
  },
  [DEFAULT_CACHE_KEY]: null,
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ claims: payloadData, count: 100000 }] };
};

function claimsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLAIMS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_CLAIMS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      const cacheKey = action?.cacheKey;

      const rowPayload = createPayload(
        action?.payload?.payload?.result?.data || [],
        lobTypes,
        cacheKey,
        body,
      );
      const newRows = rowPayload;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.result?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // console.log('insurances: ', insurances);
      // const insuranceCollections = collections;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        searchKeys,
        allRows: newRows,
        count,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
        // incomingRows,
        // claims: claimsArray,
      };
    }

    case UPDATE_CLAIMS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case GET_CLAIMS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case GET_NEXT_CLAIMS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case CLEAR_CLAIMS_CACHE: {
      return {
        ...state,
        [DEFAULT_CACHE_KEY]: null,
        allRows: initialState.allRows,
        total: initialState.total,
      };
    }

    case RESET_CLAIMS_SUCCESS: {
      return {
        ...state,
        total: initialState.total,
        allRows: initialState.allRows,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        [DEFAULT_CACHE_KEY]: null,
        total: initialState.total,
        allRows: initialState.allRows,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case GET_CLAIM_DETAILS_API_REQUEST:
      return {
        ...state,
        isDetailsPending: true,
        isDetailsError: false,
        isDetailsSuccess: false,
      };
    case GET_CLAIM_DETAILS_API_SUCCESS: {
      const details = action?.payload;
      const newClaim = details?.payload;
      const { vehicleIdentifier, indexNo } = newClaim;
      const claimId = action?.params?.claimNo;
      const allRows = state?.allRows || [];
      const foundIndex = allRows?.findIndex(x => x?.claimNo === claimId);
      const claimRow = allRows[foundIndex];
      claimRow.vehicleIdentifier = vehicleIdentifier;
      allRows[foundIndex] = claimRow;
      // console.log('Vehicle Number', row);
      return {
        ...state,
        isDetailsPending: false,
        isDetailsError: false,
        isDetailsSuccess: true,
        details,
        identifier: vehicleIdentifier || indexNo,
        allRows,
      };
    }
    case GET_CLAIM_DETAILS_API_ERROR:
      return {
        ...state,
        isDetailsPending: false,
        isDetailsError: true,
        isDetailsSuccess: false,
      };

    case GET_INSURANCE_DETAILS_REQUEST:
      return {
        ...state,
        isInsurancesPending: true,
        isInsurancesSuccess: false,
        isInsurancesError: false,
      };
    case GET_INSURANCE_DETAILS_SUCCESS: {
      const { payload } = action || {};
      const { payload: { result: { data: _insurances } = {} } = {} } = payload || {};
      const insurances = _insurances.map(({ id, cardNumber, title, members }) => ({
        id,
        cardNumber,
        title,
        members,
      }));
      return {
        ...state,
        insurances,
        isInsurancesPending: false,
        isInsurancesSuccess: true,
        isInsurancesError: false,
      };
    }
    case GET_INSURANCE_DETAILS_ERROR:
      return {
        ...state,
        isInsurancesPending: false,
        isInsurancesSuccess: false,
        isInsurancesError: true,
      };

    case GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST:
      return {
        ...state,
        isUserDetailsPending: true,
        isUserDetailsError: false,
        isUserDetailsSuccess: false,
      };

    case GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS: {
      const { payload: { payload: { members = [], title } = {} } = {} } = action || {};
      return {
        ...state,
        individualInsuranceDetails: {
          members,
          title,
        },
        isUserDetailsPending: false,
        isUserDetailsError: false,
        isUserDetailsSuccess: true,
      };
    }
    case GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR:
      return {
        ...state,
        isUserDetailsPending: false,
        isUserDetailsError: true,
        isUserDetailsSuccess: false,
      };
    case UPDATE_GLOC_CLAIM_FIELD:
      return {
        ...state,
        healthClaimForm: {
          ...state.healthClaimForm,
          [action.payload.field]: action.payload.value,
        },
      };
    case UPDATE_GLOC_CLAIM_MULTIPLE_FIELDS: {
      const healthClaimId = action.payload?.healthClaimId;
      delete action.payload?.healthClaimId;
      return {
        ...state,
        healthClaimForm: {
          ...state.healthClaimForm,
          ...action.payload,
        },
        healthClaimId: healthClaimId ?? state.healthClaimId,
      };
    }
    case SAVE_HEALTH_CLAIM_REQUEST: {
      return {
        ...state,
        saveHealthClaimRequest: true,
      };
    }
    case SAVE_HEALTH_CLAIM_SUCCESS: {
      return {
        ...state,
        healthClaimId: action.payload.c360ClaimId || action?.payload?.id,
        saveHealthClaimSuccess: true,
        saveHealthClaimRequest: false,
        saveHealthClaimError: false,
      };
    }
    case SAVE_HEALTH_CLAIM_ERROR: {
      return {
        ...state,
        saveHealthClaimRequest: false,
      };
    }
    case SUBMIT_HEALTH_CLAIM_REQUEST: {
      return {
        ...state,
        submitHealthClaimRequest: true,
        submitHealthClaimSuccess: false,
        submitHealthClaimError: false,
      };
    }
    case SUBMIT_HEALTH_CLAIM_SUCCESS: {
      return {
        ...state,
        healthClaimId: action.payload.c360ClaimId || action?.payload?.id,
        submitHealthClaimRequest: false,
        submitHealthClaimSuccess: true,
        submitHealthClaimError: false,
      };
    }
    case SUBMIT_HEALTH_CLAIM_ERROR: {
      return {
        ...state,
        submitHealthClaimRequest: false,
        submitHealthClaimSuccess: false,
        submitHealthClaimError: true,
      };
    }
    case RESET_HEALTH_CLAIMS: {
      return {
        ...state,
        healthClaimForm: { ...initialState.healthClaimForm },
        individualInsuranceDetails: initialState.individualInsuranceDetails,
        isInsurancesPending: initialState.isInsurancesPending,
        isInsurancesError: initialState.isInsurancesError,
        isInsurancesSuccess: initialState.isInsurancesSuccess,
        insurances: initialState.insurances,
        isUserDetailsPending: initialState.isUserDetailsPending,
        isUserDetailsError: initialState.isUserDetailsError,
        isUserDetailsSuccess: initialState.isUserDetailsSuccess,
        healthClaimId: initialState.healthClaimId,
        saveHealthClaimRequest: initialState.saveHealthClaimRequest,
        saveHealthClaimSuccess: initialState.saveHealthClaimSuccess,
        saveHealthClaimError: initialState.saveHealthClaimError,
        submitHealthClaimRequest: initialState.submitHealthClaimRequest,
        submitHealthClaimSuccess: initialState.submitHealthClaimSuccess,
        submitHealthClaimError: initialState.submitHealthClaimError,
      };
    }
    case TOGGLE_INSTRUCTION_MODAL: {
      return {
        ...state,
        showInstructionsModal: action.payload,
      };
    }
    case IS_HEALTH_CLAIMS_FORM_DIRTY: {
      return {
        ...state,
        isHealthClaimsFormDirty: action.payload,
      };
    }
    default:
      return state;
  }
}

export default claimsReducer;

/*

    */
