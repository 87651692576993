export const UPDATE_QUOTES_CURRENT_CACHE_KEY = 'UPDATE_QUOTES_CURRENT_CACHE_KEY';
export const GET_QUOTES_API_REQUEST = 'GET_QUOTES_API_REQUEST';
export const GET_QUOTES_API_SUCCESS = 'GET_QUOTES_API_SUCCESS';
export const GET_QUOTES_API_ERROR = 'GET_QUOTES_API_ERROR';
export const GET_NEXT_QUOTES_SUCCESS = 'GET_NEXT_QUOTES_SUCCESS';
export const UPDATE_QUOTES_TOTAL = 'UPDATE_QUOTES_TOTAL';
export const UPDATE_QUOTES_STATUS = 'UPDATE_QUOTES_STATUS';
export const DECLINE_QUOTES_API_REQUEST = 'DECLINE_QUOTES_API_REQUEST';
export const DECLINE_QUOTES_API_SUCCESS = 'DECLINE_QUOTES_API_SUCCESS';
export const DECLINE_QUOTES_API_ERROR = 'DECLINE_QUOTES_API_ERROR';
export const ACCEPT_QUOTE_API_REQUEST = 'ACCEPT_QUOTE_API_REQUEST';
export const ACCEPT_QUOTE_API_SUCCESS = 'ACCEPT_QUOTE_API_SUCCESS';
export const ACCEPT_QUOTE_API_ERROR = 'ACCEPT_QUOTE_API_ERROR';
export const RESET_QUOTES_DATA = 'RESET_QUOTES_DATA';
export const QUOTATION_EMAIL_REQUEST = 'QUOTATION_EMAIL_REQUEST';
export const QUOTATION_EMAIL_REQUEST_SUCCESS = 'QUOTATION_EMAIL_REQUEST_SUCCESS';
export const QUOTATION_EMAIL_REQUEST_ERROR = 'QUOTATION_EMAIL_REQUEST_ERROR';
export const MARK_PAID_QUOTE_API_REQUEST = 'MARK_PAID_QUOTE_API_REQUEST';
export const MARK_PAID_QUOTE_API_SUCCESS = 'MARK_PAID_QUOTE_API_SUCCESS';
export const MARK_PAID_QUOTE_API_ERROR = 'MARK_PAID_QUOTE_API_ERROR';
export const SEND_QUOTE_DETAILS_TO_CLIENT_REQUEST = 'SEND_QUOTE_DETAILS_TO_CLIENT_REQUEST';
export const SEND_QUOTE_DETAILS_TO_CLIENT_SUCCESS = 'SEND_QUOTE_DETAILS_TO_CLIENT_SUCCESS';
export const SEND_QUOTE_DETAILS_TO_CLIENT_ERROR = 'SEND_QUOTE_DETAILS_TO_CLIENT_ERROR';
