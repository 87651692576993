import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_CART_API_REQUEST,
  GET_CART_API_SUCCESS,
  GET_CART_API_ERROR,
  GET_CART_PENDING_API_REQUEST,
  GET_CART_PENDING_API_SUCCESS,
  GET_CART_PENDING_API_ERROR,
  ADD_TO_CART_API_REQUEST,
  ADD_TO_CART_API_SUCCESS,
  ADD_TO_CART_API_ERROR,
  ADD_TO_CART_SUCCESS,
  ADD_PREMIUM_TO_CART_API_REQUEST,
  ADD_PREMIUM_TO_CART_API_SUCCESS,
  ADD_PREMIUM_TO_CART_API_ERROR,
  ADD_PREMIUM_TO_CART_SUCCESS,
  ADD_LOAN_TO_CART_API_REQUEST,
  ADD_LOAN_TO_CART_API_ERROR,
  ADD_LOAN_TO_CART_API_SUCCESS,
  ADD_LOAN_TO_CART_SUCCESS,
  ADD_RENEWAL_TO_CART_API_REQUEST,
  ADD_RENEWAL_TO_CART_API_SUCCESS,
  ADD_RENEWAL_TO_CART_API_ERROR,
  DELETE_CART_ITEM_API_REQUEST,
  DELETE_CART_ITEM_API_SUCCESS,
  DELETE_CART_ITEM_API_ERROR,
  DELETE_CART_ITEM_SUCCESS,
  CHECKOUT_API_REQUEST,
  CHECKOUT_API_SUCCESS,
  CHECKOUT_API_ERROR,
  CHECKOUT_SUCCESS,
  GET_ORDER_SUMMARY_API_REQUEST,
  GET_ORDER_SUMMARY_API_SUCCESS,
  GET_ORDER_SUMMARY_API_ERROR,
  GET_ORDER_SUMMARY_SUCCESS,
  CREATE_ORDER_API_REQUEST,
  CREATE_ORDER_API_SUCCESS,
  CREATE_ORDER_API_ERROR,
  CREATE_ORDER_SUCCESS,
  CHANGE_QUANTITY_API_REQUEST,
  CHANGE_QUANTITY_API_SUCCESS,
  CHANGE_QUANTITY_API_ERROR,
  CHANGE_PARTIAL_PREMIUM_API_REQUEST,
  CHANGE_PARTIAL_PREMIUM_API_SUCCESS,
  CHANGE_PARTIAL_PREMIUM_API_ERROR,
  PAY_NOW_API_REQUEST,
  PAY_NOW_API_SUCCESS,
  PAY_NOW_API_ERROR,
  PAY_NOW_SUCCESS,
  PAY_FIRST_PREMIUM_API_REQUEST,
  PAY_FIRST_PREMIUM_API_SUCCESS,
  PAY_FIRST_PREMIUM_API_ERROR,
  PAY_FIRST_PREMIUM_SUCCESS,
  MAKE_FAC_PAYMENT_API_REQUEST,
  MAKE_FAC_PAYMENT_API_SUCCESS,
  MAKE_FAC_PAYMENT_API_ERROR,
  MAKE_FAC_PAYMENT_SUCCESS,
  VIEW_RECEIPT_API_REQUEST,
  VIEW_RECEIPT_API_SUCCESS,
  VIEW_RECEIPT_API_ERROR,
  RESET_CART_SUCCESS,
} from '../../constants/cartActionNames';

import { RESET_RENEWAL_SUCCESS } from '../../constants/renewalActionNames';
import {
  RESET_ADD_PREMIUM_TO_CART_SUCCESS,
  CLEAR_CART_CACHE,
} from '../../constants/miscActionNames';
import { statusOk, loanPaymentType, aplPaymentType } from '../../utils/variables';

// import payloadData from './payloadData';
// import pendingCartData from './pendingCartData';

export const initialState = {
  response: null, // { payload: payloadData },
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  pendingCartResponse: null, // { payload: pendingCartData },
  isPendingCartPending: false,
  isPendingCartError: false,
  isPendingCartSuccess: false,
  pendingCartError: null,
  isChangeQuantityPending: false,
  isChangeQuantityError: false,
  isChangeQuantitySuccess: false,
  changeQuantityResponse: null,
  changeQuantityError: null,
  isChangePartialPremiumPending: false,
  isChangePartialPremiumError: false,
  isChangePartialPremiumSuccess: false,
  changePartialPremiumResponse: null,
  changePartialPremiumError: null,
  isAddToCartPending: false,
  isAddToCartSuccess: false,
  isAddToCartError: false,
  addToCartError: null,
  addToCartResponse: null,
  isAddPremiumToCartPending: false,
  isAddPremiumToCartSuccess: false,
  isAddPremiumToCartError: false,
  addPremiumToCartError: null,
  addPremiumToCartResponse: null,
  isAddLoanToCartPending: false,
  isAddLoanToCartSuccess: false,
  isAddLoanToCartError: false,
  addLoanToCartError: null,
  addLoanToCartResponse: null,
  isAddRenewalToCartPending: false,
  isAddRenewalToCartSuccess: false,
  isAddRenewalToCartError: false,
  addRenewalToCartError: null,
  addRenewalToCartResponse: null,
  isDeleteCartItemPending: false,
  isDeleteCartItemSuccess: false,
  isDeleteCartItemError: false,
  deleteCartItemError: null,
  deleteCartItemResponse: null,
  isCheckoutPending: false,
  isCheckoutSuccess: false,
  isCheckoutError: false,
  checkoutError: null,
  checkoutResponse: null,
  isOrderSummaryPending: false,
  isOrderSummarySuccess: false,
  isOrderSummaryError: false,
  orderSummaryResponse: null,
  orderSummaryError: null,
  isCreateOrderPending: false,
  isCreateOrderSuccess: false,
  isCreateOrderError: false,
  createOrderResponse: null,
  createOrderError: null,
  isPayNowPending: false,
  isPayNowSuccess: false,
  isPayNowError: false,
  payNowResponse: null,
  payNowError: null,
  isFacPending: false,
  isFacSuccess: false,
  isFacError: false,
  facResponse: null,
  facError: null,
  isViewReceiptPending: false,
  isViewReceiptSuccess: false,
  isViewReceiptError: false,
  viewReceiptResponse: null,
  viewReceiptError: null,
  isPayFirstPremiumPending: false,
  isPayFirstPremiumSuccess: false,
  isPayFirstPremiumError: false,
  payFirstPremiumResponse: null,
  payFirstPremiumError: null,
  order: null,
  receiptNo: null,
  referenceNo: null,
  cart: [],
  pendingCart: [],
  saveForLater: [],
  orderSummaryCart: null,
  createOrderCart: null,
  checkoutCart: null,
  localCart: [],
  cartCount: 0,
  [DEFAULT_CACHE_KEY]: null,
};

const resetState = _state => {
  return {
    ..._state,
    isSuccess: false,
    isChangeQuantityError: false,
    isAddToCartSuccess: false,
    isOrderSummarySuccess: false,
    isPayNowSuccess: false,
    isAddPremiumToCartSuccess: false,
  };
};

const mergeCartItems = (incomingContainers, existingContainers, body) => {
  // response.payload = null;
  // let cartCount = 0;
  // // console.log('cart: ', existingCarts);
  // const result = updatedCart.map(subCart =>
  // subCart.cart.map(item => {
  //  cartCount += 1;
  // cartCount += item.quantity;
  // }),
  // );

  incomingContainers.map(container => {
    const updatedCartItems = container?.cart?.map(item => {
      const isLoan =
        item?.paymentType === loanPaymentType || item?.paymentType === aplPaymentType;
      const advancedPayAllowed = item?.advancedPayAllowed;
      let finalQty;
      if (isLoan || advancedPayAllowed) {
        finalQty = item?.quantity;
      } else {
        finalQty =
          item?.quantity > item?.premiumsDue ? item?.premiumsDue : item?.quantity;
      }
      return { ...item, quantity: finalQty };
    });
    const updatedCart = { ...container, cart: updatedCartItems, body };

    const incomingCart = { ...updatedCart };

    const index = existingContainers.findIndex(
      oldCart => oldCart?._id?.toString() === incomingCart?._id?.toString(),
    );
    // Replace item at index using native splice
    if (index !== -1) {
      existingContainers.splice(index, 1, incomingCart);
    } else {
      existingContainers.push(incomingCart);
    }
    return null;
  });
  return existingContainers;
};
function cartReducer(_state = initialState, action) {
  switch (action.type) {
    case GET_CART_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    }
    case GET_CART_API_SUCCESS: {
      const state = resetState(_state);
      const incomingContainers = action?.payload?.payload || [];
      const existingCart = state?.cart || [];
      const existingContainers = [...existingCart];
      const params = action?.params || {};
      const finalContainers = mergeCartItems(incomingContainers, [], params);

      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      const cacheKey = action?.cacheKey;
      //  response.payload = null;

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        cart: finalContainers,
        isAddToCartRequest: false,
        isAddToCartSuccess: false,
        isAddToCartError: false,
        localCart: [],
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: null,
          },
        },
      };
    }
    case GET_CART_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
      };
    }
    case GET_CART_PENDING_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isPendingCartPending: true,
        isPendingCartError: false,
        isPendingCartSuccess: false,
      };
    }
    case GET_CART_PENDING_API_SUCCESS: {
      const state = resetState(_state);
      const incomingPendingCarts = action?.payload?.payload || [];
      const existingPendingCarts = [];
      // const existingCarts = [...state.cart];

      return {
        ...state,
        isPendingCartPending: false,
        isPendingCartError: false,
        isPendingCartSuccess: true,
        // pendingCartResponse: action.payload,
        // pendingCart: existingPendingCarts,
        // isAddToCartRequest: false,
        // isAddToCartSuccess: false,
        // isAddToCartError: false,
      };
    }
    case RESET_RENEWAL_SUCCESS: {
      return {
        isAddRenewalToCartPending: false,
        isAddRenewalToCartSuccess: false,
        isAddRenewalToCartError: false,
      };
    }
    case CLEAR_CART_CACHE: {
      const state = resetState(_state);
      return {
        ...state,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case GET_CART_PENDING_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isPendingCartPending: false,
        isPendingCartError: true,
        isPendingCartSuccess: false,
        // pendingCartError: action.payload,
      };
    }
    case ADD_TO_CART_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isAddToCartPending: true,
        isAddToCartError: false,
        isAddToCartSuccess: false,
      };
    }
    case ADD_TO_CART_API_SUCCESS: {
      const state = resetState(_state);
      const incomingCartItem = action?.payload?.payload || {};
      const newCart = [...state.cart, incomingCartItem];
      const response = action?.payload;
      //  response.payload = null;
      return {
        ...state,
        isAddToCartPending: false,
        isAddToCartError: false,
        isAddToCartSuccess: true,
        addToCartResponse: response,
        cart: newCart,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case ADD_TO_CART_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isAddToCartPending: false,
        isAddToCartError: true,
        isAddToCartSuccess: false,
        // addToCartError: action.payload,
      };
    }
    case ADD_TO_CART_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;
      //  response.payload = null;

      return {
        ...state,
        isAddToCartPending: false,
        isAddToCartError: false,
        isAddToCartSuccess: true,
        addToCartResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        // cart: newCart,
      };
    }
    case ADD_PREMIUM_TO_CART_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isAddPremiumToCartPending: true,
        isAddPremiumToCartError: false,
        isAddPremiumToCartSuccess: false,
      };
    }
    case ADD_PREMIUM_TO_CART_API_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isAddPremiumToCartPending: false,
        isAddPremiumToCartError: false,
        isAddPremiumToCartSuccess: true,
        addPremiumToCartResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        // localCart: newLocalCart,
        // cartCount,
        // cart: newCart,
      };
    }
    case ADD_PREMIUM_TO_CART_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isAddPremiumToCartPending: false,
        isAddPremiumToCartError: true,
        isAddPremiumToCartSuccess: false,
        addPremiumToCartError: action.payload,
      };
    }
    case RESET_ADD_PREMIUM_TO_CART_SUCCESS: {
      const state = resetState(_state);
      return {
        ...state,
        isAddPremiumToCartError: false,
      };
    }
    case ADD_LOAN_TO_CART_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isAddLoanToCartPending: true,
        isAddLoanToCartError: false,
        isAddLoanToCartSuccess: false,
      };
    }
    case ADD_LOAN_TO_CART_API_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;

      return {
        ...state,
        isAddLoanToCartPending: false,
        isAddLoanToCartError: false,
        isAddLoanToCartSuccess: true,
        addLoanToCartResponse: response,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case ADD_LOAN_TO_CART_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isAddLoanToCartPending: false,
        isAddLoanToCartError: true,
        isAddLoanToCartSuccess: false,
        addLoanToCartError: action.payload,
      };
    }
    case ADD_RENEWAL_TO_CART_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isAddRenewalToCartPending: true,
        isAddRenewalToCartError: false,
        isAddRenewalToCartSuccess: false,
      };
    }
    case ADD_RENEWAL_TO_CART_API_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isAddRenewalToCartPending: false,
        isAddRenewalToCartError: false,
        isAddRenewalToCartSuccess: true,
        addRenewalToCartResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        // localCart: newLocalCart,
        // cartCount,
        // cart: newCart,
      };
    }
    case ADD_RENEWAL_TO_CART_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isAddRenewalToCartPending: false,
        isAddRenewalToCartError: true,
        isAddRenewalToCartSuccess: false,
        addRenewalToCartError: action?.payload,
      };
    }
    case PAY_FIRST_PREMIUM_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isPayFirstPremiumPending: true,
        isPayFirstPremiumError: false,
        isPayFirstPremiumSuccess: false,
      };
    }
    case PAY_FIRST_PREMIUM_API_SUCCESS: {
      const state = resetState(_state);

      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isPayFirstPremiumPending: false,
        isPayFirstPremiumSuccess: true,
        isPayFirstPremiumError: false,
        payFirstPremiumResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        // localCart: newLocalCart,
        // cartCount,
        // cart: newCart,
      };
    }
    case PAY_FIRST_PREMIUM_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isPayFirstPremiumPending: false,
        isPayFirstPremiumError: true,
        isPayFirstPremiumSuccess: false,
        // payFirstPremiumError: action.payload,
      };
    }
    case DELETE_CART_ITEM_API_REQUEST: {
      const state = resetState(_state);

      return {
        ...state,
        isDeleteCartItemPending: true,
        isDeleteCartItemError: false,
        isDeleteCartItemSuccess: false,
      };
    }
    case DELETE_CART_ITEM_API_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isDeleteCartItemPending: false,
        isDeleteCartItemError: false,
        isDeleteCartItemSuccess: true,
        deleteCartItemResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        cart: [],
        // cart: existingCart,
      };
    }
    case DELETE_CART_ITEM_SUCCESS: {
      const state = resetState(_state);

      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isDeleteCartItemPending: false,
        isDeleteCartItemError: false,
        isDeleteCartItemSuccess: true,
        deleteCartItemResponse: response,
        [DEFAULT_CACHE_KEY]: null,
        cart: [],
        // cart: existingCart,
      };
    }
    case DELETE_CART_ITEM_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isDeleteCartItemPending: false,
        isDeleteCartItemError: true,
        isDeleteCartItemSuccess: false,
        deleteCartItemError: action.payload,
      };
    }
    case CHECKOUT_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isCheckoutPending: true,
        isCheckoutError: false,
        isCheckoutSuccess: false,
      };
    }
    case CHECKOUT_API_SUCCESS: {
      const state = resetState(_state);
      const checkoutSummary = action?.params;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isCheckoutPending: false,
        isCheckoutError: false,
        isCheckoutSuccess: true,
        checkoutResponse: response,
        checkoutCart: checkoutSummary,
      };
    }
    case CHECKOUT_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isCheckoutPending: false,
        isCheckoutError: true,
        isCheckoutSuccess: false,
        // checkoutError: action.payload,
      };
    }
    case CHECKOUT_SUCCESS: {
      const state = resetState(_state);
      const checkoutSummary = action?.params;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isCheckoutPending: false,
        isCheckoutError: false,
        isCheckoutSuccess: true,
        checkoutResponse: response,
        checkoutCart: checkoutSummary,
      };
    }
    case GET_ORDER_SUMMARY_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isOrderSummaryPending: true,
        isOrderSummaryError: false,
        isOrderSummarySuccess: false,
      };
    }
    case GET_ORDER_SUMMARY_API_SUCCESS: {
      const state = resetState(_state);
      const orderSummary = action?.payload?.payload;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isOrderSummaryPending: false,
        isOrderSummaryError: false,
        isOrderSummarySuccess: true,
        orderSummaryResponse: response,
        orderSummaryCart: orderSummary,
      };
    }
    case GET_ORDER_SUMMARY_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isOrderSummaryPending: false,
        isOrderSummaryError: true,
        isOrderSummarySuccess: false,
        // orderSummaryError: action.payload,
      };
    }
    case GET_ORDER_SUMMARY_SUCCESS: {
      const state = resetState(_state);
      const orderSummary = action?.params;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isOrderSummaryPending: false,
        isOrderSummaryError: false,
        isOrderSummarySuccess: true,
        orderSummaryResponse: response,
        orderSummaryCart: orderSummary,
      };
    }
    case CREATE_ORDER_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isCreateOrderPending: true,
        isCreateOrderError: false,
        isCreateOrderSuccess: false,
      };
    }
    case CREATE_ORDER_API_SUCCESS: {
      const state = resetState(_state);
      const order = action?.payload?.payload || {};
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isCreateOrderPending: false,
        isCreateOrderError: false,
        isCreateOrderSuccess: true,
        createOrderResponse: response,
        createOrderCart: order,
      };
    }
    case CREATE_ORDER_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isCreateOrderPending: false,
        isCreateOrderError: true,
        isCreateOrderSuccess: false,
        // createOrderError: action.payload,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      const state = resetState(_state);
      const order = action?.params;
      const response = action?.payload;
      //  response.payload = null;
      return {
        ...state,
        isCreateOrderPending: false,
        isCreateOrderError: false,
        isCreateOrderSuccess: true,
        createOrderResponse: response,
        createOrderCart: order,
      };
    }
    case CHANGE_QUANTITY_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isChangeQuantityPending: true,
        isChangeQuantityError: false,
        isChangeQuantitySuccess: false,
      };
    }
    case CHANGE_QUANTITY_API_SUCCESS: {
      const state = resetState(_state);
      // console.log(action?.payload?.payload);
      const newContainer = action?.payload?.payload?.cart;
      const incomingContainers = newContainer ? [newContainer] : [];
      const existingContainers = [...state.cart];
      const params = action?.params || {};
      const finalContainers = mergeCartItems(
        incomingContainers,
        existingContainers,
        params,
      );

      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isChangeQuantityPending: false,
        isChangeQuantityError: false,
        isChangeQuantitySuccess: true,
        changeQuantityResponse: response,
        cart: finalContainers,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case CHANGE_QUANTITY_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isChangeQuantityPending: false,
        isChangeQuantityError: true,
        isChangeQuantitySuccess: false,
        // changeQuantityError: action.payload,
      };
    }
    case CHANGE_PARTIAL_PREMIUM_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isChangePartialPremiumPending: true,
        isChangePartialPremiumError: false,
        isChangePartialPremiumSuccess: false,
      };
    }
    case CHANGE_PARTIAL_PREMIUM_API_SUCCESS: {
      const state = resetState(_state);
      const newContainer = action?.payload?.payload?.cart;
      const incomingContainers = newContainer ? [newContainer] : [];
      const existingContainers = [...state.cart];
      const response = action?.payload;
      const params = action?.params;
      const finalCart = mergeCartItems(incomingContainers, existingContainers, params);
      return {
        ...state,
        isChangePartialPremiumPending: false,
        isChangePartialPremiumError: false,
        isChangePartialPremiumSuccess: true,
        changePartialPremiumResponse: response,
        cart: finalCart,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case CHANGE_PARTIAL_PREMIUM_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isChangePartialPremiumPending: false,
        isChangePartialPremiumError: true,
        isChangePartialPremiumSuccess: false,
        changePartialPremiumError: action.payload,
      };
    }
    case RESET_CART_SUCCESS: {
      const state = resetState(_state);
      return {
        ...state,
        isChangePartialPremiumPending: false,
        isChangePartialPremiumError: false,
        isChangePartialPremiumSuccess: false,
        changePartialPremiumError: null,
        isDeleteCartItemPending: false,
        isDeleteCartItemError: false,
        isDeleteCartItemSuccess: false,
        deleteCartItemError: null,
      };
    }
    case PAY_NOW_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isPayNowPending: true,
        isPayNowError: false,
        isPayNowSuccess: false,
      };
    }
    case PAY_NOW_API_SUCCESS: {
      const state = resetState(_state);
      const orderObj = action?.payload?.payload || action?.params;
      const existingCart = [...state.cart];
      const containerIndex = existingCart.findIndex(
        container => container?._id === orderObj?._id,
      );

      if (containerIndex !== -1) {
        existingCart.splice(containerIndex, 1);
      }

      const response = action?.payload;
      // response.payload = null;

      return {
        ...state,
        isPayNowPending: false,
        isPayNowError: false,
        isPayNowSuccess: true,
        // payNowResponse: action.payload,
        cart: existingCart,
        order: orderObj,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case PAY_NOW_API_ERROR: {
      const state = resetState(_state);

      return {
        ...state,
        isPayNowPending: false,
        isPayNowError: true,
        isPayNowSuccess: false,
        payNowError: action.payload,
      };
    }
    case MAKE_FAC_PAYMENT_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isFacPending: true,
        isFacError: false,
        isFacSuccess: false,
      };
    }
    case MAKE_FAC_PAYMENT_API_SUCCESS: {
      const state = resetState(_state);
      const receiptNo = action?.payload?.payload?.receiptNo;
      const referenceNo = action?.payload?.payload?.referenceNo;
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isFacPending: false,
        isFacError: false,
        isFacSuccess: true,
        facResponse: response,
        receiptNo,
        referenceNo,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case MAKE_FAC_PAYMENT_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isFacPending: false,
        isFacError: true,
        isFacSuccess: false,
        facError: action.payload,
      };
    }
    case VIEW_RECEIPT_API_REQUEST: {
      const state = resetState(_state);
      return {
        ...state,
        isViewReceiptPending: true,
        isViewReceiptError: false,
        isViewReceiptSuccess: false,
      };
    }
    case VIEW_RECEIPT_API_SUCCESS: {
      const state = resetState(_state);
      const response = action?.payload;
      // response.payload = null;
      return {
        ...state,
        isViewReceiptPending: false,
        isViewReceiptError: false,
        isViewReceiptSuccess: true,
        viewReceiptResponse: response,
      };
    }
    case VIEW_RECEIPT_API_ERROR: {
      const state = resetState(_state);
      return {
        ...state,
        isViewReceiptPending: false,
        isViewReceiptError: true,
        isViewReceiptSuccess: false,
        viewReceiptError: action?.payload,
        // facError: action.payload,
      };
    }
    default:
      return _state;
  }
}
export default cartReducer;
