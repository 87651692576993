import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Container } from '@mui/material';
import { uuidv4 } from '@microsoft/omnichannel-chat-sdk';
import RecommendationList from '../../list/recommendationList';
import RecommendationsEngine from '../recommendationsEngine';
import CallToActionCard from '../../cards/callToActionCard';
import { useFeatureToggle, useVariables } from '../../../hooks';
import Referral from '../referral';

// import Feedback from '../feedback';
// import QuickLinks from '../quickLinks';
// import ContactUs from '../contactUs';

const MarketingAds = ({ surveySlug }) => {
  const doFeatureToggle = useFeatureToggle();
  const autoPayFeatureActive = doFeatureToggle(`autoPay`);
  const payAnythingFeatureActive = doFeatureToggle(`payAnything`);
  const promotions = useVariables()?.promotions || [];
  // const isReferCustomerActive = doFeatureToggle('referACustomer');
  return (
    <Grid container direction="row" spacing={2}>
      {/* isAuthenticated && (
        <Grid item xs={12}>
          <RecommendationsEngine />
        </Grid>
      ) */}
      {/* {isAuthenticated && (
          <Grid item xs={12}>
            <RecommendationList />
          </Grid>
        )} */}
      {/* <Grid item xs={12}>
        <ContactUs />
      </Grid> */}
      {/* <Grid item xs={12}>
        <QuickLinks />
      </Grid> */}
      {promotions?.map(promotion => {
        if (promotion.hidden) return null;
        if (promotion?.title?.includes('Recurring') && !autoPayFeatureActive) return null;
        if (promotion?.title?.includes('Custom Pay') && !payAnythingFeatureActive)
          return null;
        if (promotion.type === 'customer-referral') {
          return (
            <Grid item xs={12}>
              <Referral {...promotion} />
            </Grid>
          );
        }
        return (
          <Grid item xs={12} key={uuidv4()}>
            <CallToActionCard {...promotion} />
          </Grid>
        );
      })}

      {/* <Grid item xs={12}>
        <Feedback eventSlug={surveySlug} authRequired />
      </Grid> */}
    </Grid>
  );
};

export default MarketingAds;
