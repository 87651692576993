import {
  GET_INSURANCES_API_REQUEST,
  GET_INSURANCES_API_SUCCESS,
  GET_INSURANCES_API_ERROR,
  GET_NEXT_INSURANCES_SCROLL_SUCCESS,
  GET_INSURANCE_INVESTMENTS_REQUEST,
  GET_INSURANCE_INVESTMENTS_SUCCESS,
  GET_INSURANCE_INVESTMENTS_ERROR,
  UPDATE_INSURANCE_BODY_SUCCESS,
} from '../../../constants/portfolio/insuranceActionNames';
import { promiseTimeout } from '../../../utils/functions';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getInsuranceUrl } from '../../../apis/portfolio/getInsurancesApi';
import { getInvestmentsUrl } from '../../../apis/portfolio/getInvestmentsApi';

// import { api } from '../../../utils/variables';
import { getInsurancesApi } from '../../../apis/portfolio';

function getInsurancesError(err, body) {
  return {
    type: GET_INSURANCES_API_ERROR,
    payload: err,
    body,
  };
}

function getInsurancesRequest(body) {
  return {
    type: GET_INSURANCES_API_REQUEST,
    payload: true,
    body,
  };
}
function getInsurancesSuccess(data, body, cacheKey, lobTypes, templateName = '') {
  return {
    type: GET_INSURANCES_API_SUCCESS,
    payload: data,
    body,
    lobTypes,
    cacheKey,
    templateName,
  };
}

function updateInsuranceBodySuccess(body, cacheKey, lobTypes) {
  return {
    type: UPDATE_INSURANCE_BODY_SUCCESS,
    payload: null,
    body,
    lobTypes,
    cacheKey,
  };
}

export function getInsurancesAction(params, body) {
  // const apiEndpoint = {}; // ...api };
  // const response = getPayloadResponse();

  return (dispatch, getState) => {
    const state = getState();
    const cacheParams = {
      ...params,
      infiniteScroll: body?.infiniteScroll,
      isMobile: body?.isMobile,
      alpha2: body?.alpha2,
    };

    const cacheKey = generateCacheKey({
      path: getInsuranceUrl,
      cacheParams,
      state,
    });
    const templateName = state?.locations?.yourLocation?.variables?.templateName;
    // // console.log('state: ', state);
    const firstTime = state?.insurance?.firstTime;
    const lobTypes = state?.locations?.lobCategories;
    if (body?.scrollKey === body?.staticLob) {
      dispatch(updateInsuranceBodySuccess(body, cacheKey, lobTypes));
    }

    const { isCacheValid } = checkCacheValid(getState, 'insurance', cacheKey);
    if (isCacheValid) {
      if (body.forceReload) {
        dispatch(getInsurancesSuccess('forceReload', body, cacheKey, lobTypes));
      }
      //
      return Promise.resolve({ body });
    }

    // // console.log('firstTime1: ', firstTime);
    dispatch(getInsurancesRequest(body));
    return Promise.resolve(null)
      .then(data => {
        if (firstTime) {
          // // console.log('firstTime2: ', firstTime);
          // return new Promise((resolve, reject) => {
          // setTimeout(() => resolve(data), 2000);
          // });
          return promiseTimeout(2000, data);
        }
        return Promise.resolve(data);
      })
      .then(() => getInsurancesApi.getInsurances(state, params))

      .then(data => {
        // // console.log('data: ', data);
        // Update the app state with the results of the API call.

        dispatch(getInsurancesSuccess(data, body, cacheKey, lobTypes, templateName));
        return Promise.resolve({ body, data });
      })
      .catch(error => {
        dispatch(getInsurancesError(error, body));
        throw error;
      });
  };
}

function getInsuranceInvestmentsError(err, body) {
  return {
    type: GET_INSURANCE_INVESTMENTS_ERROR,
    payload: err,
    body,
  };
}

function getInsuranceInvestmentsRequest(body) {
  return {
    type: GET_INSURANCE_INVESTMENTS_REQUEST,
    payload: true,
    body,
  };
}
function getInsuranceInvestmentsSuccess(data, body, cacheKey, lobTypes) {
  return {
    type: GET_INSURANCE_INVESTMENTS_SUCCESS,
    payload: data,
    body,
    lobTypes,
    cacheKey,
  };
}

export function getInsuranceInvestmentsAction(params, body) {
  // console.log('params: ', params);
  return (dispatch, getState) => {
    const state = getState();
    const cacheKey = generateCacheKey({
      path: getInvestmentsUrl,
      cacheParams: params,
      state,
    });

    const isError = state?.investments?.isError;
    const isSuccess = state?.investments?.isSuccess;
    const accounts = state?.investments?.accounts;
    const error = state?.investments?.error;
    const response = state?.investments?.response;
    const newAccounts = accounts?.map(x => ({ ...x, body }));
    // console.log('accounts: ', accounts);
    const lobTypes = state?.locations?.lobCategories;
    // console.log('body: ', body);
    dispatch(getInsuranceInvestmentsRequest(body));
    const payload = {
      isError,
      isSuccess,
      response,
      data: newAccounts,
      error,
    };
    if (body?.scrollKey === body?.staticLob) {
      dispatch(updateInsuranceBodySuccess(body, cacheKey, lobTypes));
    }
    return Promise.resolve(payload)
      .then(data => {
        dispatch(getInsuranceInvestmentsSuccess(data, body, cacheKey, lobTypes));
        return { data, body };
      })
      .catch(error => {
        dispatch(getInsuranceInvestmentsError(error, body));
        throw error;
      });
  };
}

function getNextInsurancesScrollSuccess(prod) {
  return {
    type: GET_NEXT_INSURANCES_SCROLL_SUCCESS,
    payload: prod,
  };
}

export function getNextInsurancesScrollAction(data) {
  return getNextInsurancesScrollSuccess(data);
}

export default {
  getInsurancesAction,
  getNextInsurancesScrollAction,
  getInsuranceInvestmentsAction,
};
