import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_QUOTES_API_REQUEST,
  GET_QUOTES_API_SUCCESS,
  GET_QUOTES_API_ERROR,
  GET_NEXT_QUOTES_SUCCESS,
  ACCEPT_QUOTE_API_ERROR,
  ACCEPT_QUOTE_API_REQUEST,
  ACCEPT_QUOTE_API_SUCCESS,
  DECLINE_QUOTES_API_REQUEST,
  DECLINE_QUOTES_API_SUCCESS,
  DECLINE_QUOTES_API_ERROR,
  UPDATE_QUOTES_TOTAL,
  RESET_QUOTES_DATA,
  MARK_PAID_QUOTE_API_REQUEST,
  MARK_PAID_QUOTE_API_ERROR,
  MARK_PAID_QUOTE_API_SUCCESS,
} from '../../constants/quoteActionNames';
import { SELECT_CLIENTS_SUCCESS } from '../../constants/authActionNames';
import {
  CLEAR_QUOTES_CACHE,
  RESET_INFINITE_SCROLL_SUCCESS,
} from '../../constants/miscActionNames';

import payloadData from './payloadData';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
  stringQuoteToDate,
  dateTimeToString,
  stringToDateTime,
  generateSearchWords,
  getKeys,
  getAllKeys,
  uniq,
  addArrayOnce,
} from '../../utils/functions';
import { statusOk } from '../../utils/variables';

// .slice(0, loadQuotesCount)
let searchKeys = [];

const createPayload = (payloadArray, lobTypes, body, cacheKey) => {
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const newQuotes = payloadArray.map((object, index) => {
    const lobObj = lobTypes?.find(lobObject => {
      const lobIndex = object?.lob?.toLowerCase() === lobObject.lob;
      // // console.log('index: ', index2, lobObject.lob);

      return lobIndex;
    });
    const position = index;
    const convertedDate = stringQuoteToDate(object?.quoteData?.createTime);
    // // console.log('convertedDate: ', convertedDate);

    const quote = {
      ...object,
      index: position,
      cacheId: `${object?._id}_${cacheKey}`,
      cacheKey,
      id: object?._id, // ? `${object._id}_${position}` : uuidv4(), // `${object?._id}_${uiid}`,
      slug: object?.quoteNumber,
      lob: lobObj?.lob,
      icon: lobObj?.icon,
      materialIcon: lobObj?.materialIcon,
      color: lobObj?.color,
      clientFullName: `${object?.firstName} ${object?.lastName}`,
      clientFullNameNoSpace: `${object?.firstName} ${object?.lastName}`?.replace(
        / /g,
        '',
      ),
      createTime: object?.quoteData?.createTime, // dateToString(isoStringToDate(object?.quoteData?.createTime)),
      expiredTime: object?.quoteData?.expiredTime, // dateToString(isoStringToDate(object?.quoteData?.expiredTime)),
      quoteNumber: object?.quoteNumber,
      totalAmount: object?.quoteData?.totalAmount,
      vehicleName: object?.quoteData?.vehicleName,
      vehicleNameNoSpace: object?.vehicleName?.replace(/ /g, ''),
      coverType: object?.quoteData?.coverType,
      coverTypeCode: object?.quoteData?.coverTypeCode,
      vehicleYear: object?.quoteData?.vehicleYear,
      pickup: object?.quoteData?.isPickup,
      recoveryService: object?.quoteData?.trackingDevice,
      antiTheft: object?.quoteData?.antiTheftDevice,
      sumInsured: object?.quoteData?.sumInsured,
      windscreen: object?.quoteData?.windscreenCoverage,
      noWindscreen: !parseInt(object?.quoteData?.windscreenCoverage, 10),
      premium: object?.quoteData?.premiumAmount,
      tax: object?.quoteData?.taxAmount,
      filterDate: dateToString(convertedDate),
      filterId: object?.quoteNumber,
      filterLob: object?.lob,
      filterStatus: object?.status,
      currency: object?.quoteData?.currency,
      dateOfBirth: dateToString(isoStringToDate(object?.dob)),
      homeAddress: object?.quoteData?.homeAddress,
      buildingValue: object?.quoteData?.buildingValue,
      swimmingPoolValue: object?.quoteData?.swimmingPoolValue,
      contentsValue: object?.quoteData?.contentsValue,
      electronicEquipmentValue: object?.quoteData?.electronicEquipmentValue,
      valuables: object?.quoteData?.valuables,
      computerLossData: object?.quoteData?.computerLossData,
      body,
      // ...searchWords,
    };

    // const searchWords = generateSearchWords(object);
    // const newKeys = getKeys(searchWords);
    // // console.log('newKeys: ', newKeys);
    // searchKeys = [...searchKeys, ...newKeys];
    // searchKeys = uniq(searchKeys);
    // // console.log('searchWords: ', searchWords);

    // const reduceObj = reduceKeys(obj);
    const newKeys = getKeys(quote);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    // // console.log('searchKeys: ', searchKeys);

    return quote;
  });
  /*
  newQuotes.sort((a, b) => {
    return b.slug > a.slug ? 1 : -1;
  });
  */

  return newQuotes;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
  total: {},
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
};
// createPayload(payloadData, 0)

export const getPayloadResponse = () => {
  // // console.log('params: ', params);
  return { payload: [{ quotes: payloadData, count: 100000 }] };
};

function quotesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_QUOTES_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };

    case GET_QUOTES_API_SUCCESS: {
      const response = action?.payload;
      const rows = response?.payload;
      const lobTypes = action?.lobTypes;
      const cacheKey = action?.cacheKey;
      const body = action?.params; // // console.log('initialQuotes: ', initialQuotes);
      const oldRows = (state?.allRows && [...state?.allRows]) || [];
      // const startIndex = action?.params?.offset;
      const newRows = createPayload(rows, lobTypes, body, cacheKey) || [];
      let allRows = addArrayOnce(oldRows, newRows, 'cacheId');

      // const allRows = [...newRows, ...state?.allRows];

      const isError = response?.responseType !== statusOk;

      const count = allRows?.length || 0;
      // to sort quotes based on endDate for fatum
      if (count > 0 && allRows[0]?.createdAt) {
        const modifiedRows = allRows
          .map(obj => {
            return { ...obj, createdAt: new Date(obj?.createdAt) };
          })
          .sort((objA, objB) => Number(objB.createdAt) - Number(objA.createdAt));
        allRows = modifiedRows;
      }

      const total = {};
      lobTypes?.forEach(lobType => {
        const subRows = allRows?.filter(
          row => row?.lob === lobType?.lob && row?.body?.alpha2 === body?.alpha2,
        );
        const subLength = subRows?.length || 0;
        total[lobType.lob] = subLength;
      });

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        // rows,
        count,
        searchKeys,
        allRows,
        total,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }

    case GET_QUOTES_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case GET_NEXT_QUOTES_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,

        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case DECLINE_QUOTES_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };

    case DECLINE_QUOTES_API_SUCCESS: {
      return {
        ...state,
        allRows: state.allRows.filter(row => row._id !== action.payload.quoteId),
        count: state.count - 1,
        isPending: false,
        isSuccess: true,
      };
    }

    case DECLINE_QUOTES_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };
    case ACCEPT_QUOTE_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };

    case ACCEPT_QUOTE_API_SUCCESS: {
      return {
        ...state,
        allRows: state.allRows,
        count: state.count,
        isPending: false,
        isSuccess: true,
      };
    }

    case ACCEPT_QUOTE_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case MARK_PAID_QUOTE_API_REQUEST: {
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    }

    case MARK_PAID_QUOTE_API_ERROR: {
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };
    }

    case MARK_PAID_QUOTE_API_SUCCESS: {
      return {
        ...state,
        allRows: state.allRows.filter(row => row._id !== action.payload.quoteId),
        count: state.count - 1,
        isPending: false,
        isSuccess: true,
      };
    }

    case UPDATE_QUOTES_TOTAL: {
      const total = {};
      const lobTypes = action?.lobTypes;
      const cacheKey = action?.cacheKey;
      const body = action?.params;

      lobTypes?.forEach(lobType => {
        const subRows = state?.allRows?.filter(
          row => row?.lob === lobType?.lob && row?.body?.alpha2 === body?.alpha2,
        );
        const subLength = subRows?.length || 0;
        total[lobType.lob] = subLength;
      });
      // console.log('total: ', total);
      return {
        ...state,
        total,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case SELECT_CLIENTS_SUCCESS: {
      return {
        ...state,
        allRows: initialState.allRows,
        total: initialState.total,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    case CLEAR_QUOTES_CACHE: {
      return {
        ...state,
        allRows: initialState.allRows,
        total: initialState.total,
        [DEFAULT_CACHE_KEY]: null,
      };
    }

    case RESET_QUOTES_DATA: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

export default quotesReducer;
