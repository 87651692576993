import {
  GET_PENDING_REQUEST_HISTORY_API_REQUEST,
  GET_PENDING_REQUEST_HISTORY_API_SUCCESS,
  GET_PENDING_REQUEST_HISTORY_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function getPendingRequestHistoryRequest() {
  return {
    type: GET_PENDING_REQUEST_HISTORY_API_REQUEST,
    payload: true,
  };
}

function getPendingRequestHistorySuccess(requests, params) {
  return {
    type: GET_PENDING_REQUEST_HISTORY_API_SUCCESS,
    payload: requests,
    params,
  };
}

function getPendingRequestHistoryError(err) {
  return {
    type: GET_PENDING_REQUEST_HISTORY_API_ERROR,
    payload: err,
  };
}

export function getPendingRequestHistoryAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(getPendingRequestHistoryRequest());
    return getRequestsApi
      .getPendingRequestHistory(state, params)
      .then(data => {
        dispatch(getPendingRequestHistorySuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getPendingRequestHistoryError(error));
        throw error;
        // return response;
      });
  };
}

export default { getPendingRequestHistoryAction };
