import {
  UPDATE_CLIENTS_CURRENT_CACHE_KEY,
  GET_CLIENTS_API_REQUEST,
  GET_CLIENTS_API_SUCCESS,
  GET_CLIENTS_API_ERROR,
  GET_NEXT_CLIENTS_SUCCESS,
} from '../../../constants/clientActionNames';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getClientsUrl } from '../../../apis/clients/getClientsApi';
import { getClientsApi } from '../../../apis/clients';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_CLIENTS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getClientsRequest(body) {
  return {
    type: GET_CLIENTS_API_REQUEST,
    payload: true,
    body,
  };
}

function getClientsSuccess(clients, params, body, cacheKey, lobTypes) {
  return {
    type: GET_CLIENTS_API_SUCCESS,
    payload: clients,
    params,
    cacheKey,
    lobTypes,
    body,
  };
}

function getClientsError(err, body) {
  return {
    type: GET_CLIENTS_API_ERROR,
    payload: err,
    body,
  };
}

export function getClientsAction(params, body) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const cacheKey = generateCacheKey({
      path: getClientsUrl,
      cacheParams: params,
      state,
    });
    const lobTypes = state?.locations?.lobCategories;
    const { isCacheValid, cache } = checkCacheValid(getState, 'clients', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(getClientsSuccess(cache, params, body, cacheKey, lobTypes));
      return Promise.resolve(cache);
    }
    dispatch(getClientsRequest(body));
    return getClientsApi
      .getClients(state, params)
      .then(data => {
        dispatch(getClientsSuccess(data, params, body, cacheKey, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getClientsError(error, body));
        throw error;
        // return response;
      });
  };
}

function getNextClientsSuccess(prod) {
  return {
    type: GET_NEXT_CLIENTS_SUCCESS,
    payload: prod,
  };
}

export function getNextClientsAction(data) {
  return getNextClientsSuccess(data);
}

export default { getClientsAction, getNextClientsAction };
