import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  useMediaQuery,
  Typography,
  Popover,
  Popper,
  Paper,
  Box,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpIcon from '@mui/icons-material/Help';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { useSmall } from '../../../../../hooks';
import { updateTempState } from '../../../../../actions/temp';
import { ContentDisplay } from '../../../../common';
import { dutchTemplate } from '../../../../../utils/variables/index';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: theme.palette.background.banner,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.shape.borderWidth,
    borderStyle: 'solid',
  },
  popoverTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor:
      theme?.template === dutchTemplate
        ? theme.palette.primary?.main
        : theme.palette.background.banner,
    color:
      theme?.template === dutchTemplate
        ? theme.palette.text.bright
        : theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme?.template === dutchTemplate ? 'none' : theme.palette.text.primary,
    borderWidth: theme?.template === dutchTemplate ? '0px' : theme.shape.borderWidth,
    borderStyle: 'solid',
    minWidth: theme?.template === dutchTemplate ? '60vw' : '10rem',
    maxWidth: theme?.template === dutchTemplate ? '90vw' : '40rem',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('md')]: {
      minWidth: theme?.template === dutchTemplate ? '30vw' : '10rem',
      maxWidth: theme?.template === dutchTemplate ? '60vw' : '40rem',
    },
    [theme.breakpoints.down('md')]: {
      top: theme?.template === dutchTemplate ? 'none' : '10px !important',
      left: theme?.template === dutchTemplate ? 'none' : '0px !important',
    },
    [theme.breakpoints.down('sm')]: theme?.template === dutchTemplate && {
      left: '30px !important',
      minWidth: '15rem',
      maxWidth: '15rem',
    },

  },
  customArrow: {
    color: theme.palette.secondary.main,
  },
  dutchIconButton: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
      paddingLeft: '5px !important',
      fontSize: '0.8rem !important'
    }
  },
  dutchTextField: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem !important'
    }
  },
  icon: {
    width: theme?.template === dutchTemplate ? '1rem' : '',
    height: theme?.template === dutchTemplate ? '1rem' : '',
    // fontSize: '0.8rem !important'
    [theme.breakpoints.down('sm')]: {
      width: theme?.template === dutchTemplate ? '0.8rem' : '',
      height: theme?.template === dutchTemplate ? '0.8rem' : '',
    }
  }
}));

const CustomTooltip = ({
  title,
  url = '',
  style = {},
  icon,
  children,
  fontSize = 'small',
  showInModal,
  content,
  template,
  customIcon,
}) => {
  const classes = useStyles();
  const isSmall = useSmall();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    if (showInModal) {
      const tooltipObj = {
        content: title,
      };
      dispatch(updateTempState.updateTempStateAction({ alertPopUp: tooltipObj }));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getIcon = iconName => {
    const icons = {
      helpIcon: HelpIcon,
      infoIcon: InfoIcon,
      helpOutlineIcon: HelpOutlineIcon,
    };
    return icons[iconName];
  };

  let SelectedIcon = HelpOutlineIcon;
  if (icon || template) {
    if (customIcon) SelectedIcon = getIcon(customIcon);
    else SelectedIcon = HelpIcon;
  }

  if (isSmall) {
    return (
      <span style={style}>
        {children && (
          <Box sx={{ display: 'inline-block', mr: 1 }} onClick={handleClick}>
            {children}
          </Box>
        )}

        <IconButton
          aria-label="help"
          size="small"
          sx={{ display: 'inline-block' }}
          onClick={handleClick}
          className={template === dutchTemplate && classes.dutchIconButton}
        >
          {SelectedIcon && (
            <SelectedIcon
              fontSize={fontSize}
              color={icon || template === dutchTemplate ? 'primary' : ''}
              className={classes.icon}

            />
          )}
        </IconButton>
        {!showInModal && (
          <Popover
            id={id}
            classes={{ paper: classes.popoverTooltip }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{ zIndex: 1000 }}
            disablePortal
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {title && <Typography align="left" className={classes.dutchTextField}>{title}</Typography>}
            {url && (
              <Link color="primary" to={url}>
                View more
              </Link>
            )}
            {content && content?.length > 11 && <ContentDisplay content={content} />}
          </Popover>
        )}
      </span>
    );
  }
  return (
    <span onMouseEnter={handleClick} onMouseLeave={handleClose} style={style}>
      {children && <Box sx={{ display: 'inline-block', mr: 1 }}>{children}</Box>}
      <Box sx={{ display: 'inline-block' }}>
        <IconButton aria-label="help" size="small">
          {SelectedIcon && (
            <SelectedIcon
              fontSize={fontSize}
              color={icon || template === dutchTemplate ? 'primary' : ''}
              sx={
                template === dutchTemplate
                  ? { width: '0.84375rem', height: '0.84375rem' }
                  : {}
              }
            />
          )}
        </IconButton>
      </Box>
      {!showInModal && (
        <Popper
          style={{ zIndex: 1000 }}
          disablePortal
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={template ? 'top' : 'bottom-end'}
        >
          <Paper className={classes.popoverTooltip}>
            {title && <Typography align="left" className={template === dutchTemplate && classes.dutchTextField}>
              {template === dutchTemplate ? <div dangerouslySetInnerHTML={{ __html: `${title}` }} /> : title} </Typography>}
            {url && (
              <Link color="primary" to={url}>
                View more
              </Link>
            )}
            {content && content?.length > 11 && <ContentDisplay content={content} />}
          </Paper>
        </Popper>
      )}
    </span>
  );
};

export default CustomTooltip;
