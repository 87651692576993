import {
  checkComponent,
  transformExpansionItems,
  validateAccess,
  validateMultiFieldConfigurer,
} from './misc';
import { checkValueLimitFunction } from './checkValueLimit';
import { validateExp, parseBool } from '../../../utils/functions';
import { agentType } from '../../../utils/variables';

export const validateComponent = (
  storeState,
  component,
  appSettings,
  show,
  disable,
  makeNamesDistinct,
  applySelectedClientChanges,
  isCustomForm,
  parentName,
) => {
  const wizardData = storeState?.wizards?.allInitialWizardData;
  const {
    makeDistinctSuffix,
    isAuthenticated,
    customerVerified,
    isAgent,
    usertype,
    selectedClient,
  } = appSettings || {};
  let newName = component?.name;
  let parent;
  let child;
  if (isCustomForm) {
    parent = parentName;
    child = component?.name;
  }
  if (makeNamesDistinct) {
    newName = `${newName}${makeDistinctSuffix}`;
  }
  const emailVerificationRequired = component?.emailVerificationRequired;
  const phoneVerificationRequired = component?.phoneVerificationRequired;
  let validRequire = true;
  let validExp = true;

  if (emailVerificationRequired) {
    const finalEmailVerifiedRequired = validateAccess(
      emailVerificationRequired,
      isAuthenticated,
      customerVerified,
    );
    if (finalEmailVerifiedRequired) {
      newName = `${newName}`;
    }
  }

  if (phoneVerificationRequired) {
    const finalPhoneVerifiedRequired = validateAccess(
      phoneVerificationRequired,
      isAuthenticated,
      customerVerified,
    );
    if (finalPhoneVerifiedRequired) {
      newName = `${newName}`;
    }
  }

  const {
    required: activeConditionalRequired,
    regExpEnabled: activeConditionalRegExpEnabled,
    reqMessage,
  } = checkComponent(component, storeState, isAuthenticated, customerVerified);

  // console.log("activeConditionalRequired:", activeConditionalRequired)
  // console.log("component:", component)

  if (applySelectedClientChanges) {
    if (isAgent && usertype === agentType && !selectedClient) {
      disable = false;
    }
    // console.log('disable 1: ', disable);
  }

  // console.log('disable 2: ', disable);
  let validationValue = wizardData?.[newName];
  if (component?.type === 'number') {
    validationValue = String(validationValue)?.replace(/[^0-9.]/g, '');
  }

  if (
    (component?.required || activeConditionalRequired) &&
    show &&
    !disable &&
    ((validationValue &&
      typeof validationValue === 'string' &&
      validationValue.length === 0) ||
      (component?.dontAllowZero &&
        component?.type === 'number' &&
        parseFloat(validationValue) === 0) ||
      (validationValue &&
        Array.isArray(validationValue) &&
        validationValue.length === 0) ||
      (validationValue &&
        typeof validationValue === 'object' &&
        Object.keys(validationValue).length === 0) ||
      !validationValue)
  ) {
    // console.log('disable 3: ', disable);
    // console.log('component required: ', component);
    // console.log('wizardData[newName]: ', wizardData[newName]);
    // console.log('newName: ', newName);
    validRequire = false;
  } else {
    validRequire = true;
  }
  if (
    isCustomForm &&
    wizardData[parent] &&
    component?.required &&
    show &&
    !disable &&
    typeof wizardData[parent][child] === 'string' &&
    wizardData[parent][child].length === 0
  ) {
    validRequire = false;
  }

  if (show && !disable && component?.required && reqMessage) {
    validRequire = false;
  }
  if (
    component.slug === 'RegionCountryChoice' &&
    wizardData[`${newName}_country`] &&
    wizardData[`${newName}_region`] &&
    !component.showCountryOnly &&
    (component?.required || activeConditionalRequired) &&
    show &&
    !disable &&
    wizardData[`${newName}_country`].length > 0 &&
    wizardData[`${newName}_region`].length > 0
  ) {
    validRequire = true;
  } else if (
    component.slug === 'RegionCountryChoice' &&
    wizardData[`${newName}_country`] &&
    component.showCountryOnly &&
    (component?.required || activeConditionalRequired) &&
    show &&
    !disable &&
    wizardData[`${newName}_country`].length > 0
  ) {
    validRequire = true;
  }

  if (
    component.slug === 'IDVerification' &&
    (component?.required || activeConditionalRequired) &&
    show &&
    !disable &&
    (!wizardData[`${newName}`] || wizardData[`${newName}`]?.payload?.result !== 'clear')
  ) {
    if (
      !(
        wizardData[`${newName}`]?.payload?.status === 'in_progress' &&
        component?.allowToContinueIfInProgress
      )
    ) {
      validRequire = false;
    }
    if (
      wizardData[`${newName}`]?.payload?.status === 'cancelled' &&
      component?.doOnlyPollingUntilValidResponse
    ) {
      validRequire = true;
    }
  }
  //
  //
  if (
    component?.required &&
    component?.yesRequired &&
    component.slug === 'RadioChoice' &&
    show &&
    !disable &&
    wizardData[`${newName}`] &&
    !parseBool(wizardData[`${newName}`])
  ) {
    validRequire = false;
  }

  if (
    component?.required &&
    component.slug === 'CardItemsList' &&
    show &&
    !disable &&
    component.name &&
    (!wizardData[`${component.name}`] || wizardData[`${component.name}`].length === 0)
  ) {
    // console.log('component required not valid: ', component);
    // console.log('required wizardData[newName]: ', wizardData[newName]);
    // console.log('required wizardData: ', wizardData);
    // console.log('required newName: ', newName);
    validRequire = false;
    // console.log('validRequire: ', validRequire);
  }

  if (component.required && show && !disable && component.checkValueLimit) {
    const limitExceeded = checkValueLimitFunction(
      storeState,
      component.checkValueLimit,
      wizardData[`${newName}`],
    );
    if (limitExceeded) {
      validRequire = false;
    }
    // console.log('limitExceeded: ', limitExceeded);
  }

  if (
    (component.regExp &&
      activeConditionalRegExpEnabled &&
      show &&
      !disable &&
      (!!wizardData[newName] || wizardData[newName]?.toString().length > 0) &&
      !validateExp(component.regExp, wizardData[newName]?.toString())) ||
    (component.regExp && show && !wizardData[newName] && component?.required)
  ) {
    // console.log('component exp not valid: ', component);
    // console.log('exp wizardData[newName]: ', wizardData[newName]);
    // console.log('exp wizardData: ', wizardData);
    // console.log('exp newName: ', newName);
    validExp = false;
    // // console.log('validExp2 here: ');
  } else {
    validExp = true;
  }

  if (
    component.slug &&
    component.slug === 'MultiFieldConfigurer' &&
    show &&
    !disable &&
    component.name &&
    (component?.required || activeConditionalRequired)
  ) {
    if (
      !wizardData[`${component.name}`] ||
      wizardData[`${component.name}`].length === 0
    ) {
      validRequire = false;
    }
    const componentWizardData = wizardData[`${component.name}`];
    const groupedComponentsTemplate = component.layoutTemplate;

    const { isValidRequire, isValidExp } = validateMultiFieldConfigurer(
      groupedComponentsTemplate,
      componentWizardData,
    );
    validRequire = isValidRequire;
    validExp = isValidExp;
  }

  return { validRequire, validExp };
};

export const checkFormValidation = (storeState, currentForm, appSettings) => {
  const {
    components,
    disableValidation,
    makeNamesDistinct,
    applySelectedClientChanges,
  } = currentForm?.form;
  const { isAuthenticated, customerVerified } = appSettings || {};
  const wizardData = storeState?.wizards?.allInitialWizardData;
  let dataRequiredValid = true;
  let dataExpValid = true;
  const componentsWithRequireErrors = [];
  const componentsWithExpErrors = [];
  if (components && components.length > 0) {
    components.forEach(component => {
      const { show, disable } = checkComponent(
        component,
        storeState,
        isAuthenticated,
        customerVerified,
      );
      //
      const useSubForm = component?.useSubForm;
      const customForm = component?.customForm;
      // // console.log('useSubForm: ', useSubForm);
      if (useSubForm) {
        // // console.log('useSubForm: ', useSubForm);
        const initialSubForm = component?.subForm;
        const newComponent = { ...component };
        // // console.log('newComponent: ', newComponent);
        newComponent.items = newComponent?.items || newComponent?.options;
        const tempItems = transformExpansionItems(newComponent);
        // // console.log('tempItems: ', tempItems);

        tempItems.forEach(item => {
          const subForm = item?.optionSubForm || initialSubForm;
          // // console.log('subForm: ', subForm);
          const {
            components: subComponents,
            makeNamesDistinct: subMakeNamesDistinct,
          } = subForm;
          // // console.log('subComponents: ', subComponents);
          if (
            subComponents &&
            subComponents.length > 0 &&
            item?.value?.toString() === wizardData[component.name]?.toString()
          ) {
            subComponents.forEach(subComponent => {
              const { show: subShow, disable: subDisable } = checkComponent(
                subComponent,
                storeState,
                isAuthenticated,
                customerVerified,
              );
              // // console.log('subShow: ', subShow);
              const { validRequire, validExp } = validateComponent(
                storeState,
                subComponent,
                appSettings,
                subShow,
                subDisable,
                subMakeNamesDistinct,
                applySelectedClientChanges,
              );
              if (!validRequire) {
                dataRequiredValid = false;
                componentsWithRequireErrors.push(subComponent?.name);
              }
              if (!validExp) {
                dataExpValid = false;
                componentsWithExpErrors.push(subComponent?.name);
              }
            });
          }
        });
      }

      if (customForm) {
        const subComponents = customForm?.components;
        const isCustomForm = true;
        const parentName = component?.name;
        subComponents.forEach(customComponent => {
          const { validRequire, validExp } = validateComponent(
            storeState,
            customComponent,
            appSettings,
            show,
            disable,
            makeNamesDistinct,
            applySelectedClientChanges,
            isCustomForm,
            parentName,
          );
          if (!validRequire) {
            dataRequiredValid = false;
            componentsWithRequireErrors.push(customComponent?.name);
          }
          if (!validExp) {
            dataExpValid = false;
            componentsWithExpErrors.push(customComponent?.name);
          }
        });
      }
      const { validRequire, validExp } = validateComponent(
        storeState,
        component,
        appSettings,
        show,
        disable,
        makeNamesDistinct,
        applySelectedClientChanges,
      );
      if (!validRequire) {
        dataRequiredValid = false;
        componentsWithRequireErrors.push(component?.name);
      }
      if (!validExp) {
        dataExpValid = false;
        componentsWithExpErrors.push(component?.name);
      }
    });
  }
  if (disableValidation) {
    dataRequiredValid = true;
  }
  return {
    dataRequiredValid,
    dataExpValid,
    componentsWithExpErrors,
    componentsWithRequireErrors,
  };
};

export default {
  checkFormValidation,
  validateComponent,
};
