import { routerPostCall } from '../../axios';

export const sendQuoteDetailsURL = `/accounts/documents/send`;

const sendQuoteDetails = (state, params) => {
  const url = sendQuoteDetailsURL;
  return routerPostCall(state, url, params);
};

export default { sendQuoteDetails };
