import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { getReceipts } from '../actions/receipts';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import useSmall from './useSmall';
import { receiptsIndexName } from '../utils/variables';

const useReceiptData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const isMobile = useSmall();
  const user = useSelector(state => state.auth.user);
  const customerId = user?.customer?.id || '';
  const history = useHistory();
  const parsed = queryString.parse(history?.location?.search);
  const orderNo = parsed?.orderNo;
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: receiptsIndexName, infiniteScroll }),
  );
  const allRows = useSelector(state => state.receipts.allRows);
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);

  return ({
    nextStartIndex,
    nextItemCount,
    nextScrollCount,
    infiniteScroll: primaryInfiniteScroll,
    isMobile: primaryIsMobile,
  }) => {
    let finalInfiniteScroll = false;
    if (primaryInfiniteScroll !== undefined) {
      finalInfiniteScroll = primaryInfiniteScroll;
    } else {
      finalInfiniteScroll = infiniteScroll;
    }

    let finalIsMobile = isMobile;
    if (primaryIsMobile !== undefined) {
      finalIsMobile = primaryIsMobile;
    } else {
      finalIsMobile = isMobile;
    }

    let newStart;
    if (nextStartIndex) {
      newStart = nextStartIndex;
    } else if (defaultOptions?.startIndex) {
      newStart = defaultOptions?.startIndex;
    } else if (defaultOptions?.startIndex === 0) {
      newStart = defaultOptions?.startIndex;
    }

    let newLimit;
    // console.log('nextItemCount: ', nextItemCount);
    // console.log('defaultOptions.itemCount: ', defaultOptions?.itemCount);
    if (nextItemCount) {
      newLimit = nextItemCount;
    } else if (defaultOptions?.itemCount) {
      newLimit = defaultOptions?.itemCount;
    } else if (defaultOptions?.itemCount === 0) {
      newLimit = defaultOptions?.itemCount;
    }

    const body = {
      id: customerId,
      masterId: customerId,
      orderNo,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
      q: defaultOptions?.search ? defaultOptions?.search : undefined,
      paymentType:
        defaultOptions.status && defaultOptions.status.length > 0
          ? defaultOptions.status
          : undefined,
      scrollKey: defaultOptions?.scrollKey,
      staticLob: defaultOptions?.staticLob,
      infiniteScroll: finalInfiniteScroll,
      finalInfiniteScroll,
      isMobile: finalIsMobile,
      alpha2,
    };

    const params = {
      limit: newLimit,
      offset: newStart,
      q: defaultOptions?.search ? defaultOptions?.search : undefined,
      paymentType:
        defaultOptions.status && defaultOptions.status.length > 0
          ? defaultOptions.status
          : undefined,
    };

    return dispatch(getReceipts.getReceiptsAction(params, body));
  };
};
export default useReceiptData;
