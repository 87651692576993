import React from 'react';
import {
  // Container,
  Grid,
  Typography,
  Button,
  Paper,
  Icon,
  // Divider,
  // Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useFeatureToggle } from '../../../hooks';
import styles from './style';

const useStyles = makeStyles(styles);

const EmptyBox = ({ description, buttons, actions }) => {
  const classes = useStyles();
  const doFeatureToggle = useFeatureToggle();
  const autoPayFeatureActive = doFeatureToggle(`autoPay`);
  const payAnythingFeatureActive = doFeatureToggle(`payAnything`);
  const makePaymentActive = doFeatureToggle(`makePayment`);

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {description && (
          <Grid item xs={12} md={10}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.primary',
              }}
              align="left"
            >
              {description}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={10}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {buttons?.map(button => {
              return (
                <Grid item xs={12}>
                  {button}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            {actions?.map(action => {
              if (action?.hide) return null;
              if (action?.subKey?.includes('Recurring') && !autoPayFeatureActive)
                return null;
              if (action?.subKey?.includes('Custom Pay') && !payAnythingFeatureActive)
                return null;
              if (action?.subKey?.includes('Make a Payment') && !makePaymentActive)
                return null;
              let contentButton = null;

              contentButton = (
                <Button
                  className={classes[action?.variant || 'primaryButton']}
                  size={action?.size || 'small'}
                  fullWidth={action?.fullWidth || false}
                  width={action?.width || 'auto'}
                  onClick={action?.onClick}
                  component={RouterLink}
                  to={action?.link}
                  target={action?.target}
                  startIcon={
                    action?.icon && (
                      <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                        {action?.icon}
                      </Icon>
                    )
                  }
                  endIcon={
                    action?.endIcon && (
                      <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                        {action?.endIcon}
                      </Icon>
                    )
                  }
                  sx={{
                    textAlign: action?.textAlign || 'left',
                  }}
                  disabled={action?.isDisabled || false}
                >
                  {action?.title}
                </Button>
              );

              return (
                <Grid
                  item
                  xs={action?.xs || 'auto'}
                  md={action?.md || 'auto'}
                  lg={action?.lg || 'auto'}
                  style={{ width: action?.width || 'auto' }}
                >
                  {contentButton}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmptyBox;
