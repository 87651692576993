import { useSelector } from 'react-redux';
import {
  authSearchCategories,
  notAuthSearchCategories,
  agentSearchCategories,
  dutchAgentSearchCategories,
} from '../utils/variables/searchCategories';
import { agentType, dutchTemplate } from '../utils/variables';
import { addItemOnce } from '../utils/functions';
import useVariables from './useVariables';

const useSearchCategories = ({ overrides = [] } = {}) => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const variables = useVariables();
  const templateName = variables?.templateName;
  const isDutchRegion = templateName === dutchTemplate;
  const isAgent = useSelector(state => !!state?.auth?.user?.agent);
  let searchCategories = [...notAuthSearchCategories];
  if (isAuthenticated) {
    searchCategories = [...authSearchCategories];
  }
  if (isAgent) {
    searchCategories = [...agentSearchCategories];
    if (isDutchRegion) {
      searchCategories = [...dutchAgentSearchCategories];
    }
  }
  if (overrides && overrides.length > 0) {
    overrides.map(override => {
      searchCategories = addItemOnce(searchCategories, override, 'value');
    });
  }

  return [...searchCategories];
};

export default useSearchCategories;
