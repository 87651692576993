import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  UPDATE_PENDING_REQUESTS_CURRENT_CACHE_KEY,
  GET_PENDING_REQUESTS_API_REQUEST,
  GET_PENDING_REQUESTS_API_SUCCESS,
  GET_PENDING_REQUESTS_API_ERROR,
  GET_NEXT_PENDING_REQUESTS_SUCCESS,
} from '../../constants/pendingRequestActionNames';
import {
  APPROVE_PENDING_REQUEST_API_SUCCESS,
  REJECT_PENDING_REQUEST_API_SUCCESS,
  CANCEL_PENDING_REQUEST_API_SUCCESS,
} from '../../constants/requestActionNames';
import {
  RESET_INFINITE_SCROLL_SUCCESS,
  CLEAR_REQUESTS_CACHE,
} from '../../constants/miscActionNames';
import { createPayload } from '../newRequests';
import { persistKey, statusOk } from '../../utils/variables';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  filters: [],
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  [persistKey]: null,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
  client: null,
};

function pendingRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PENDING_REQUESTS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_PENDING_REQUESTS_API_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const body = action?.body;
      // console.log('action: ', action);
      const cacheKey = action?.cacheKey;
      const rowPayload = createPayload(
        action?.payload?.payload?.[0]?.result?.data,
        lobTypes,
        cacheKey,
        body,
      );
      const newRows = (rowPayload && Array.isArray(rowPayload) && rowPayload) || [];
      // console.log('newRows: ', newRows);
      // console.log('body: ', body);
      const newFilters = action?.payload?.payload?.[0]?.result?.statuses || [];
      const newTotal = action?.payload?.payload?.[0]?.result?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        filters: newFilters,
        // rows,
        count,
        searchKeys: newRows?.[0]?.searchKeys,
        allRows: newRows,
        // [persistKey]: nowTime,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case GET_PENDING_REQUESTS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action?.payload,
      };

    case UPDATE_PENDING_REQUESTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };

    case GET_NEXT_PENDING_REQUESTS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }

    case APPROVE_PENDING_REQUEST_API_SUCCESS:
    case REJECT_PENDING_REQUEST_API_SUCCESS:
    case CANCEL_PENDING_REQUEST_API_SUCCESS:
    case CLEAR_REQUESTS_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        allRows: initialState.allRows,
        total: initialState.total,
      };

    default:
      return state;
  }
}

export default pendingRequestsReducer;
