// Hook
import { useSelector } from 'react-redux';

const useStoreState = () => {
  const storeState = useSelector(state => state);

  return storeState;
};

export default useStoreState;
