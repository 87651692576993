import { combineReducers } from 'redux';
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { REHYDRATE, PURGE } from 'redux-persist/lib/constants';
// import storage from 'redux-persist-indexeddb-storage';
// import storage from 'redux-persist/lib/storage';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import expireReducer from 'redux-persist-expire';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import createCompressor from 'redux-persist-transform-compress';

// Reducers
import authReducer from './auth';
import profileReducer from './profile';
import productsReducer from './products';
import locationReducer from './mainLayout/location';
import tempReducer from './temp';
import accountReducer from './account';
import liveChatReducer from './liveChat';
import chatbotReducer from './chatbot';
import chatSDKReducer from './chatSDK';
import notificationsReducer from './notifications';
import insuranceReducer from './portfolio/insurance';
import investmentReducer from './portfolio/investments';
import investmentDetailTransactionsReducer from './portfolio/investmentTransactions';
import quotesReducer from './quotes';
import healthCardReducer from './healthCard';
import helpCenterReducer from './helpCenter';
import clientsReducer from './clients';
import verificationReducer from './dutch/verification';
import dutchPaymentsReducer from './dutchPayments';
import newRequestsReducer from './newRequests';
import pendingRequestsReducer from './pendingRequests';
import reportsReducer from './reports';
import paymentCardsReducer from './paymentCards';
import recurringPaymentsReducer from './recurringPayments';
import clientPoliciesReducer from './clientspolicies';
import claimsReducer from './claims';
import insuredItemsReducer from './insuredItems';
import cartReducer from './cart';
import renewalReducer from './renewals';
import requestsReducer from './requests';
import wizardsReducer from './wizards';
import receiptsReducer from './receipts';
import miscReducer from './misc';
import diskReducer from './disk';
import digitalSalesReducer from './digitalSales';
import officeLocatorReducer from './officeLocator';
import precertsReducer from './precerts/pendingPrecerts';
import registeredPrecertsReducer from './precerts/registeredPrecerts';

import history from '../history';
import { validateAuth } from '../hooks/useAuth';
import initialState from './initialState';
import { UPDATE_YOUR_STORAGE_VERSION_SUCCESS } from '../constants/miscActionNames';
import { SIGN_OUT_API_SUCCESS, SIGNIN_API_SUCCESS } from '../constants/authActionNames';
import {
  GET_INSURANCES_API_SUCCESS,
  GET_SINGLE_INSURANCE_API_SUCCESS,
} from '../constants/portfolio/insuranceActionNames';
import { persistKey, agentType, customerType } from '../utils/variables';
// import { getPersistor } from '../store';
import {
  mergeArrayOnce,
  mergeArraysDeep,
  rangeOfYears,
  removeDuplicates,
} from '../utils/functions'; // checkOnlineStatus

import { removeAccessToken, getAccessToken } from '../storage/accessToken';
import pendingClaimsReducer from './claims/pendingClaims';
import batchClaimsReducer from './batchClaims';

import routes from '../utils/variables/routes';

const smallExpireTime = 60 * 10; // 120
const wizardExpireTime = 180 * 5;
// const longExpireTime = 3600 * 24 * 7;

const whitelist = [
  'auth',
  // 'products',
  // 'locations',
  // 'cart',
  'disk',
  'misc',
  // 'investments',
  'wizards',
  // 'renewals',
  // 'insurance',
  // 'insuredItems',
];
const blacklist = ['_persist'];

// you want to store only a subset of your state of reducer one

const compressor = createCompressor({
  whitelist,
  blacklist,
}); // or

/*
import createEncryptor from 'redux-persist-transform-encrypt'
const encryptor = createEncryptor({
  secretKey: 'my-super-secret-key',
});
[encryptor];
*/
const getPersistConfig = secretKey => ({
  key: 'root',
  storage: localForage,
  whitelist,
  blacklist,
  // storage,
  // storage: storage('myDB'),

  stateReconciler: autoMergeLevel2,
  transforms: [
    expireReducer('wizards', {
      expireSeconds: 3600 * 24 * 7,
      expiredState: initialState.wizards,
      persistedAtKey: persistKey,
      autoExpire: true,
    }),

    compressor,
    encryptTransform({
      secretKey,
      onError(error) {
        console.error('error: ', error);
        // Handle the error.
      },
    }),
  ],
});

// let rootPersistConfig = null; // getPersistConfig(encryptKey);

const appReducer = combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  account: accountReducer,
  auth: authReducer,
  liveChat: liveChatReducer,
  chatbot: chatbotReducer,
  chatSDK: chatSDKReducer,
  profile: profileReducer,
  misc: miscReducer,
  disk: diskReducer,
  locations: locationReducer,
  products: productsReducer,
  insurance: insuranceReducer,
  renewals: renewalReducer,
  cart: cartReducer,
  receipts: receiptsReducer,
  insuredItems: insuredItemsReducer,
  quotes: quotesReducer,
  healthCard: healthCardReducer,
  helpCenter: helpCenterReducer,
  claims: claimsReducer,
  batchClaims: batchClaimsReducer,
  partiallyFilledClaims: pendingClaimsReducer,
  requests: requestsReducer,
  investments: investmentReducer,
  transactions: investmentDetailTransactionsReducer,
  wizards: wizardsReducer,
  clients: clientsReducer,
  reports: reportsReducer,
  clientPolicies: clientPoliciesReducer,
  notifications: notificationsReducer,
  digitalSales: digitalSalesReducer,
  temp: tempReducer,
  officeLocator: officeLocatorReducer,
  recurringPayments: recurringPaymentsReducer,
  pendingPrecerts: precertsReducer,
  registeredPrecerts: registeredPrecertsReducer,
  paymentCards: paymentCardsReducer,
  dutchPayments: dutchPaymentsReducer,
  verifications: verificationReducer,
  pendingRequests: pendingRequestsReducer,
  newRequests: newRequestsReducer,
});

const rootReducer = (_state, action) => {
  let state = { ..._state };

  if (action.type === UPDATE_YOUR_STORAGE_VERSION_SUCCESS) {
    const newStorageVersion = action.payload;

    const { router, misc, disk } = state;
    const oldStorageVersion = disk?.storageVersion || misc?.storageVersion;
    if (newStorageVersion !== oldStorageVersion && oldStorageVersion) {
      disk.storageVersion = newStorageVersion;
      disk.devices = disk?.devices || misc?.devices;
      state = { router, disk };
    }
  }

  if (action.type === REHYDRATE) {
    const storageData = action?.payload || {};
    const result = storageData?.auth?.signUpContactResponse || {};

    const passwordKey = 'password';
    const confirmPasswordKey = 'confirmPassword';

    delete result[passwordKey];
    delete result[confirmPasswordKey];
    if (storageData && storageData.auth && typeof storageData.auth === 'object') {
      storageData.auth.signUpContactResponse = result;
    }
    if (storageData && storageData.misc && typeof storageData.misc === 'object') {
      storageData.misc.openChatBot = false;
      storageData.misc.isRehydrated = true;
      storageData.misc.modalContent = null;
    } else {
      storageData.misc = {
        openChatBot: false,
        isRehydrated: true,
        modalContent: null,
      };
    }

    state = { ...state, ...storageData };
    // // console.log(`Local Storage Data: ${JSON.stringify(action.payload)}`);
  }

  if (action.type === LOCATION_CHANGE) {
    const pathname = action?.payload?.location?.pathname || '';

    if (
      (!pathname?.includes(routes.wizards.index) ||
        pathname === routes.wizards.quote ||
        pathname === routes.wizards.claim ||
        pathname === routes.wizards.application ||
        pathname === routes.wizards.calculator ||
        pathname === routes.wizards.submitDocument) &&
      !pathname?.includes(routes.auth.index) &&
      !pathname?.includes(routes.account.index)
    ) {
      const wizardStorageData = state?.wizards;
      wizardStorageData.allInitialWizardData = {};
      wizardStorageData.wizard = null;
      state = { ...state, wizards: wizardStorageData };
    }
    /*
      const investmentTime = state.investments[persistKey] || null;
      if (investmentTime) {
        const investmentDateTime = investmentTime + smallExpireTime * 1000;
        const nowTime = new Date().getTime();
        if (investmentDateTime < nowTime) {
          //  state.investments = initialState.investments;
          state.investments = {
            ...initialState.investments,
            balanceDate: state.investments.balanceDate,
          };
        }
      } else {
        // state.investments = initialState.investments;
        state.investments = {
          ...initialState.investments,
          balanceDate: state.investments.balanceDate,
        };
      }
      const insuranceTime = state.insurance[persistKey] || null;
      if (insuranceTime) {
        const insuranceDateTime = insuranceTime + smallExpireTime * 1000;
        const nowTime = new Date().getTime();
        if (insuranceDateTime < nowTime) {
          state.insurance = initialState.insurance;
        }
      } else {
        state.insurance = {
          ...initialState.insurance,
          firstTime: state.insurance.firstTime,
        };
      }
      */

    /*
      const productTime = state.products[persistKey] || null;
      if (productTime) {
        const productDateTime = productTime + smallExpireTime * 1000;
        const nowTime = new Date().getTime();
        if (productDateTime < nowTime) {
          state.products = initialState.products;
        }
      } else {
        state.products = initialState.products;
      }
      */

    const clientPoliciesTime = state.clientPolicies[persistKey] || null;
    if (clientPoliciesTime) {
      const clientPoliciesDateTime = clientPoliciesTime + smallExpireTime * 1000;
      const nowTime = new Date().getTime();
      if (clientPoliciesDateTime < nowTime) {
        state.clientPolicies = initialState.clientPolicies;
      }
    } else {
      state.clientPolicies = initialState.clientPolicies;
    }

    const clientTime = state.clients[persistKey] || null;
    if (clientTime) {
      const clientDateTime = clientTime + smallExpireTime * 1000;
      const nowTime = new Date().getTime();
      if (clientDateTime < nowTime) {
        state.clients = initialState.clients;
      }
    } else {
      state.clients = initialState.clients;
    }
  }

  const initialUser = state?.auth?.user;
  let user = { ...initialUser };

  // user?.accessToken

  const { isAuthenticated: tempAuthenticated, revalidate, isExpired } = validateAuth(
    state,
  );
  const storageAccessToken = getAccessToken();
  const accessToken = user?.accessToken;
  // // console.log('isAuthenticated: ', isAuthenticated);
  // // console.log('revalidate: ', revalidate);
  // let myEncryptKey = accessToken;
  let newAuthenticated = tempAuthenticated;

  if (!accessToken && state?.misc?.isRehydrated && !state?.temp?.appReady) {
    // console.log('accessToken: ', accessToken);
    state.auth.usertype = customerType;
  }

  if (
    ((!newAuthenticated && user && user?.accessToken) ||
      (!storageAccessToken &&
        action.type !== SIGNIN_API_SUCCESS &&
        newAuthenticated &&
        user &&
        user?.accessToken)) &&
    state?.misc?.isRehydrated &&
    state?.temp?.appReady
  ) {
    // // console.log('action.type: ', action.type);
    // // console.log('user: ', user);
    // // console.log('newAuthenticated: ', newAuthenticated);
    // // console.log('accessToken: ', accessToken);
    user = initialState?.auth?.user;

    newAuthenticated = false;
    // myEncryptKey = initialState?.auth?.encryptKey;
    removeAccessToken();
  }

  const usertype = state?.auth?.usertype;
  const expiresIn = state?.auth?.expiresIn;
  const incomingAuth = {
    [persistKey]: state?.auth?.[persistKey] || null,
    user,
    isAuthenticated: newAuthenticated,
    revalidate,
    usertype,
    expiresIn,
    isExpired,
    selectedClient: state?.auth?.user?.agent ? state?.auth.selectedClient : null,
    // encryptKey: myEncryptKey,
  };

  if (state?.misc?.isRehydrated && action.type !== REHYDRATE && state?.temp?.appReady) {
    const newAuth = { ...state?.auth, ...incomingAuth };
    const newState = { auth: newAuth };
    state = { ...state, ...newState };
  }

  if (action.type === REHYDRATE || action.type === PURGE) {
    const allWizardData = state?.wizards?.allWizardData || {};
    const persist = state?.wizards[persistKey] || null;
    const userId = state?.auth?.user?.id || null;
    const authUnAuthTime = state.auth ? state.auth[persistKey] : null;

    const wizardUnAuthTime =
      allWizardData && allWizardData.none ? allWizardData.none[persistKey] : null;
    const nowTime = new Date().getTime();
    if (userId) {
      const wizardAuthData =
        allWizardData && allWizardData[userId] ? allWizardData[userId] : {};
      const wizardAuthTime = wizardAuthData ? wizardAuthData[persistKey] : null;
      const wizardAuthDateTime = wizardAuthTime + wizardExpireTime * 1000;

      if (wizardAuthDateTime >= nowTime) {
        state.wizards = {
          ...initialState.wizards,
          allInitialWizardData: wizardAuthData,
          allWizardData,
          [persistKey]: persist,
          resettedWizardData: state?.wizards?.resettedWizardData,
          // nextBackButtonLink: state?.wizards?.nextBackButtonLink,
          lastEntryPoint: state?.wizards?.lastEntryPoint,
        };
      } else {
        allWizardData[userId] = {};
        state.wizards = {
          ...initialState.wizards,
          // allInitialWizardData: {},
          allWizardData,
          [persistKey]: persist,
        };
      }
    } else if (wizardUnAuthTime) {
      const wizardUnAuthDateTime = wizardUnAuthTime + wizardExpireTime * 1000;

      if (wizardUnAuthDateTime > nowTime) {
        const wizardUnAuthData =
          allWizardData && allWizardData.none ? allWizardData.none : {};
        state.wizards = {
          ...initialState.wizards,
          allInitialWizardData: wizardUnAuthData,
          allWizardData,
          [persistKey]: persist,
          resettedWizardData: state?.wizards?.resettedWizardData,
          // nextBackButtonLink: state?.wizards?.nextBackButtonLink,
          lastEntryPoint: state?.wizards?.lastEntryPoint,
        };
      } else {
        allWizardData.none = {};
        state.wizards = {
          ...initialState.wizards,
          // allInitialWizardData: {},
          allWizardData,
          [persistKey]: persist,
        };
      }
    }

    // console.log('authUnAuthTime: ', authUnAuthTime);
    if (authUnAuthTime) {
      const authUnAuthDateTime = authUnAuthTime + smallExpireTime * 1000;
      // console.log('authUnAuthTime: ', authUnAuthTime);
      if (authUnAuthDateTime < nowTime) {
        state.auth = { ...initialState.auth, ...incomingAuth, [persistKey]: nowTime };
      }
    }
  }

  if (action.type === SIGN_OUT_API_SUCCESS || (isExpired && initialUser?.accessToken)) {
    // console.log('incomingAuth: ', incomingAuth);
    // console.log('initialState',initialState);
    // console.error('here signing out');
    const signOutWizardData = state?.wizards?.allWizardData?.none || {};
    const allWizardData = state?.wizards?.allWizardData;

    if (action.type === SIGN_OUT_API_SUCCESS) {
      const tempAuth = state?.auth;
      const tempMisc = state?.misc;
      const tempLocation = state?.locations;
      const tempDisk = state?.disk;
      const tempRouter = state?.router;
      state = initialState;
      state.auth = tempAuth;
      state.locations = tempLocation;
      state.disk = tempDisk;
      state.router = tempRouter;

      const tempRedirectUrl = tempMisc?.redirectUrl;
      state.misc = { ...tempMisc, redirectUrl: null };
      if (action?.params?.signOutType === 'auto') {
        state.misc.redirectUrl = tempRedirectUrl;
        state.auth.signOutType = null;
      }
      const persist = null; // state?.wizards[persistKey] || null;
      // allWizardData.none = {};
      state.wizards = {
        ...initialState.wizards,
        // allInitialWizardData: signOutWizardData,
        // allWizardData,
        [persistKey]: persist,
      };
    }

    if (state.misc.isRehydrated) {
      state.auth = { ...initialState.auth, ...incomingAuth };
    }
  }

  if (action.type === SIGNIN_API_SUCCESS) {
    const newUser = action?.payload?.payload || null; // ...state?.user,
    const customerId = newUser?.customer?.id;
    // // console.log('sign In customerId: ', customerId);
    const signInWizardData =
      state?.wizards?.allWizardData[customerId] ||
      state?.wizards?.allWizardData?.none ||
      {};
    const persist = state?.wizards[persistKey] || null;
    state.wizards = {
      ...initialState.wizards,
      allInitialWizardData: signInWizardData,
      allWizardData: state?.wizards?.allWizardData,
      [persistKey]: persist,
    };
  }

  // // console.log('my state: ', state);
  // secretKey = user?.accessToken || encryptKey;

  const updatedState = appReducer(state, action);

  if (
    action.type === GET_INSURANCES_API_SUCCESS ||
    action.type === GET_SINGLE_INSURANCE_API_SUCCESS
  ) {
    const singlePolicy = updatedState.insurance.insurancePolicy;
    const insuranceAccounts = updatedState?.insurance?.accounts || [];
    if (singlePolicy && singlePolicy.id) {
      insuranceAccounts.push(singlePolicy);
    }
    const oldAccounts = removeDuplicates(insuranceAccounts, 'id');
    const newAccounts = updatedState.investments.accounts;
    const finalAccounts = oldAccounts?.map(account => {
      const oldAccount = oldAccounts?.find(x => x?.id === account?.id) || {};
      const newAccount = newAccounts?.find(x => x?.id === account?.id) || {};
      const finalHoldings = mergeArrayOnce(
        oldAccount?.holdings,
        newAccount?.holdings,
        'secCode',
      );
      return {
        ...oldAccount,
        ...newAccount,
        holdings: finalHoldings,
      };
    });
    updatedState.investments.accounts = finalAccounts;
    updatedState.investments.addedAccounts = finalAccounts;
    updatedState.investments.initialAccounts = finalAccounts;
  }

  return updatedState;
};

export default encryptKey => {
  // // console.log('secretKey: ', encryptKey);
  const rootPersistConfig = getPersistConfig(encryptKey); // state?.auth?.encryptKey
  return persistReducer(rootPersistConfig, rootReducer);
};

/*
    expireReducer('auth', {
      expireSeconds: 3600 * 24 * 7,
      expiredState: initialState.auth,
      persistedAtKey: persistKey,
      autoExpire: true,
    }),
    expireReducer('renewals', {
      expireSeconds: 3600 * 24 * 7,
      expiredState: initialState.renewals,
      persistedAtKey: persistKey,
      autoExpire: true,
    }),

getPersistor()
          .purge()
          .then(result => {
            // console.log('result: ', result);
            localStorage.clear();
            window.alert('Data Storage Resetted');
            return Promise.resolve(null);
          })
          .catch(() => window.alert('Error occured in resetting your local storage'));

 const insuredItemsTime = state.insuredItems[persistKey] || null;
      if (insuredItemsTime) {
        const insuredItemsDateTime = insuredItemsTime + smallExpireTime * 1000;
        const nowTime = new Date().getTime();
        if (insuredItemsDateTime < nowTime) {
          state.insuredItems = initialState.insuredItems;
        }
      } else {
        state.insuredItems = initialState.insuredItems;
      }

persistReducer(wizardPersistConfig, wizardsReducer), //
const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isUserLoggingIn, isUserRegistering'],
};
auth: persistReducer(authPersistConfig, authReducer),

const wizardPersistConfig = {
  key: 'root.wizards',
  storage,
  whitelist: ['wizard'],
  blacklist: ['_persist'],
  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer('wizard', {
      expireSeconds: 120, // 3600 * 1,
      autoExpire: true,
    }),
  ],
};

  transforms: [
    // Create a transformer by passing the reducer key and configuration. Values
    // shown below are the available configurations with default values
    expireReducer('investments', {
      expireSeconds: smallExpireTime, // 3600 * 1,
      expiredState: initialState.investments,
      // autoExpire: true,
      persistedAtKey: persistKey,
    }),
    expireReducer('locations', {
      expireSeconds: longExpireTime,
      expiredState: initialState.locations,
      persistedAtKey: persistKey,
    }),
    expireReducer('auth', {
      expireSeconds: longExpireTime,
      expiredState: initialState.auth,
      persistedAtKey: persistKey,
    }),
    expireReducer('wizards', {
      expireSeconds: longExpireTime,
      expiredState: initialState.wizards,
      persistedAtKey: persistKey,
    }),
    expireReducer('renewals', {
      expireSeconds: longExpireTime,
      expiredState: initialState.renewals,
      persistedAtKey: persistKey,
    }),
  ],

  */
