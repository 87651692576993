import {
  DUTCH_PAYMENT_STATUS_ERROR,
  DUTCH_PAYMENT_STATUS_SUCCESS,
  DUTCH_PAYMENT_STATUS_REQUEST,
  DUTCH_PAYMENT_RETRY_SUCCESS,
  DUTCH_PAYMENT_RETRY_REQUEST,
  DUTCH_PAYMENT_RETRY_ERROR,
} from '../../constants/dutchPaymentActionNames';

export const initialState = {
  isFetchingTransactionDetails: false,
  transactionDetails: null,
  error: null,
  isFetchingRetryDetails: false,
  retryDetails: null,
  retryError: false,
};

function dutchPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case DUTCH_PAYMENT_STATUS_REQUEST: {
      return {
        ...state,
        isFetchingTransactionDetails: true,
      };
    }
    case DUTCH_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        isFetchingTransactionDetails: false,
        transactionDetails: action.payload,
        error: null,
      };
    }
    case DUTCH_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        isFetchingTransactionDetails: false,
        payload: action.payload,
        error: true,
      };
    }
    case DUTCH_PAYMENT_RETRY_REQUEST: {
      return {
        ...state,
        isFetchingRetryDetails: true,
      };
    }
    case DUTCH_PAYMENT_RETRY_SUCCESS: {
      return {
        ...state,
        isFetchingRetryDetails: false,
        retryDetails: action.payload,
        retryError: null,
      };
    }
    case DUTCH_PAYMENT_RETRY_ERROR: {
      return {
        ...state,
        isFetchingRetryDetails: false,
        retryDetails: action.payload,
        retryError: true,
      };
    }
    default:
      return state;
  }
}

export default dutchPaymentsReducer;
