import {
  GET_INVESTMENTS_API_REQUEST,
  GET_INVESTMENTS_API_SUCCESS,
  GET_INVESTMENTS_API_ERROR,
  ADD_INVESTMENT_ACCOUNT_SUCCESS,
} from '../../../constants/portfolio/investmentActionNames';

// import { api } from '../../../utils/variables';
import { getInvestmentsApi } from '../../../apis/portfolio';
import { getPayloadResponse } from '../../../reducers/portfolio/investments';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getInvestmentsUrl } from '../../../apis/portfolio/getInvestmentsApi';

function getInvestmentsError(err) {
  return {
    type: GET_INVESTMENTS_API_ERROR,
    payload: err,
  };
}

function getInvestmentsRequest() {
  return {
    type: GET_INVESTMENTS_API_REQUEST,
    payload: true,
  };
}
function getInvestmentsSuccess(prod, params, cacheKey, lobTypes) {
  return {
    type: GET_INVESTMENTS_API_SUCCESS,
    payload: prod,
    params,
    lobTypes,
    cacheKey,
  };
}

export function getInvestmentsAction(params) {
  // const apiEndpoint = {}; // ...api };
  // const response = getPayloadResponse();

  return (dispatch, getState) => {
    const state = getState();
    const cacheKey = generateCacheKey({
      path: getInvestmentsUrl,
      cacheParams: params,
      state,
    });
    const lobTypes = state?.locations?.lobCategories;

    const { isCacheValid } = checkCacheValid(getState, 'investments', cacheKey);
    if (isCacheValid) {
      // dispatch(getInvestmentsSuccess(null, params, cacheKey, lobTypes));
      return Promise.resolve(null);
    }

    dispatch(getInvestmentsRequest());
    return getInvestmentsApi
      .getInvestments(state, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getInvestmentsSuccess(data, params, cacheKey, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getInvestmentsError(error));
        throw error;
      });
  };
}

function addInvestmentAccountSuccess(params) {
  return {
    type: ADD_INVESTMENT_ACCOUNT_SUCCESS,
    payload: true,
    params,
  };
}

export function addInvestmentAccountAction(params) {
  return addInvestmentAccountSuccess(params);
}

export default {
  getInvestmentsAction,
  addInvestmentAccountAction,
};
