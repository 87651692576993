import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';
import {
  GET_PAYMENT_CARD_DETAILS_REQUEST,
  GET_PAYMENT_CARD_DETAILS_SUCCESS,
  GET_PAYMENT_CARD_DETAILS_ERROR,
  GET_PAYMENT_CARDS_REQUEST,
  GET_PAYMENT_CARDS_SUCCESS,
  GET_PAYMENT_CARDS_ERROR,
  ADD_PAYMENT_CARD_REQUEST,
  ADD_PAYMENT_CARD_SUCCESS,
  ADD_PAYMENT_CARD_ERROR,
  UPDATE_PAYMENT_CARD_REQUEST,
  UPDATE_PAYMENT_CARD_SUCCESS,
  UPDATE_PAYMENT_CARD_ERROR,
  DELETE_PAYMENT_CARD_REQUEST,
  DELETE_PAYMENT_CARD_SUCCESS,
  DELETE_PAYMENT_CARD_ERROR,
  AUTHORIZE_PAYMENT_CARD_REQUEST,
  AUTHORIZE_PAYMENT_CARD_SUCCESS,
  AUTHORIZE_PAYMENT_CARD_ERROR,
} from '../../constants/paymentCardActionNames';
import { CLEAR_PAYMENT_CARDS_CACHE } from '../../constants/miscActionNames';
import {
  stringToDate,
  isoStringToDate,
  dateToString,
  toTitleCase,
} from '../../utils/functions';
import { persistKey } from '../../utils/variables';

export const initialState = {
  isGetPaymentCardsSuccess: false,
  isGetPaymentCardsError: false,
  isGetPaymentCardsPending: false,
  isGetPaymentCardDetailsSuccess: false,
  isGetPaymentCardDetailsError: false,
  isGetPaymentCardDetailsPending: false,
  isAddPaymentCardSuccess: false,
  isAddPaymentCardError: false,
  isAddPaymentCardPending: false,
  isDeletePaymentCardSuccess: false,
  isDeletePaymentCardError: false,
  isDeletePaymentCardPending: false,
  isUpdatePaymentCardSuccess: false,
  isUpdatePaymentCardError: false,
  isUpdatePaymentCardPending: false,
  isAuthorizePaymentCardSuccess: false,
  isAuthorizePaymentCardError: false,
  isAuthorizePaymentCardPending: false,
  paymentCards: [],
  paymentCard: null,
  error: null,
  authorize: null,
  [DEFAULT_CACHE_KEY]: null,
};
//
const createPayload = payloadArray => {
  if (!Array.isArray(payloadArray)) return [];

  const paymentCards = payloadArray?.map((object, index) => {
    const position = index;
    const paymentCard = {
      ...object,
      index: position,
      id: object?._id,
    };

    return paymentCard;
  });

  return paymentCards;
};

function paymentCardsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_CARD_DETAILS_REQUEST: {
      return {
        ...state,
        isGetPaymentCardDetailsSuccess: false,
        isGetPaymentCardDetailsError: false,
        isGetPaymentCardDetailsPending: true,
      };
    }
    case GET_PAYMENT_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        isGetPaymentCardDetailsSuccess: true,
        isGetPaymentCardDetailsError: false,
        isGetPaymentCardDetailsPending: false,
        paymentCard: action?.payload?.payload,
      };
    }
    case GET_PAYMENT_CARD_DETAILS_ERROR:
      return {
        ...state,
        isGetPaymentCardDetailsSuccess: false,
        isGetPaymentCardDetailsError: true,
        isGetPaymentCardDetailsPending: false,
        error: action?.payload,
      };
    case GET_PAYMENT_CARDS_REQUEST: {
      return {
        ...state,
        isGetPaymentCardsSuccess: false,
        isGetPaymentCardsError: false,
        isGetPaymentCardsPending: true,
      };
    }
    case GET_PAYMENT_CARDS_SUCCESS: {
      const response = action?.payload;
      const cacheKey = action?.cacheKey;
      return {
        ...state,
        isGetPaymentCardsSuccess: true,
        isGetPaymentCardsError: false,
        isGetPaymentCardsPending: false,
        paymentCards: createPayload(response?.payload),
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
      };
    }
    case GET_PAYMENT_CARDS_ERROR:
      return {
        ...state,
        isGetPaymentCardsSuccess: false,
        isGetPaymentCardsError: true,
        isGetPaymentCardsPending: false,
        error: action?.payload,
      };
    case ADD_PAYMENT_CARD_REQUEST: {
      return {
        ...state,
        isAddPaymentCardSuccess: false,
        isAddPaymentCardError: false,
        isAddPaymentCardPending: true,
      };
    }
    case ADD_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isAddPaymentCardSuccess: true,
        isAddPaymentCardError: false,
        isAddPaymentCardPending: false,
        paymentCard: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case ADD_PAYMENT_CARD_ERROR:
      return {
        ...state,
        isAddPaymentCardSuccess: false,
        isAddPaymentCardError: true,
        isAddPaymentCardPending: false,
        error: action?.payload,
      };

    case AUTHORIZE_PAYMENT_CARD_REQUEST: {
      return {
        ...state,
        isAuthorizePaymentCardSuccess: false,
        isAuthorizePaymentCardError: false,
        isAuthorizePaymentCardPending: true,
      };
    }
    case AUTHORIZE_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isAuthorizePaymentCardSuccess: true,
        isAuthorizePaymentCardError: false,
        isAuthorizePaymentCardPending: false,
        authorize: action?.payload?.payload,
      };
    }
    case AUTHORIZE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        isAuthorizePaymentCardSuccess: false,
        isAuthorizePaymentCardError: true,
        isAuthorizePaymentCardPending: false,
        error: action?.payload,
      };

    case UPDATE_PAYMENT_CARD_REQUEST: {
      return {
        ...state,
        isUpdatePaymentCardSuccess: false,
        isUpdatePaymentCardError: false,
        isUpdatePaymentCardPending: true,
      };
    }
    case UPDATE_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isUpdatePaymentCardSuccess: true,
        isUpdatePaymentCardError: false,
        isUpdatePaymentCardPending: false,
        paymentCard: action?.payload?.payload,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case UPDATE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        isUpdatePaymentCardSuccess: false,
        isUpdatePaymentCardError: true,
        isUpdatePaymentCardPending: false,
        error: action?.payload,
      };
    case DELETE_PAYMENT_CARD_REQUEST: {
      return {
        ...state,
        isDeletePaymentCardSuccess: false,
        isDeletePaymentCardError: false,
        isDeletePaymentCardPending: true,
      };
    }
    case DELETE_PAYMENT_CARD_SUCCESS: {
      return {
        ...state,
        isDeletePaymentCardSuccess: true,
        isDeletePaymentCardError: false,
        isDeletePaymentCardPending: false,
        paymentCard: null,
        [DEFAULT_CACHE_KEY]: null,
      };
    }
    case DELETE_PAYMENT_CARD_ERROR:
      return {
        ...state,
        isDeletePaymentCardSuccess: false,
        isDeletePaymentCardError: true,
        isDeletePaymentCardPending: false,
        error: action?.payload,
      };

    case CLEAR_PAYMENT_CARDS_CACHE:
      return {
        ...state,
        [persistKey]: null,
        [DEFAULT_CACHE_KEY]: null,
        paymentCards: initialState.paymentCards,
        paymentCard: initialState.paymentCard,
      };

    default:
      return state;
  }
}

export default paymentCardsReducer;
