import {
  ACCEPT_QUOTE_API_REQUEST,
  ACCEPT_QUOTE_API_SUCCESS,
  ACCEPT_QUOTE_API_ERROR,
} from '../../../constants/quoteActionNames';

import { acceptQuoteApi } from '../../../apis/quotes/index';

function acceptQuoteRequest() {
  return {
    type: ACCEPT_QUOTE_API_REQUEST,
    payload: true,
  };
}

function acceptQuoteSuccess(quotes, params) {
  return {
    type: ACCEPT_QUOTE_API_SUCCESS,
    payload: params,
  };
}

function acceptQuoteError(err) {
  return {
    type: ACCEPT_QUOTE_API_ERROR,
    payload: err,
  };
}

export function acceptQuoteAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();

    dispatch(acceptQuoteRequest());
    return acceptQuoteApi
      .acceptQuotes(state, params)
      .then(data => {
        dispatch(acceptQuoteSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(acceptQuoteError(error, params));
        throw error;
      });
  };
}

export default { acceptQuoteAction };
