import {
  GET_ONFIDO_CHECK_LIST_REQUEST,
  GET_ONFIDO_CHECK_LIST_SUCCESS,
  GET_ONFIDO_CHECK_LIST_ERROR,
} from '../../../constants/dutch/verificationsActionNames';

export const initialState = {
  isLoading: false,
  isSuccess: true,
  isError: false,
  onfidoChecks: null,
};

function verificationReducer(_state = initialState, action) {
  const state = {
    ..._state,
  };

  switch (action.type) {
    case GET_ONFIDO_CHECK_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    }
    case GET_ONFIDO_CHECK_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        onfidoChecks: action.payload,
      };
    }
    case GET_ONFIDO_CHECK_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export default verificationReducer;
