import React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paper, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useRouter } from '../../../hooks';
import { createRelativeUrl, uuidv4, getRef } from '../../../utils/functions';
import styles from './style';
import { requestsIndexName, agentType } from '../../../utils/variables';
import routes from '../../../utils/variables/routes';

const StatusChipList = ({ name, totals = {}, onCustomClick }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const theme = useTheme();
  const statusCategories = [];
  const yourLocation = useSelector(state => state.locations.yourLocation);
  const alpha2 = yourLocation?.alpha2;
  const router = useRouter();
  const parsed = router?.query || {};
  const { status } = parsed;

  let allPathname = null;
  let pathname = null;

  if (name === requestsIndexName) {
    allPathname = routes.portfolio.pendingInvestmentRequestHistory;
    pathname = routes.portfolio.pendingInvestmentRequestHistory;
  }

  let filteredStatusCategories = [];

  filteredStatusCategories = statusCategories.filter(
    x => x.isAvailableForCustomerPortfolio,
  );

  let fullTotal = 0;

  Object.values(totals)?.map(x => {
    if (x) {
      fullTotal += x;
    }
    return x;
  });

  filteredStatusCategories.unshift({
    title: 'Pending',
    status: 'pending',
    color: 'background.chip',
    total: fullTotal,
  });
  filteredStatusCategories.unshift({
    title: 'Rejected',
    status: 'rejected',
    color: 'background.chip',
    total: fullTotal,
  });
  filteredStatusCategories.unshift({
    title: 'All',
    status: 'all',
    color: 'background.chip',
    total: fullTotal,
  });
  const handleChange = data => {
    if (onCustomClick) {
      onCustomClick();
    }
  };

  return (
    <Paper component="ul" classes={{ root: classes.root }} elevation={0}>
      {filteredStatusCategories.map(statusCategory => {
        const statusColor = getRef(theme.palette, statusCategory?.color);

        const chipClasses = useStyles({
          color: statusColor,
          contrastColor: theme.palette.text.bright,
        });
        if (statusCategory?.status !== 'all') {
          statusCategory.total =
            totals[`${statusCategory?.status}_${alpha2}`] ||
            totals[statusCategory?.status] ||
            0;
        }
        if (statusCategory?.hideIfEmpty && statusCategory?.total === 0) {
          return null;
        }

        let finalUrl = '';
        const newParsed = {};
        newParsed.status = statusCategory?.status;
        let path = pathname;
        const queryObject = { ...parsed, ...newParsed };
        if (statusCategory?.status === 'all') {
          path = allPathname;
          delete queryObject.status;
        }
        const relativeUrl = createRelativeUrl({
          path,
          queryObject,
        });

        finalUrl = relativeUrl;
        console.log('Final URL', finalUrl);

        return (
          <li key={uuidv4()}>
            <Chip
              // icon={icon}
              component={RouterLink}
              to={finalUrl}
              label={
                statusCategory?.total
                  ? `${statusCategory?.title} (${statusCategory?.total})`
                  : `${statusCategory?.title}`
              }
              onClick={() => handleChange(statusCategory)}
              classes={{
                root: classnames(chipClasses.chip, {
                  [chipClasses.active]: status === statusCategory?.status,
                  [chipClasses.inActive]: status !== statusCategory?.status,
                }),
              }}
            />
          </li>
        );
      })}
    </Paper>
  );
};

StatusChipList.defaultProps = {
  allPathname: '/portfolio/accounts/history/pending/request',
  pathname: '/portfolio/accounts/history/pending/request',
};

export default StatusChipList;
