import {
  SELECT_CLIENTS_SUCCESS,
  SELECT_DUTCH_CLIENTS_SUCCESS,
} from '../../../constants/authActionNames';

function selectedClientSuccess(client) {
  return {
    type: SELECT_CLIENTS_SUCCESS,
    payload: client,
    customerId: client?.clientNumber || client?.id,
  };
}

function selectedDutchClientSuccess(client) {
  return {
    type: SELECT_DUTCH_CLIENTS_SUCCESS,
    payload: client,
    customerId: client?.clientNumber || client?.id,
  };
}

export default {
  selectedClientSuccess,
  selectedDutchClientSuccess,
};
