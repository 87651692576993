import {
  APPROVE_PENDING_REQUEST_API_REQUEST,
  APPROVE_PENDING_REQUEST_API_SUCCESS,
  APPROVE_PENDING_REQUEST_API_ERROR,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function approvePendingRequestRequest() {
  return {
    type: APPROVE_PENDING_REQUEST_API_REQUEST,
    payload: true,
  };
}

function approvePendingRequestSuccess(requests, params) {
  return {
    type: APPROVE_PENDING_REQUEST_API_SUCCESS,
    payload: requests,
    params,
  };
}

function approvePendingRequestError(err) {
  return {
    type: APPROVE_PENDING_REQUEST_API_ERROR,
    payload: err,
  };
}

export function approvePendingRequestAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    // console.log(state);
    dispatch(approvePendingRequestRequest());
    return getRequestsApi
      .postApprovePendingRequest(state, params)
      .then(data => {
        dispatch(approvePendingRequestSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(approvePendingRequestError(error));
        throw error;
        // return response;
      });
  };
}

export default { approvePendingRequestAction };
