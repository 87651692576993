import {
  DUTCH_API_CALL_WIZARD_API_SUCCESS,
  DUTCH_API_CALL_WIZARD_API_ERROR,
} from '../../../constants/wizardActionNames';
import {
  filterConditions,
  makeRequestBody,
} from '../../../componentsV2/wizards/functions';

import { getMotorPremiumWizardApi } from '../../../apis/wizards';

function dutchApiCallWizardError(err, savedKey) {
  return {
    type: DUTCH_API_CALL_WIZARD_API_ERROR,
    payload: err,
    savedKey,
  };
}
function dutchApiCallWizardSuccess(prod, savedKey) {
  return {
    type: DUTCH_API_CALL_WIZARD_API_SUCCESS,
    payload: prod,
    savedKey,
  };
}

export function getPremiumAction(requestParams, savedKey, currResponse, requestUrl) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const data = await getMotorPremiumWizardApi.getPremuim(
        state,
        {
          ...makeRequestBody(
            state,
            requestParams.filter(param => filterConditions(param, state)),
            currResponse,
          ),
        },
        requestUrl,
      );
      dispatch(dutchApiCallWizardSuccess(data, savedKey));
      return data;
    } catch (error) {
      dispatch(dutchApiCallWizardError(error, savedKey));
      throw error;
    }
  };
}

export default {
  getPremiumAction,
};
