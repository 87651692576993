import { useSelector } from 'react-redux';
import {
  GET_PENDING_CLAIM_API_REQUEST,
  GET_PENDING_CLAIM_API_SUCCESS,
  GET_PENDING_CLAIM_API_ERROR,
  GET_NEXT_PENDING_CLAIM_SUCCESS,
} from '../../../constants/pendingClaimActionNames';
import { agentType } from '../../../utils/variables';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import getPendingClaimApi, {
  getPendingClaimUrl,
} from '../../../apis/claims/getPendingClaimApi';

function getPendingClaimRequest(body) {
  return {
    type: GET_PENDING_CLAIM_API_REQUEST,
    payload: true,
    body,
  };
}

function getPendingClaimSuccess(claims, body, cacheKey, lobTypes) {
  return {
    type: GET_PENDING_CLAIM_API_SUCCESS,
    payload: claims,
    body,
    lobTypes,
    cacheKey,
  };
}

function getPendingClaimError(err, body) {
  return {
    type: GET_PENDING_CLAIM_API_ERROR,
    payload: err,
    body,
  };
}

export function getPendingClaimAction(params, body) {
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    const usertype = state?.auth?.usertype;
    let filteredLobCategories = [];
    if (usertype === agentType) {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForAgentClaims);
    } else {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForCustomerClaims);
    }

    const cacheParams = {
      ...params,
      infiniteScroll: body?.infiniteScroll,
      alpha2: body?.alpha2,
    };
    const cacheKey = generateCacheKey({
      path: getPendingClaimUrl,
      cacheParams,
      state,
    });

    const { isCacheValid } = checkCacheValid(getState, 'partiallyFilledClaims', cacheKey);

    if (isCacheValid) {
      return Promise.resolve(null);
    }

    dispatch(getPendingClaimRequest(body));
    return getPendingClaimApi
      .getPendingClaim(state, params)
      .then(data => {
        dispatch(getPendingClaimSuccess(data, body, cacheKey, filteredLobCategories));
        return data;
      })
      .catch(error => {
        dispatch(getPendingClaimError(error, body));
        throw error;
      });
  };
}

function getNextPendingClaimSuccess(prod) {
  return {
    type: GET_NEXT_PENDING_CLAIM_SUCCESS,
    payload: prod,
  };
}

export function getNextPendingClaimAction(data) {
  return getNextPendingClaimSuccess(data);
}

export default { getPendingClaimAction, getNextPendingClaimAction };
