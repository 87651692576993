import { toastrStyles } from '../../styles';

const styles = theme => ({
  ...toastrStyles,
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
  },
  closeButton: {
    '&:hover': {
      color: theme.palette.error.main
    }
  },
  scanLabel: {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.2rem'
  },

  buttonText: {
    marginTop: theme.spacing(2),
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.3rem'
  },
  actions: {
    padding: '0 15px 15px 15px'
  },
  informationText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.6rem',
    fontWeight: '400',
    lineHeight: '0.6rem',
    color: theme.palette.primary.main
  },
  socialMediaIcons: {
    width: '2rem',
    height: '2rem',
    cursor: 'pointer'
  },
});

export default styles;
