import {
  GET_PENDING_CLAIMS_API_REQUEST,
  GET_PENDING_CLAIMS_API_SUCCESS,
  GET_PENDING_CLAIMS_API_ERROR,
  GET_NEXT_PENDING_CLAIMS_SUCCESS,
  UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY,
} from '../../../constants/pendingClaimActionNames';
import { agentType } from '../../../utils/variables';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import getPendingClaimsApi, {
  getPendingClaimsUrl,
} from '../../../apis/claims/getPendingClaimsApi';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getPendingClaimsRequest(body) {
  return {
    type: GET_PENDING_CLAIMS_API_REQUEST,
    payload: true,
    body,
  };
}

function getPendingClaimsSuccess(claims, body, cacheKey, lobTypes) {
  return {
    type: GET_PENDING_CLAIMS_API_SUCCESS,
    payload: claims,
    body,
    lobTypes,
    cacheKey,
  };
}

function getPendingClaimsError(err, body) {
  return {
    type: GET_PENDING_CLAIMS_API_ERROR,
    payload: err,
    body,
  };
}

export function getPendingClaimsAction(params, body) {
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    const usertype = state?.auth?.usertype;
    let filteredLobCategories = [];
    if (usertype === agentType) {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForAgentClaims);
    } else {
      filteredLobCategories = lobTypes.filter(x => x.isAvailableForCustomerClaims);
    }

    const cacheParams = {
      ...params,
      infiniteScroll: body?.infiniteScroll,
      alpha2: body?.alpha2,
    };
    const cacheKey = generateCacheKey({
      path: getPendingClaimsUrl,
      cacheParams,
      state,
    });

    const { isCacheValid, cache } = checkCacheValid(
      getState,
      'partiallyFilledClaims',
      cacheKey,
    );

    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(getPendingClaimsSuccess(cache, body, cacheKey, filteredLobCategories));
      return Promise.resolve(cache);
    }

    dispatch(getPendingClaimsRequest(body));
    return getPendingClaimsApi
      .getPendingClaims(state, params)
      .then(data => {
        dispatch(getPendingClaimsSuccess(data, body, cacheKey, filteredLobCategories));
        return data;
      })
      .catch(error => {
        dispatch(getPendingClaimsError(error, body));
        throw error;
      });
  };
}

function getNextPendingClaimsSuccess(prod) {
  return {
    type: GET_NEXT_PENDING_CLAIMS_SUCCESS,
    payload: prod,
  };
}

export function getNextPendingClaimsAction(data) {
  return getNextPendingClaimsSuccess(data);
}

export default { getPendingClaimsAction, getNextPendingClaimsAction };
