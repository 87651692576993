import {
  GET_REGISTERED_PRECERTS_SUCCESS,
  GET_REGISTERED_PRECERTS_ERROR,
  GET_REGISTERED_PRECERTS_REQUEST,
  UPDATE_PRECERTS_CURRENT_CACHE_KEY,
} from '../../constants/preCertActionNames';
import { statusOk } from '../../utils/variables';
import { DEFAULT_CACHE_KEY, generateCacheTTL } from '../../utils/functions/cache';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  isDetailsPending: false,
  isDetailsError: false,
  isDetailsSuccess: false,
  isAddPrecertError: false,
  isAddPrecertSuccess: false,
  isAddPrecertPending: false,
  response: null,
  error: null,
  allRows: [],
  details: null,
  identifier: null,
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys: [],
  total: {},
  totalRows: 0,
  [DEFAULT_CACHE_KEY]: null,
  currentCacheKey: '',
};

function registeredPrecertsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REGISTERED_PRECERTS_REQUEST: {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        isPending: true,
      };
    }
    case GET_REGISTERED_PRECERTS_SUCCESS: {
      const lobTypes = action?.lobTypes;
      const rowPayload = action?.payload?.payload?.results || [];
      const newRows = rowPayload;
      const body = action?.body;
      const cacheKey = action?.cacheKey;
      // console.log('body: ', body);
      const newTotal = action?.payload?.payload?.total || 0;
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;

      const nextStartIndex = body?.nextStartIndex;
      const nextScrollCount = body?.nextScrollCount;
      const nextItemCount = body?.nextItemCount;

      const count = typeof state?.count === 'object' ? { ...state?.count } : {};
      count[cacheKey] = newTotal;

      return {
        ...state,
        total: newTotal,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        searchKeys: [],
        allRows: newRows,
        count,
        currentCacheKey: cacheKey,
        [DEFAULT_CACHE_KEY]: {
          ...(state[DEFAULT_CACHE_KEY] || {}),
          [cacheKey]: {
            time: generateCacheTTL(),
            payload: response,
          },
        },
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }
    case GET_REGISTERED_PRECERTS_ERROR: {
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isPending: false,
        error: action?.payload,
      };
    }

    case UPDATE_PRECERTS_CURRENT_CACHE_KEY:
      return {
        ...state,
        currentCacheKey: action?.payload,
      };
    default:
      return state;
  }
}

export default registeredPrecertsReducer;
