import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Icon,
  Box,
  DialogContent,
  IconButton,
  Typography,
  Divider,
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'absolute',
    color: theme.palette.primary.main,
    fontWeight: '900',
    // margin: '8px',
    cursor: 'pointer',
    right: 20,
    top: 20,
    '&:hover': {
      transform: 'Scale(1.05)',
    },
  },
  dialogBox: {
    border: '1rem solid transparent',
    // position: 'relative',
    width: 'fit-content',
    margin: '0px',
    padding: '0rem',
  },
  dialogContent: {
    width: '100% !important',
  },
  title: {
    fontWeight: 500,
  },
}));

function DutchCustomTooltip({
  title,
  content,
  style = {},
  fontSize = 'small',
  template,
  customIcon,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = iconName => {
    const icons = {
      helpIcon: HelpIcon,
      infoIcon: InfoIcon,
      helpOutlineIcon: HelpOutlineIcon,
    };
    return icons[iconName];
  };

  let SelectedIcon = HelpOutlineIcon;
  if (template) {
    if (customIcon) SelectedIcon = getIcon(customIcon);
  }

  return (
    <span>
      <Box sx={{ display: 'inline-block' }}>
        <IconButton aria-label="help" size="medium">
          {SelectedIcon && (
            <SelectedIcon
              fontSize={fontSize}
              color={'primary'}
              sx={
                style || {
                  width: '1.25rem',
                  height: '1.25rem',
                }
              }
              onClick={() => {
                handleClickOpen();
              }}
            />
          )}
        </IconButton>
      </Box>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
      // PaperProps={{ sx: { margin: '5px' } }}
      >
        <DialogContent className={classes.dialogBox}>
          {/* Dialog close button  */}
          <Grid className={classes.dialogContent} container>
            <Grid xs={12} item>
              <Typography
                align="left"
                sx={{ display: 'inline-block', fontWeight: 600 }}
                color="primary"
                variant="h6"
              >
                Explanation
              </Typography>
              <Icon align="right" onClick={handleClose} className={classes.closeIcon}>
                close
              </Icon>
              <Divider sx={{ width: '100%', margin: '1rem 0rem' }} />
            </Grid>
            <Grid item xs={12}>
              {title && (
                <Typography
                  className={classes.title}
                  color="primary"
                  variant="p"
                  component={title.includes('<') ? 'div' : 'p'}
                >
                  {title.includes('<') ? (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    title
                  )}
                </Typography>
              )}
              {content && (
                <Typography color="primary" variant="p">
                  {content}
                </Typography>
              )}
            </Grid>
            <Grid xs={12}>
              <Divider sx={{ width: '100%', margin: '1rem 0rem' }} />
            </Grid>
            <Grid container xs={12} justifyContent="flex-end">
              <Button
                onClick={handleClose}
                variant="outlined"
                color="primary"
                sx={{ width: 'fit-content' }}
              >
                Understood!
              </Button>
            </Grid>
          </Grid>
          {/* Dialog content  */}
        </DialogContent>
      </Dialog>
    </span>
  );
}

export default DutchCustomTooltip;
