import {
    SEND_QUOTE_DETAILS_TO_CLIENT_REQUEST,
    SEND_QUOTE_DETAILS_TO_CLIENT_SUCCESS,
    SEND_QUOTE_DETAILS_TO_CLIENT_ERROR
} from '../../../constants/quoteActionNames';

import { sendQuoteDetailsToClientApi } from '../../../apis/quotes/index';

function sendQuoteDetailsToClientRequest() {
    return {
        type: SEND_QUOTE_DETAILS_TO_CLIENT_REQUEST,
        payload: true,
    };
}

function sendQuoteDetailsToClientSuccess(quotes, params) {
    return {
        type: SEND_QUOTE_DETAILS_TO_CLIENT_SUCCESS,
        payload: params
    };
}

function sendQuoteDetailsToClientError(err) {
    return {
        type: SEND_QUOTE_DETAILS_TO_CLIENT_ERROR,
        payload: err,
    };
}

export function sendQuoteDetailsToClientAction(params) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(sendQuoteDetailsToClientRequest());
        return sendQuoteDetailsToClientApi.sendQuoteDetails(state, params)
            .then(data => {
                dispatch(sendQuoteDetailsToClientSuccess(data, params));
                return data;
            })
            .catch(error => {
                dispatch(sendQuoteDetailsToClientError(error, params));
                throw error;
            });
    };
}

export default { sendQuoteDetailsToClientAction };
