import {
    DECLINE_QUOTES_API_REQUEST,
    DECLINE_QUOTES_API_SUCCESS,
    DECLINE_QUOTES_API_ERROR,
    UPDATE_QUOTES_TOTAL,
    UPDATE_QUOTES_STATUS,
} from '../../../constants/quoteActionNames';

import { declineQuotesApi } from '../../../apis/quotes/index';

function declineQuotesRequest() {
    return {
        type: DECLINE_QUOTES_API_REQUEST,
        payload: true,
    };
}

function declineQuotesSuccess(quotes, params) {
    return {
        type: DECLINE_QUOTES_API_SUCCESS,
        payload: params
    };
}

function declineQuotesTotal(params, cacheKey, lobTypes) {
    return {
        type: UPDATE_QUOTES_TOTAL,
        payload: [],
        params,
        lobTypes,
        cacheKey,
    };
}

function declineQuotesError(err) {
    return {
        type: DECLINE_QUOTES_API_ERROR,
        payload: err,
    };
}

export function declineQuotesAction(params) {
    // const response = getPayloadResponse();
    return (dispatch, getState) => {
        const state = getState();

        dispatch(declineQuotesRequest());
        return declineQuotesApi.declineQuotes(state, params)
            .then(data => {
                dispatch(declineQuotesSuccess(data, params));
                return data;
            })
            .catch(error => {
                dispatch(declineQuotesError(error, params));
                throw error;
            });
    };
}

export default { declineQuotesAction };
