import { initialState as auth } from './auth';
import { initialState as locations } from './mainLayout/location';
import { initialState as account } from './account';
import { initialState as products } from './products';
import { initialState as claims } from './claims';
import { initialState as insurance } from './portfolio/insurance';
import { initialState as cart } from './cart';
import { initialState as receipts } from './receipts';
import { initialState as investments } from './portfolio/investments';
import { initialState as transactions } from './portfolio/investmentTransactions';
import { initialState as profile } from './profile';
import { initialState as notifications } from './notifications';
import { initialState as wizards } from './wizards';
import { initialState as renewals } from './renewals';
import { initialState as quotes } from './quotes';
import { initialState as clients } from './clients';
import { initialState as reports } from './reports';
import { initialState as clientPolicies } from './clientspolicies';
import { initialState as requests } from './requests';
import { initialState as insuredItems } from './insuredItems';
import { initialState as misc } from './misc';
import { initialState as disk } from './disk';
import { initialState as liveChat } from './liveChat';
import { initialState as chatSDK } from './chatSDK';
import { initialState as helpCenter } from './helpCenter';
import { initialState as batchClaims } from './batchClaims';
import { initialState as pendingPrecert } from './precerts/pendingPrecerts';
import { initialState as registeredPrecert } from './precerts/registeredPrecerts';
import { initialState as recurringPayments } from './recurringPayments';
import { initialState as paymentCards } from './paymentCards';
import { initialState as pendingRequests } from './pendingRequests';
import { initialState as newRequests } from './newRequests';

export default {
  account,
  auth,
  profile,
  misc,
  locations,
  products,
  insurance,
  renewals,
  cart,
  receipts,
  insuredItems,
  claims,
  partiallyFilledClaims: claims,
  quotes,
  requests,
  investments,
  transactions,
  wizards,
  clients,
  reports,
  clientPolicies,
  notifications,
  liveChat,
  chatSDK,
  disk,
  helpCenter,
  batchClaims,
  pendingPrecert,
  registeredPrecert,
  recurringPayments,
  paymentCards,
  pendingRequests,
  newRequests,
};
