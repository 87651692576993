import {
    QUOTATION_EMAIL_REQUEST,
    QUOTATION_EMAIL_REQUEST_SUCCESS,
    QUOTATION_EMAIL_REQUEST_ERROR
} from '../../../constants/quoteActionNames';
import { getQuotationEmailApi } from '../../../apis/quotes';


function getQuotationEmailRequest() {
    return {
        type: QUOTATION_EMAIL_REQUEST,
        payload: true,
    };
}

function getQuotationEmailRequestSuccess(data) {
    return {
        type: QUOTATION_EMAIL_REQUEST_SUCCESS,
        payload: data,
    };
}

function getQuotationEmailRequestError(err) {
    return {
        type: QUOTATION_EMAIL_REQUEST_ERROR,
        payload: err,
    };
}


export function getQuotationEmailRequestAction(params) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(getQuotationEmailRequest());
        return getQuotationEmailApi.getQuotationEmail(state, params)
            .then(data => {
                dispatch(getQuotationEmailRequestSuccess(data));
                return data;
            })
            .catch(error => {
                dispatch(getQuotationEmailRequestError(error));
                throw error;
            });
    };
}

export default { getQuotationEmailRequestAction };
