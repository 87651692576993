import React, { useRef, useEffect } from 'react';

const useDebounce = (callbackFunction, delayTime) => {

    const timeOutRef = useRef(null);

    // cleaning up previous renders
    useEffect(() => {
        return () => {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current)
            }
        }
    }, []);

    const debounceCallBack = (...params) => {
        if (timeOutRef.current) {
            clearTimeout(timeOutRef.current)
        }

        timeOutRef.current = setTimeout(() => {
            callbackFunction(...params)
        }, delayTime)
    }

    return debounceCallBack
}

export default useDebounce