import {
  UPDATE_NEW_REQUESTS_CURRENT_CACHE_KEY,
  GET_NEW_REQUESTS_API_REQUEST,
  GET_NEW_REQUESTS_API_SUCCESS,
  GET_NEW_REQUESTS_API_ERROR,
  GET_NEXT_NEW_REQUESTS_SUCCESS,
} from '../../../constants/newRequestActionNames';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getPendingRequestsUrl } from '../../../apis/pendingRequests/getPendingRequestsApi';
import { getPendingRequestsApi } from '../../../apis/pendingRequests';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_NEW_REQUESTS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getNewRequestsRequest(body) {
  return {
    type: GET_NEW_REQUESTS_API_REQUEST,
    payload: true,
    body,
  };
}

function getNewRequestsSuccess(newRequests, params, body, cacheKey, lobTypes) {
  return {
    type: GET_NEW_REQUESTS_API_SUCCESS,
    payload: newRequests,
    params,
    cacheKey,
    lobTypes,
    body,
  };
}

function getNewRequestsError(err, body) {
  return {
    type: GET_NEW_REQUESTS_API_ERROR,
    payload: err,
    body,
  };
}

export function getNewRequestsAction(params, body) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const cacheKey = generateCacheKey({
      path: getPendingRequestsUrl,
      cacheParams: params,
      state,
    });
    const lobTypes = state?.locations?.lobCategories;
    const { isCacheValid, cache } = checkCacheValid(getState, 'newRequests', cacheKey);
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(getNewRequestsSuccess(cache, params, body, cacheKey, lobTypes));
      return Promise.resolve(cache);
    }
    dispatch(getNewRequestsRequest(body));
    return getPendingRequestsApi
      .getPendingRequests(state, params)
      .then(data => {
        dispatch(getNewRequestsSuccess(data, params, body, cacheKey, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getNewRequestsError(error, body));
        throw error;
        // return response;
      });
  };
}

function getNextNewRequestsSuccess(prod) {
  return {
    type: GET_NEXT_NEW_REQUESTS_SUCCESS,
    payload: prod,
  };
}

export function getNextNewRequestsAction(data) {
  return getNextNewRequestsSuccess(data);
}

export default { getNewRequestsAction, getNextNewRequestsAction };
