import { dateToString, isoStringToDate } from '../../dateManipulation';
import routes from '../../../variables/routes';
import {
  motorLob,
  homeLob,
  motorAccidentType,
  thirdPartyType,
  windscreenType,
  keyReplacementType,
  healthLob,
} from '../../../variables';
import { getStepIndex } from '..';
// import stepIndexMappingObject from './stepIndexMapping';

// function to generate url
export const createClaimUrl = (response, newClaim) => {
  const { _id: c360ClaimId, claim, stage } = response;
  const lob = claim?.type;
  const subtype = claim?.subtype;
  let url;

  if (lob === motorLob) {
    let motorDomain = routes.wizards.motorClaim;
    if (subtype === motorAccidentType) motorDomain = routes.wizards.motorAccidentClaim;
    if (subtype === thirdPartyType) motorDomain = routes.wizards.thirdPartyClaim;
    if (subtype === windscreenType) motorDomain = routes.wizards.windscreenClaim;
    if (subtype === keyReplacementType) motorDomain = routes.wizards.keyReplacementClaim;
    if (newClaim) {
      url = `${motorDomain}`;
    } else if (c360ClaimId) {
      url = `${motorDomain}?c360ClaimId=${c360ClaimId}`;
    } else url = `${motorDomain}`;
  } else if (lob === homeLob) {
    if (newClaim) {
      url = `${routes.wizards.homeClaim}`;
    } else if (c360ClaimId) {
      url = `${routes.wizards.homeClaim}?c360ClaimId=${c360ClaimId}`;
    } else url = `${routes.wizards.homeClaim}`;
  } else if (lob === healthLob) {
    if (stage === 'ClaimantDetails') {
      url = `${routes.healthClaims.newClaims}?stepIndex=0&c360ClaimId=${c360ClaimId}`;
    } else if (stage === 'UploadDocuments') {
      url = `${routes.healthClaims.newClaims}?stepIndex=1&c360ClaimId=${c360ClaimId}`;
    } else if (stage === 'ReviewAndSubmit') {
      url = `${routes.healthClaims.newClaims}?stepIndex=2&c360ClaimId=${c360ClaimId}`;
    } else if (stage === 'TransmittalDetails') {
      url = `${routes.healthClaims.fnolClaims}?stepIndex=0&c360ClaimId=${c360ClaimId}`;
    } else if (stage === 'UploadBatchDocuments') {
      url = `${routes.healthClaims.fnolClaims}?stepIndex=1&c360ClaimId=${c360ClaimId}`;
    } else if (stage === 'ReviewAndSubmitBatch') {
      url = `${routes.healthClaims.fnolClaims}?stepIndex=2&c360ClaimId=${c360ClaimId}`;
    }
  }
  return url;
};

// main function to map partial claim details
export const createPartialClaim = (wizard, response) => {
  // getting stepIndex
  const stepIndex = wizard ? getStepIndex(wizard, response)?.toString() : '0';
  if (response?.claim?.type === motorLob) {
    const draft = {
      ...motorClaimResponseMapping(response),
      stepIndex,
    };
    return draft;
  }
  if (response?.claim?.type === homeLob) {
    const draft = {
      ...homeClaimResponseMapping(response),
      stepIndex,
    };
    // console.log('draft: ', draft);
    return draft;
  }
};

// sub-functions used for motor mapping
export const getAddress = (addresses, type) => {
  let addressObj;
  if (addresses) {
    addresses?.forEach(address => {
      if (address.type === type) {
        addressObj = {
          addressLine1: address?.addressLine1,
          addressLine2: address?.addressLine2,
          addressLine3: address?.addressLine3,
        };
      }
    });
  }
  return addressObj;
};
export const createWitnessData = data => {
  let witnessData;
  if (data) {
    witnessData = data.map(witness => {
      return {
        witnessFirstName: witness?.firstName,
        witnessSurname: witness?.lastName,
        witnessPrimaryContactNo: getContactNumberDetails(witness?.contactNumbers, true),
        witnessSecondaryContactNo: getContactNumberDetails(
          witness?.contactNumbers,
          false,
        ),
        witnessExtension: getContactNumberDetails(witness?.contactNumbers, false, true),
        witnessEmail: witness?.emailAddress,
        witnessOccupation: witness?.occupation,
        witnessResidentialAddress: getAddress(witness?.addresses, 'Residential'),
        witnessBusinessAddress: getAddress(witness?.addresses, 'Business'),
      };
    });
  }
  return witnessData;
};
export const getContactNumberDetails = (
  contactsArray,
  isPrimary = false,
  isExtension,
  type = 'Mobile',
) => {
  let number;
  if (contactsArray && Array.isArray(contactsArray)) {
    if (isPrimary) {
      number = contactsArray?.find(contact => contact?.isPrimary);
    } else if (type) {
      number = contactsArray?.find(contact => contact?.type === type);
    }
  } else if (contactsArray) {
    number = contactsArray;
  }
  if (isExtension) {
    return number?.extension;
  }
  return number?.contactNumber;
};
export const createInjuredPersons = (data, claimType) => {
  let injuredPersons;
  if (data) {
    if (claimType === 'Third Party') {
      injuredPersons = data?.map(injuredPerson => {
        return {
          whichVehicleInjuredBelongedToThirdParty: injuredPerson?.personInvolved?.owner,
          roleOfInjuredPerson: injuredPerson?.role,
          roleOfInjuredPersonOther: injuredPerson?.other,
          injuredFirstName: injuredPerson?.firstName,
          injuredSurname: injuredPerson?.lastName,
          injuredSex: injuredPerson?.sex,
          injuredDob: injuredPerson?.dateOfBirth,
          injuredPrimaryContactNo: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            true,
          ),
          injuredSecondaryContactNo: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            false,
          ),
          injuredExtension: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            false,
            true,
          ),
          injuredEmail: injuredPerson?.emailAddress,
          injuredOccupation: injuredPerson?.occupation,
          injuredResidentialAddress: getAddress(injuredPerson?.addresses, 'Residential'),
          injuredBusinessAddress: getAddress(injuredPerson?.addresses, 'Business'),
          natureAndExtentOfInjuries: injuredPerson?.injuries?.natureAndExtent,
          attendingPhysicianHospital: injuredPerson?.injuries?.attendingPhysician,
        };
      });
    }
    if (claimType === 'Motor Accident') {
      injuredPersons = data?.map(injuredPerson => {
        return {
          whichVehicleInjuredBelongedTo: injuredPerson?.personInvolved?.owner,
          roleOfInjuredPerson: injuredPerson?.role,
          roleOfInjuredPersonOther: injuredPerson?.other,
          injuredFirstName: injuredPerson?.firstName,
          injuredSurname: injuredPerson?.lastName,
          injuredSex: injuredPerson?.sex,
          injuredDob: injuredPerson?.dateOfBirth,
          injuredPrimaryContactNo: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            true,
          ),
          injuredSecondaryContactNo: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            false,
          ),
          injuredExtension: getContactNumberDetails(
            injuredPerson?.contactNumbers,
            false,
            true,
          ),
          injuredEmail: injuredPerson?.emailAddress,
          injuredOccupation: injuredPerson?.occupation,
          injuredResidentialAddress: getAddress(injuredPerson?.addresses, 'Residential'),
          injuredBusinessAddress: getAddress(injuredPerson?.addresses, 'Business'),
          natureAndExtentOfInjuries: injuredPerson?.injuries?.natureAndExtent,
          attendingPhysicianHospital: injuredPerson?.injuries?.attendingPhysician,
        };
      });
    }
  }
  return injuredPersons;
};
export const createThirdPartyDamage = (thirdPartyDamage, claimType) => {
  let thirdPartyObj;
  if (thirdPartyDamage) {
    if (claimType && claimType === 'Windscreen') {
      thirdPartyObj = thirdPartyDamage?.map(data => {
        return {
          tpDriverSameAsInsured: data?.wasOwnerDriving ? 'Yes' : 'No',
          tpOwnerFirstName: data?.owner?.firstName,
          tpOwnerSurname: data?.owner?.lastName,
          tpOwnerAddress: {
            addressLine1: data?.owner?.address?.addressLine1,
            addressLine2: data?.owner?.address?.addressLine1,
            addressLine3: data?.owner?.address?.addressLine1,
          },
          tpOwnerEmail: data?.owner?.emailAddress,
          tpOwnerVehicleRegNo: data?.vehicle?.registrationNumber,
          tpOwnerVehicleMake: data?.vehicle?.make,
          tpOwnerVehicleModel: data?.vehicle?.model,
          tpOwnerVehicleYearOfManufacture: data?.vehicle?.yearOfManufacture,
          tpOwnerYearOfManufacture: data?.vehicle?.yearOfManufacture,
          tpNameOfInsurer: data?.vehicle?.insuranceCompany,
          tpTypeOfCoverage: data?.vehicle?.coverageType,
          tpDriverFirstName: data?.driver?.firstName,
          tpDriverSurname: data?.driver?.lastName,
          tpDriversPermit: {
            dateOfRenewal: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfRenewal),
            ),
            dateOfExpiry: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfExpiry),
            ),
            dateOfFirstIssue: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfFirstIssue),
            ),
            permitNumber: data?.driversPermit?.permitNumber,
            allowedVehicleClass: data?.driversPermit?.allowedVehicleClass,
          },
          tpDriverPrimaryContact: getContactNumberDetails(
            data?.driver?.contactNumbers,
            true,
          ),
          tpDriverSecondaryContact: getContactNumberDetails(
            data?.driver?.contactNumbers,
            false,
          ),
          tpDriverExtension: getContactNumberDetails(
            data?.driver?.contactNumbers,
            true,
            true,
          ),
          tpDriverEmail: data?.driver?.emailAddress,
          tpNameOfInsurer: data?.insurance?.insuranceCompany,
          tpTypeOfCoverage: data?.insurance?.coverageType,
          tpPolicyNumber: data?.insurance?.policyNumber,
          tpPolicyExpiryDate: data?.insurance?.dateOfExpiry,
          tpDriverDob: data?.driver?.dateOfBirth,
          tpDriverAddress: {
            addressLine1: data?.driver?.address?.addressLine1,
            addressLine2: data?.driver?.address?.addressLine1,
            addressLine3: data?.driver?.address?.addressLine1,
          },
          // tpDriverPrimaryContact: data?.driver?.contactNumbers[0]?.contactNumber,
          // tpDriverSecondaryContact: data?.driver?.contactNumbers[1]?.contactNumber,
          // tpDriverExtension: data?.driver?.contactNumbers[1]?.extension,
          tpOwnerPrimaryContact: getContactNumberDetails(
            data?.owner?.contactNumbers,
            true,
          ),
          tpOwnerSecondaryContact: getContactNumberDetails(
            data?.owner?.contactNumbers,
            false,
          ),
          tpOwnerExtension: getContactNumberDetails(
            data?.owner?.contactNumbers,
            false,
            true,
          ),
        };
      });
    } else {
      thirdPartyObj = thirdPartyDamage?.map(data => {
        return {
          tpOwnerFirstName: data?.owner?.firstName,
          tpOwnerSurname: data?.owner?.lastName,
          tpOwnerAddress: {
            addressLine1: data?.owner?.address?.addressLine1,
            addressLine2: data?.owner?.address?.addressLine1,
            addressLine3: data?.owner?.address?.addressLine1,
          },
          tpOwnerEmail: data?.owner?.emailAddress,
          tpOwnerVehicleRegNo: data?.vehicle?.registrationNumber,
          tpOwnerVehicleMake: data?.vehicle?.make,
          tpOwnerVehicleModel: data?.vehicle?.model,
          tpOwnerVehicleYearOfManufacture: data?.vehicle?.yearOfManufacture,
          tpOwnerYearOfManufacture: data?.vehicle?.yearOfManufacture,
          tpNameOfInsurer: data?.vehicle?.insuranceCompany,
          tpTypeOfCoverage: data?.vehicle?.coverageType,
          tpDriverFirstName: data?.driver?.firstName,
          tpDriverSurname: data?.driver?.lastName,
          tpDriversPermit: {
            dateOfRenewal: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfRenewal),
            ),
            dateOfExpiry: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfExpiry),
            ),
            dateOfFirstIssue: dateToString(
              isoStringToDate(data?.driversPermit?.dateOfFirstIssue),
            ),
            permitNumber: data?.driversPermit?.permitNumber,
            allowedVehicleClass: data?.driversPermit?.allowedVehicleClass,
          },
          tpDriverPrimaryContact: getContactNumberDetails(
            data?.driver?.contactNumbers,
            true,
          ),
          tpDriverSecondaryContact: getContactNumberDetails(
            data?.driver?.contactNumbers,
            false,
          ),
          tpDriverExtension: getContactNumberDetails(
            data?.driver?.contactNumbers,
            true,
            true,
          ),
          tpDriverEmail: data?.driver?.emailAddress,
          tpNameOfInsurer: data?.insurance?.insuranceCompany,
          tpTypeOfCoverage: data?.insurance?.coverageType,
          tpPolicyNumber: data?.insurance?.policyNumber,
          tpPolicyExpiryDate: data?.insurance?.dateOfExpiry,
          tpDriverDob: data?.driver?.dateOfBirth,
          tpDriverAddress: {
            addressLine1: data?.driver?.address?.addressLine1,
            addressLine2: data?.driver?.address?.addressLine1,
            addressLine3: data?.driver?.address?.addressLine1,
          },
          // tpDriverPrimaryContact: data?.driver?.contactNumbers[0]?.contactNumber,
          // tpDriverSecondaryContact: data?.driver?.contactNumbers[1]?.contactNumber,
          // tpDriverExtension: data?.driver?.contactNumbers[1]?.extension,
          tpOwnerPrimaryContact: getContactNumberDetails(
            data?.owner?.contactNumbers,
            true,
          ),
          tpOwnerSecondaryContact: getContactNumberDetails(
            data?.owner?.contactNumbers,
            false,
          ),
          tpOwnerExtension: getContactNumberDetails(
            data?.owner?.contactNumbers,
            false,
            true,
          ),
        };
      });
    }
  }
  return thirdPartyObj;
};
export const createUploadedFiles = data => {
  let uploadedFiles;
  uploadedFiles = data?.map((file, index) => {
    return {
      ...file,
      index: index + 1,
      exp: 900,
    };
  });
  return uploadedFiles;
};

// home and motor mapping functions
function homeClaimResponseMapping(doc) {
  const response = {};
  const {
    insured,
    fireAndOtherPerils,
    arrayObjValue,
    loss,
    detailsOfLoss,
    burglaryOrTheft,
    verifiedEmail,
    c360Id,
  } = doc;
  response.arrayParams = [{}];

  // insured
  if (verifiedEmail) {
    response.verify_email = verifiedEmail;
  }
  response.c360ClaimId = c360Id;
  response.propertyPolicyNo = insured?.policyNo;
  response.policyId = insured?.policyNo;
  response.propertyFirstName = insured?.firstName;
  response.propertyLastName = insured?.lastName;
  response.propertyPrimaryContact = getContactNumberDetails(
    insured?.contactNumbers,
    true,
  );
  response.propertySecondaryContact = getContactNumberDetails(
    insured?.contactNumbers,
    false,
  );
  response.propertyExtension = getContactNumberDetails(
    insured?.contactNumbers,
    false,
    true,
  );
  response.propertyEmail = insured?.emailAddress;
  response.pre_email = insured?.emailAddress;
  // particulars of loss step (2nd step)
  response.dateAndTimeOfLoss = loss?.dateTimeOfLoss;
  response.causeOfOccurence = loss?.causeOfLoss;
  response.propertyLocation = loss?.locationOfLoss;
  response.typeOfLossIncur = loss?.typeOfLoss?.map(x => ({ ...x, checked: true }));
  response.contractorEstimate = loss?.contractorEstimate;
  response.purposeOfUseAtOccurrence = loss?.particularsOfUse?.purpose;
  response.werePremisesUsedAtTimeOfLoss = loss?.particularsOfUse?.wasPremiseOccupied;
  response.dateAndTimeLastOccupied = loss?.particularsOfUse?.dateTimeLastOccupied;
  response.lossReportedToAuthorities = loss?.wasLossReported;
  response.dateOfReport = loss?.report?.dateOfReport;
  response.fireStation = loss?.report?.fireStation;
  response.policeStation = loss?.report?.policeStation;
  response.soleOwnerOfProperty = loss?.property?.isSoleOwner;
  response.nameOfOtherOwner = loss?.property?.otherOwner?.name;
  response.addressForOtherOwner = loss?.property?.otherOwner?.address;
  response.primaryContactForOtherOwner = getContactNumberDetails(
    loss?.property?.otherOwner?.contactNumbers,
    true,
  );
  response.secondaryContactForOtherOwner = getContactNumberDetails(
    loss?.property?.otherOwner?.contactNumbers,
    false,
    false,
    'Residential',
  );
  response.extensionForOtherOwner = getContactNumberDetails(
    loss?.property?.otherOwner?.contactNumbers,
    false,
    true,
    'Residential',
  );
  response.emailForOtherOwner = loss?.property?.otherOwner?.emailAddress;
  response.anyOtherInsurance = loss?.property?.hadOtherInsurer;
  response.otherInsurer = loss?.property?.otherInsurer?.insuranceCompany;
  response.otherPolicyNumber = loss?.property?.otherInsurer?.policyNumber;
  response.otherWhatIsCoverage = loss?.property?.otherInsurer?.coverage;
  // 3rd step -> this is dynamic based on causeOfOccurence
  // for fire loss and other perils (3rd step)
  response.descriptionWhatHappened = fireAndOtherPerils?.lossDescription;
  response.causeOfDamage = fireAndOtherPerils?.causeOfDamage;
  response.werePremisesSameAsDescribed =
    fireAndOtherPerils?.stateOfPremisesAndOccupancy?.matchesPolicyDescription;
  response.differencesOfPremises =
    fireAndOtherPerils?.stateOfPremisesAndOccupancy?.difference;
  response.alterationInRisk = fireAndOtherPerils?.riskAlteration?.sinceLastIssue;
  response.alterationInRiskDetails = fireAndOtherPerils?.riskAlteration?.details;
  response.previouslySustainedLoss = fireAndOtherPerils?.hadPreviousLoss;
  response.dateOfPreviouslySustainedLoss = fireAndOtherPerils?.lossDetails?.dateOfLoss;
  response.amountOfPreviouslySustainedLoss =
    fireAndOtherPerils?.lossDetails?.amountOfLoss;
  response.particularsOfPreviouslySustainedLoss =
    fireAndOtherPerils?.lossDetails?.briefLossDescription;
  // for theft and bulgary (3rd step)
  response.circumstanceOfLoss = burglaryOrTheft?.circumstancesOfLoss;
  response.howWasEntryGained = burglaryOrTheft?.entryToPremises;
  response.damageToPremisesSustained = burglaryOrTheft?.hadDamageToPremises;
  response.dateAndTimeLossDiscovered =
    burglaryOrTheft?.damageDiscovery?.dateTimeOfLossDiscovered;
  response.whoDiscovered = burglaryOrTheft?.damageDiscovery?.discoveredBy;
  response.dateAndTimeItemLastSeen = burglaryOrTheft?.itemsLastSeen?.dateTimeLastSeen;
  response.whoLastSeen = burglaryOrTheft?.itemsLastSeen?.lastSeenBy;
  response.placeLastSeen = burglaryOrTheft?.itemsLastSeen?.placeLastSeen;
  response.suspectAnyIndividual = burglaryOrTheft?.hasSuspect;
  response.relationshipToInsured = burglaryOrTheft?.suspect?.relationshipToInsured;
  response.thoroughSearchConducted = burglaryOrTheft?.hadSearch;
  response.anyOtherStepsToRecoverItems = burglaryOrTheft?.wereOtherStepsTake;
  response.previouslySustainedLossTheft = burglaryOrTheft?.hadPreviousLoss;
  response.causeOfLoss = burglaryOrTheft?.lossDetails?.causeOfLoss;
  response.detailsOfDamageToPremisesSustained =
    burglaryOrTheft?.damageDiscovery?.detailsOfDamage;
  response.nameOfIndividual = burglaryOrTheft?.suspect?.name;
  response.actionTaken = burglaryOrTheft?.actionTaken;
  response.previousDateOfLoss = burglaryOrTheft?.lossDetails?.dateOfLoss;
  response.previousAmountOfLoss = burglaryOrTheft?.lossDetails?.amountOfLoss;
  response.previousBriefParticulars = burglaryOrTheft?.lossDetails?.briefLossDescription;
  // details of loss
  response.detailsOfLossData = [];
  detailsOfLoss?.forEach(element => {
    const obj = {};
    obj.descriptionOfItem = element?.description;
    obj.quantity = element?.quantity;
    obj.dateOfPurchase = element?.dateOfPurchase;
    obj.amountClaimed = element?.amountClaimed;
    response.detailsOfLossData.push(obj);
  });
  // make boolean values mapping (true -> Yes, false -> No)
  Object.keys(response).forEach(key => {
    const value = response[key];
    if (typeof value === 'boolean') {
      response[key] = value ? 'Yes' : 'No';
    }
  });
  return response;
}
function motorClaimResponseMapping(response) {
  const claim = response?.claim;
  const owner = response?.owner;
  const guardianInsured = response?.guardianInsured;
  const particularsOfVehicleOrProperty = response?.particularsOfVehicleOrProperty;
  const insured = response?.insured;
  const driver = response?.driver;
  const accident = response?.accident;
  const damageToInsuredVehicle = response?.damageToInsuredVehicle;
  const uploadedFiles = response?.uploadedFiles;
  const hadThirdPartyDamage = response?.hadThirdPartyDamage;
  const hadThirdPartyInjury = response?.hadThirdPartyInjury;
  const thirdPartyDamage = response?.thirdPartyDamage;
  const howManyVehiclesInvolved = response?.howManyVehiclesInvolved;
  const otherPropertyDamage = response?.otherPropertyDamage;
  const thirdPartyInjury = response?.thirdPartyInjury;
  const witnesses = response?.witnesses;
  const hadWitnesses = response?.hadWitnesses;
  const numberOfPersonsInjured = response?.numberOfPersonsInjured;

  const responseObject = {};
  if (response?.verifiedEmail) {
    responseObject.verify_email = response?.verifiedEmail;
  }
  responseObject.c360ClaimId = response?.c360Id;
  responseObject.motorClaimType = claim?.subtype;
  if (claim.subtype === 'Motor Accident') {
    if (insured) {
      responseObject.vehicleRegNo = insured?.vehicleRegistrationNumber;
      responseObject.firstName = insured?.firstName;
      responseObject.lastName = insured?.lastName;
      responseObject.primaryContactNumber = getContactNumberDetails(
        insured?.contactNumbers,
        true,
      );
      responseObject.secondaryContactNumber = getContactNumberDetails(
        insured?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.extension = getContactNumberDetails(
        insured?.contactNumbers,
        false,
        true,
        'Residential',
      );
      responseObject.email = insured?.emailAddress;
      responseObject.pre_email = insured?.emailAddress;
      responseObject.individualInstitution = insured?.particularsOfOtherInterest?.name;
      responseObject.mortgageOrHirePurchase = insured?.subjectToMortgageOrHirePurchase
        ? 'Yes'
        : 'No';
      responseObject.mortgageOrHirePurchasePrimaryContact = getContactNumberDetails(
        insured?.particularsOfOtherInterest?.contactNumbers,
        true,
      );
      responseObject.mortgageOrHirePurchaseSecondaryContact = getContactNumberDetails(
        insured?.particularsOfOtherInterest?.contactNumbers,
        false,
      );
      responseObject.mortgageOrHirePurchaseExtension = getContactNumberDetails(
        insured?.particularsOfOtherInterest?.contactNumbers,
        false,
        true,
      );
      responseObject.occupation = insured?.occupation;
      responseObject.employer = insured?.employer;
      responseObject.mortgageOrHirePurchaseEmail =
        insured?.particularsOfOtherInterest?.emailAddress;
      responseObject.mortgageOrHirePurchaseAddress = {
        addressLine1: insured?.particularsOfOtherInterest?.address?.addressLine1,
        addressLine2: insured?.particularsOfOtherInterest?.address?.addressLine2,
        addressLine3: insured?.particularsOfOtherInterest?.address?.addressLine3,
      };
    }
    if (owner) {
      responseObject.occupation = owner?.occupation;
      responseObject.employer = owner?.employer;
    }
    if (driver) {
      responseObject.physicalImpairmentParticulars = driver?.detailsOfImpairment;
      responseObject.physicalImpairment = driver?.hasImpairment ? 'Yes' : 'No';
      responseObject.convictionsOffences = driver?.hasConvictions ? 'Yes' : 'No';
      responseObject.insuredOtherVehicle = driver?.hasOtherVehicle ? 'Yes' : 'No';
      responseObject.convictionsOffencesParticulars = driver?.detailsOfConviction;
      responseObject.refusalOfInsurance = driver?.wasRefusedPastInsurance ? 'Yes' : 'No';
      responseObject.refusalOfInsuranceParticulars = driver?.detailsOfRefusal;
      responseObject.recentAccident = driver?.hadPastAccidents ? 'Yes' : 'No';
      responseObject.recentAccidentDate = driver?.pastAccidents?.dateOfAccident;
      responseObject.recentAccidentRegNo =
        driver?.pastAccidents?.vehicleRegistrationNumber;
      responseObject.recentAccidentInsuranceCompany =
        driver?.pastAccidents?.insuranceCompany;
      responseObject.insuranceCompany = driver?.otherVehicle?.insuranceCompany;
      responseObject.policyNumber = driver?.otherVehicle?.policyNumber;
      responseObject.driversPermit = {
        permitNumber: driver?.driversPermit?.permitNumber,
        dateOfFirstIssue: driver?.driversPermit?.dateOfFirstIssue,
        dateOfExpiry: dateToString(isoStringToDate(driver?.driversPermit?.dateOfExpiry)),
        dateOfRenewal: dateToString(
          isoStringToDate(driver?.driversPermit?.dateOfRenewal),
        ),
        allowedVehicleClass: driver?.driversPermit?.allowedVehicleClass,
      };
      responseObject.driverRelationshipToInsured = driver?.details?.relationshipToInsured;
      responseObject.driverFirstName = driver?.details?.firstName;
      responseObject.driverLastName = driver?.details?.lastName;
      responseObject.driverSex = driver?.details?.sex;
      responseObject.driverDob = driver?.details?.dateOfBirth;
      responseObject.driverInsured = driver?.isTheInsured ? 'Yes' : 'No';
      responseObject.driverPrimaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        true,
      );
      responseObject.driverSecondaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.driverExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.driverBusinessContact = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Business',
      );
      responseObject.driverBusinessExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        true,
        'Business',
      );
      responseObject.driverEmail = driver?.details?.emailAddress;
      if (driver?.details?.addresses) {
        responseObject.driverResidentialAddress = getAddress(
          driver?.details?.addresses,
          'Residential',
        );
        responseObject.driverBusinessAddress = getAddress(
          driver?.details?.addresses,
          'Business',
        );
      }

      responseObject.driverOccupation = driver?.details?.occupation;
      responseObject.driverEmployer = driver?.details?.employer;
    }
    if (accident) {
      responseObject.insuredPermission = accident?.particularsOfUse?.hadInsuredPermission
        ? 'Yes'
        : 'No';
      responseObject.purposeOfUse = accident?.particularsOfUse?.purpose;
      responseObject.purposeOfUseOther = accident?.particularsOfUse?.other;
      responseObject.speedAtAccident = accident?.particularsOfUse?.speed;
      responseObject.dateOfLossDateAndTime = dateToString(
        isoStringToDate(accident?.dateTimeOfLoss),
      );
      responseObject.location = accident?.locationOfLoss;
      responseObject.causeOfLoss = accident?.causeOfLoss;
      responseObject.policeVisitScene = accident?.didPoliceVisitScene ? 'Yes' : 'No';
      responseObject.hadMeasurementsTaken = accident?.hadMeasurementsTaken ? 'Yes' : 'No';
      responseObject.policeStationAccidentReported = accident?.report?.policeStation;
      responseObject.policeOfficerRank = accident?.report?.policeOfficer?.rank?.title;
      responseObject.policeOfficerRankOther =
        accident?.report?.policeOfficer?.rank?.other;
      responseObject.policeOfficerName = accident?.report?.policeOfficer?.name;
      responseObject.policeOfficerNumber = accident?.report?.policeOfficer?.badgeNo;
      responseObject.roadSurfaceCondition = accident?.conditions?.roadSurface?.condition;
      responseObject.roadSurfaceConditionOther = accident?.conditions?.roadSurface?.other;
      responseObject.weatherConditions = accident?.conditions?.weather?.condition;
      responseObject.weatherConditionsOther = accident?.conditions?.other;
      responseObject.warningSignal = accident?.wasWarningGiven ? 'Yes' : 'No';
      responseObject.warningSignalType = accident?.warnings?.type;
      responseObject.warningSignalTypeOther = accident?.warnings?.other;
      responseObject.headlightsTurnedOn = accident?.hadHeadlightsOn ? 'Yes' : 'No';
      responseObject.descriptionOfAccident = accident?.descriptionOfAccident;
      responseObject.werePhotosTakenAtAccident = accident?.werePhotosTaken ? 'Yes' : 'No';
      responseObject.driverCharged = accident?.accountability?.chargedByPolice
        ? 'Yes'
        : 'No';
      responseObject.whoWasAtFault = accident?.accountability?.fault;
      responseObject.other = accident?.accountability?.other;
      responseObject.admitResponsibility = accident?.accountability
        ?.responsibilityAdmitted
        ? 'Yes'
        : 'No';
      responseObject.driverWearingSeatbelt = accident?.accountability?.hadHeadlightsOn
        ? 'Yes'
        : 'No';
      responseObject.thirdPartyDriverUnderInfluence =
        accident?.accountability?.thirdPartyUnderInfluence;
      responseObject.thirdPartyDriverCharged = accident?.accountability
        ?.thirdPartyChargedByPolice
        ? 'Yes'
        : 'No';
      responseObject.antitheftDevice = accident?.accountability?.wasAntitheftDeviceOn
        ? 'Yes'
        : 'No';
      responseObject.driverUnderInfluence = accident?.accountability?.underTheInfluence;
      responseObject.driverUnderInfluenceCharged =
        accident?.accountability?.driverChargedByPolice;
      responseObject.guardianDriverUnderInfluence =
        accident?.accountability?.guardianInsuredUnderInfluence;
    }
    if (damageToInsuredVehicle) {
      responseObject.detailsOfDamage = damageToInsuredVehicle?.detailsOfDamage;
      responseObject.vehicleRemovedByWreckingCompany = damageToInsuredVehicle?.wreckage
        ?.removedByCompany
        ? 'Yes'
        : 'No';
      responseObject.wreckingCompany = damageToInsuredVehicle?.wreckage?.wreckingCompany;
      responseObject.canVehicleDriveToGuardian = damageToInsuredVehicle?.drivability
        ?.canBeDriven
        ? 'Yes'
        : 'No';
      responseObject.whereCanVehicleBeInspected =
        damageToInsuredVehicle?.drivability?.inspectionLocation;
      responseObject.obtainedEstimateForRepairs = damageToInsuredVehicle?.hasRepairEstimate
        ? 'Yes'
        : 'No';
      responseObject.garageEstimate = damageToInsuredVehicle?.repairs?.estimatedAmount;
      responseObject.repairsStarted = damageToInsuredVehicle?.repairs
        ?.repairInstructionsGiven
        ? 'Yes'
        : 'No';
      responseObject.repairsDoneByGgil = damageToInsuredVehicle?.repairs?.useInsurerGarage
        ? 'Yes'
        : 'No';
      responseObject.preferredGarage = damageToInsuredVehicle?.repairs?.preferredGarage;
    }
    responseObject.uploadedFormsMotorAccident = createUploadedFiles(uploadedFiles);
    responseObject.otherVehiclesInvolved = hadThirdPartyDamage ? 'Yes' : 'No';
    responseObject.thirdParties = createThirdPartyDamage(thirdPartyDamage) || [];
    responseObject.thirdPartyData = responseObject.thirdParties && [
      ...responseObject.thirdParties,
    ];
    responseObject.wasAnyoneInjured = hadThirdPartyInjury ? 'Yes' : 'No';
    responseObject.injuredPersons =
      createInjuredPersons(thirdPartyInjury, claim?.subtype) || [];
    responseObject.injuredPersonsData = responseObject.injuredPersons && [
      ...responseObject.injuredPersons,
    ];
    responseObject.anyWitnesses = hadWitnesses ? 'Yes' : 'No';
    responseObject.witnesses = createWitnessData(witnesses) || [];
    responseObject.witnessesData = responseObject.witnesses && [
      ...responseObject.witnesses,
    ];
    responseObject.howManyVehiclesInvolved = howManyVehiclesInvolved;
    responseObject.numberOfPersonsInjured = numberOfPersonsInjured;
    responseObject.otherPropertyDamage = otherPropertyDamage ? 'Yes' : 'No';
  }
  if (claim?.subtype === 'Third Party') {
    if (guardianInsured) {
      responseObject.giFirstName = guardianInsured?.firstName;
      responseObject.giSurname = guardianInsured?.lastName;
      responseObject.giVehicleRegNo = guardianInsured?.vehicleRegistrationNumber;
      responseObject.giDriverFirstName = guardianInsured?.driver?.firstName;
      responseObject.giDriverSurname = guardianInsured?.driver?.lastName;
    }
    if (owner) {
      responseObject.firstName = owner?.firstName;
      responseObject.lastName = owner?.lastName;
      responseObject.employer = owner?.employer;
      responseObject.occupation = owner?.occupation;
      responseObject.vatRegistrationNo = owner?.vatRegistrationNumber;
      responseObject.extension = getContactNumberDetails(
        owner?.contactNumbers,
        false,
        true,
        'Residential',
      );
      responseObject.primaryContact = getContactNumberDetails(
        owner?.contactNumbers,
        true,
      );
      responseObject.secondaryContact = getContactNumberDetails(
        owner?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.email = owner?.emailAddress;
      responseObject.pre_email = owner?.emailAddress;
      responseObject.sex = owner?.sex;
      responseObject.dob = owner?.dateOfBirth;
      responseObject.ownerResidentialAddress = getAddress(
        owner?.addresses,
        'Residential',
      );
      responseObject.ownerBusinessAddress = getAddress(owner?.addresses, 'Business');
    }
    if (particularsOfVehicleOrProperty) {
      responseObject.vehicleRegNo =
        particularsOfVehicleOrProperty?.vehicle?.registrationNumber;
      responseObject.yearOfManufacture =
        particularsOfVehicleOrProperty?.vehicle?.yearOfManufacture;
      responseObject.make = particularsOfVehicleOrProperty?.vehicle?.make;
      responseObject.model = particularsOfVehicleOrProperty?.vehicle?.model;
      responseObject.nameOfInsuranceCompanyForVehicle =
        particularsOfVehicleOrProperty?.vehicle?.insurance?.insuranceCompany;
      responseObject.typeOfCoverage =
        particularsOfVehicleOrProperty?.vehicle?.insurance?.coverageType;
      responseObject.policyNoForVehicle =
        particularsOfVehicleOrProperty?.vehicle?.insurance?.policyNumber;
      responseObject.expiryDateForVehicle = dateToString(
        isoStringToDate(particularsOfVehicleOrProperty?.vehicle?.insurance?.dateOfExpiry),
      );
      responseObject.typeOfAccident = particularsOfVehicleOrProperty?.typeOfAccident;
      responseObject.propertyDamage =
        particularsOfVehicleOrProperty?.property?.propertyDamage;
      responseObject.descOfDamageToOtherProperty =
        particularsOfVehicleOrProperty?.property?.description;
      responseObject.nameOfInsuranceCompanyForProperty =
        particularsOfVehicleOrProperty?.property?.insurance?.insuranceCompany;
      responseObject.policyNoForProperty =
        particularsOfVehicleOrProperty?.property?.insurance?.policyNumber;
      responseObject.expiryDateForProperty = dateToString(
        isoStringToDate(
          particularsOfVehicleOrProperty?.property?.insurance?.dateOfExpiry,
        ),
      );
    }
    if (driver) {
      responseObject.driverFirstName = driver?.details?.firstName;
      responseObject.driverLastName = driver?.details?.lastName;
      responseObject.driverSex = driver?.details?.sex;
      responseObject.driverDob = dateToString(
        isoStringToDate(driver?.details?.dateOfBirth),
      );
      responseObject.driverEmail = driver?.details?.emailAddress;
      responseObject.driverResidentialAddress = getAddress(
        driver?.details?.addresses,
        'Residential',
      );
      responseObject.driverBusinessAddress = getAddress(
        driver?.details?.addresses,
        'Business',
      );
      responseObject.driverOccupation = driver?.details?.occupation;
      responseObject.driverEmployer = driver?.details?.employer;
      responseObject.driverPrimaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        true,
      );
      responseObject.driverBusinessContact = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Business',
      );

      responseObject.driverBusinessExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        true,
        'Business',
      );

      responseObject.driverSecondaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.driverExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        true,
        'Residential',
      );
      responseObject.driverVehicleRegNo = driver?.vehicleRegistrationNumber;
      responseObject.driverInsured = driver?.isTheInsured ? 'Yes' : 'No';
      responseObject.doesDriverOwnVehicle = driver?.hasOwnVehicle ? 'Yes' : 'No';
      responseObject.driversPermit = {
        permitNumber: driver?.driversPermit?.permitNumber,
        dateOfFirstIssue: driver?.driversPermit?.dateOfFirstIssue,
        dateOfExpiry: dateToString(isoStringToDate(driver?.driversPermit?.dateOfExpiry)),
        dateOfRenewal: dateToString(
          isoStringToDate(driver?.driversPermit?.dateOfRenewal),
        ),
        allowedVehicleClass: driver?.driversPermit?.allowedVehicleClass,
      };
    }

    if (accident) {
      responseObject.dateAndTimeOfLoss = accident?.dateTimeOfLoss;
      responseObject.dateOfLossDateAndTime = accident?.dateTimeOfLoss;
      responseObject.location = accident?.locationOfLoss;
      responseObject.causeOfLoss = accident?.causeOfLoss;
      responseObject.howManyOtherVehiclesInvolved = accident?.otherVehiclesInvolved;
      responseObject.didPoliceVisitScene = accident?.didPoliceVisitScene ? 'Yes' : 'No';
      responseObject.wereMeasurementsTaken = accident?.hadMeasurementsTaken
        ? 'Yes'
        : 'No';
      responseObject.whichStationAccidentReported = accident?.report?.policeStation;
      responseObject.policeOfficerRank = accident?.report?.policeOfficer?.rank?.title;
      responseObject.policeOfficerRankOther =
        accident?.report?.policeOfficer?.rank?.other;
      responseObject.policeOfficerName = accident?.report?.policeOfficer?.name;
      responseObject.policeOfficerNumber = accident?.report?.policeOfficer?.badgeNo;
      responseObject.descriptionOfAccident = accident?.descriptionOfAccident;
      responseObject.damageToVehicle = accident?.damageToVehicleOrProperty ? 'Yes' : 'No';
      responseObject.descDamageToVehicleOrProperty = accident?.descriptionOfDamage;
      responseObject.obtainedEstimateForRepairs = accident?.hasRepairEstimate
        ? 'Yes'
        : 'No';
      responseObject.garageEstimate = accident?.estimatedAmount;
      responseObject.driverUnderInfluenceCharged = accident?.accountability
        ?.driverChargedByPolice
        ? 'Yes'
        : 'No';
      responseObject.guardianDriverUnderInfluence =
        accident?.accountability?.guardianInsuredUnderInfluence;
      responseObject.guardianDriverUnderInfluenceCharged = accident?.accountability
        ?.guardianInsuredChargedByPolice
        ? 'Yes'
        : 'No';
      responseObject.driverUnderInfluence =
        accident?.accountability?.driverUnderTheInfluence;
    }

    responseObject.injuredPersons =
      createInjuredPersons(thirdPartyInjury, claim?.subtype) || [];
    responseObject.injuredPersonsData = responseObject.injuredPersons && [
      ...responseObject.injuredPersons,
    ];
    responseObject.wasAnyoneInjured = hadThirdPartyInjury ? 'Yes' : 'No';
    responseObject.uploadedFormsThirdParty = createUploadedFiles(uploadedFiles);
    responseObject.anyWitnesses = hadWitnesses ? 'Yes' : 'No';
    responseObject.witnessesData = createWitnessData(witnesses) || [];
    responseObject.witnesses = responseObject.witnessesData && [
      ...responseObject.witnessesData,
    ];
    responseObject.numberOfPersonsInjured = numberOfPersonsInjured;
  }
  if (claim?.subtype === 'Windscreen') {
    if (insured) {
      responseObject.vehicleRegNo = insured?.vehicleRegistrationNumber;
      responseObject.firstName = insured?.firstName;
      responseObject.lastName = insured?.lastName;
      responseObject.primaryContactNumber = getContactNumberDetails(
        insured?.contactNumbers,
        true,
      );
      responseObject.secondaryContactNumber = getContactNumberDetails(
        insured?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.extension = getContactNumberDetails(
        insured?.contactNumbers,
        false,
        true,
        'Residential',
      );
      responseObject.email = insured?.emailAddress;
      responseObject.pre_email = insured?.emailAddress;
      responseObject.previousWindscreenClaims = insured?.hasMadePreviousClaims
        ? 'Yes'
        : 'No';
    }
    if (driver) {
      responseObject.driverRelationshipToInsured = driver?.details?.relationshipToInsured;
      responseObject.driverFirstName = driver?.details?.firstName;
      responseObject.driverLastName = driver?.details?.lastName;
      responseObject.driverSex = driver?.details?.sex;
      responseObject.driverDob = driver?.details?.dateOfBirth;
      responseObject.driverInsured = driver?.isTheInsured ? 'Yes' : 'No';
      responseObject.driverPrimaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        true,
      );
      responseObject.driverSecondaryContactNumber = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Residential',
      );
      responseObject.driverExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        true,
        'Residential',
      );

      responseObject.driverBusinessContact = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        false,
        'Business',
      );

      responseObject.driverBusinessExtension = getContactNumberDetails(
        driver?.details?.contactNumbers,
        false,
        true,
        'Business',
      );

      responseObject.driverEmail = driver?.details?.emailAddress;
      responseObject.driverResidentialAddress = getAddress(
        driver?.details?.addresses,
        'Residential',
      );
      responseObject.driverBusinessAddress = getAddress(
        driver?.details?.addresses,
        'Business',
      );
      responseObject.driverOccupation = driver?.details?.occupation;
      responseObject.driverEmployer = driver?.details?.employer;

      responseObject.isTheInsured = driver?.isTheInsured ? 'Yes' : 'No';
      responseObject.doesDriverOwnVehicle = driver?.hasOwnVehicle;
      responseObject.driversPermit = {
        permitNumber: driver?.driversPermit?.permitNumber,
        dateOfFirstIssue: driver?.driversPermit?.dateOfFirstIssue,
        dateOfExpiry: driver?.driversPermit?.dateOfExpiry,
        dateOfRenewal: dateToString(
          isoStringToDate(driver?.driversPermit?.dateOfRenewal),
        ),
        allowedVehicleClass: driver?.driversPermit?.allowedVehicleClass,
      };
    }
    if (accident) {
      responseObject.dateAndTimeOfLoss = accident?.dateTimeOfLoss;
      responseObject.drivenSpeed = accident?.particularsOfUse?.speed;
      responseObject.purposeVehicleUsed = accident?.particularsOfUse?.purpose;
      responseObject.whichWindscreenOrGlassDamagedOther =
        accident?.damageToWindscreen?.other;
      responseObject.detailsOfDamage = accident?.damageToWindscreen?.detailsOfDamage;
      responseObject.crackedOrShattered = accident?.damageToWindscreen?.extentOfDamage;
      responseObject.location = accident?.locationOfLoss;
      responseObject.causeOfLoss = accident?.causeOfLoss;
      responseObject.wereMeasurementsTaken = accident?.hadMeasurementsTaken
        ? 'Yes'
        : 'No';
      responseObject.policeStationAccidentReported = accident?.report?.policeStation;
      responseObject.policeOfficerRank = accident?.report?.policeOfficer?.rank?.title;
      responseObject.policeOfficerRankOther =
        accident?.report?.policeOfficer?.rank?.other;
      responseObject.policeOfficerName = accident?.report?.policeOfficer?.name;
      responseObject.policeOfficerNumber = accident?.report?.policeOfficer?.badgeNo;
      responseObject.purposeOfUse = accident?.particularsOfUse?.purpose;
      responseObject.parkedOrDriven = accident?.particularsOfUse?.vehicleUsage;
      responseObject.howLossOccur = accident?.lossDescription;
      responseObject.accidentReportedToPolice = accident?.wasLossReported ? 'Yes' : 'No';
      responseObject.whichWindscreenOrGlassDamaged =
        accident?.damageToWindscreen?.areaOfDamage;
    }
    responseObject.thirdParties =
      createThirdPartyDamage(thirdPartyDamage, 'Windscreen') || [];
    responseObject.thirdPartyData = responseObject.thirdParties && [
      ...responseObject.thirdParties,
    ];
    responseObject.hadThirdPartyDamage = hadThirdPartyDamage ? 'Yes' : 'No';
    responseObject.otherVehiclesInvolved = hadThirdPartyDamage ? 'Yes' : 'No';
  }
  // console.log('responseObject: ', responseObject);
  return responseObject;
}
