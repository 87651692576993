import {
  MARK_PAID_QUOTE_API_ERROR,
  MARK_PAID_QUOTE_API_REQUEST,
  MARK_PAID_QUOTE_API_SUCCESS,
} from '../../../constants/quoteActionNames';

// eslint-disable-next-line import/no-cycle
import { markAsPaidApi } from '../../../apis/quotes/index';

function markPaidQuotesRequest() {
  return {
    type: MARK_PAID_QUOTE_API_REQUEST,
    payload: true,
  };
}

function markPaidQuotesSuccess(quotes, params) {
  return {
    type: MARK_PAID_QUOTE_API_SUCCESS,
    payload: params,
  };
}

function markPaidQuotesError(err) {
  return {
    type: MARK_PAID_QUOTE_API_ERROR,
    payload: err,
  };
}

export function markPaidQuoteAction(params) {
  // const response = getPayloadResponse();
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(markPaidQuotesRequest());
    try {
      const data = await markAsPaidApi.markAsPaid(state, params);
      dispatch(markPaidQuotesSuccess(data, params));
      return data;
    } catch (error) {
      dispatch(markPaidQuotesError(error, params));
      throw error;
    }
  };
}

export default { markPaidQuoteAction };
