import React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Chip, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uuidv4 } from '../../../utils/functions';
import styles from './style';

const ChipList = ({
  items,
  onCustomClick,
  currentId,
  size = 'medium',
  justifyContent,
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles({ justifyContent });

  const handleChange = data => {
    if (onCustomClick) {
      onCustomClick();
    }
  };

  return (
    <Paper component="ul" classes={{ root: classes.root }} elevation={0}>
      {items.map(item => {
        return (
          <li key={uuidv4()}>
            <Chip
              icon={item?.icon && <Icon color="inherit">{item?.icon}</Icon>}
              size={size}
              component={RouterLink}
              to={item?.url}
              label={item?.title}
              onClick={() => handleChange(item)}
              classes={{
                root: classnames(classes.chip, {
                  [classes.active]: currentId === item?.id,
                  [classes.inActive]: currentId !== item?.id,
                }),
              }}
            />
          </li>
        );
      })}
    </Paper>
  );
};

ChipList.defaultProps = {};

export default ChipList;
