import { routerGetCall } from '../../axios';

export const getPendingRequestsUrl = `/cms/pending-requests`;
const getPendingRequests = (state, params) => {
  const url = getPendingRequestsUrl;
  return routerGetCall(state, url, params);
};

const getSinglePendingRequest = (state, params) => {
  const url = `${getPendingRequestsUrl}/${params.id}`;
  delete params.id;
  return routerGetCall(state, url, params);
};

export default { getPendingRequests, getSinglePendingRequest };
