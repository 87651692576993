export const GET_REQUESTS_API_REQUEST = 'GET_REQUESTS_API_REQUEST';
export const GET_REQUESTS_API_SUCCESS = 'GET_REQUESTS_API_SUCCESS';
export const GET_REQUESTS_API_ERROR = 'GET_REQUESTS_API_ERROR';
export const GET_NEXT_REQUESTS_SUCCESS = 'GET_NEXT_REQUESTS_SUCCESS';

export const GET_REQUEST_HISTORY_API_REQUEST = 'GET_REQUEST_HISTORY_API_REQUEST';
export const GET_REQUEST_HISTORY_API_SUCCESS = 'GET_REQUEST_HISTORY_API_SUCCESS';
export const GET_REQUEST_HISTORY_API_ERROR = 'GET_REQUEST_HISTORY_API_ERROR';

export const GET_REQUEST_HISTORY_DETAILS_API_REQUEST =
  'GET_REQUEST_HISTORY_DETAILS_API_REQUEST';
export const GET_REQUEST_HISTORY_DETAILS_API_SUCCESS =
  'GET_REQUEST_HISTORY_DETAILS_API_SUCCESS';
export const GET_REQUEST_HISTORY_DETAILS_API_ERROR =
  'GET_REQUEST_HISTORY_DETAILS_API_ERROR';

export const SUBMIT_REQUEST_API_REQUEST = 'SUBMIT_REQUEST_API_REQUEST';
export const SUBMIT_REQUEST_API_ERROR = 'SUBMIT_REQUEST_API_ERROR';
export const SUBMIT_REQUEST_API_SUCCESS = 'SUBMIT_REQUEST_API_SUCCESS';

export const GET_PENDING_REQUEST_HISTORY_API_REQUEST =
  'GET_PENDING_REQUEST_HISTORY_API_REQUEST';
export const GET_PENDING_REQUEST_HISTORY_API_SUCCESS =
  'GET_PENDING_REQUEST_HISTORY_API_SUCCESS';
export const GET_PENDING_REQUEST_HISTORY_API_ERROR =
  'GET_PENDING_REQUEST_HISTORY_API_ERROR';

export const GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST =
  'GET_PENDING_REQUEST_HISTORY_DETAILS_API_REQUEST';
export const GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS =
  'GET_PENDING_REQUEST_HISTORY_DETAILS_API_SUCCESS';
export const GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR =
  'GET_PENDING_REQUEST_HISTORY_DETAILS_API_ERROR';

export const APPROVE_PENDING_REQUEST_API_REQUEST = 'APPROVE_PENDING_REQUEST_API_REQUEST';
export const APPROVE_PENDING_REQUEST_API_SUCCESS = 'APPROVE_PENDING_REQUEST_API_SUCCESS';
export const APPROVE_PENDING_REQUEST_API_ERROR = 'APPROVE_PENDING_REQUEST_API_ERROR';

export const REJECT_PENDING_REQUEST_API_REQUEST = 'REJECT_PENDING_REQUEST_API_REQUEST';
export const REJECT_PENDING_REQUEST_API_SUCCESS = 'REJECT_PENDING_REQUEST_API_SUCCESS';
export const REJECT_PENDING_REQUEST_API_ERROR = 'REJECT_PENDING_REQUEST_API_ERROR';

export const CANCEL_PENDING_REQUEST_API_REQUEST = 'CANCEL_PENDING_REQUEST_API_REQUEST';
export const CANCEL_PENDING_REQUEST_API_SUCCESS = 'CANCEL_PENDING_REQUEST_API_SUCCESS';
export const CANCEL_PENDING_REQUEST_API_ERROR = 'CANCEL_PENDING_REQUEST_API_ERROR';

export const GET_INVEST_REQUEST_SETTING_API_REQUEST =
  'GET_INVEST_REQUEST_SETTING_API_REQUEST';
export const GET_INVEST_REQUEST_SETTING_API_SUCCESS =
  'GET_INVEST_REQUEST_SETTING_API_SUCCESS';
export const GET_INVEST_REQUEST_SETTING_API_ERROR =
  'GET_INVEST_REQUEST_SETTING_API_ERROR';
