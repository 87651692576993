import { routerPostCall } from '../../axios';

const pendingRequestUrl = `/cms/pending-requests`;

const approvePendingRequest = (state, params) => {
  const url = `${pendingRequestUrl}/${params.id}/approve`;
  return routerPostCall(state, url, params);
};

const rejectPendingRequest = (state, params) => {
  const url = `${pendingRequestUrl}/${params.id}/reject`;
  return routerPostCall(state, url, params);
};

const cancelPendingRequest = (state, params) => {
  const url = `${pendingRequestUrl}/${params.id}/cancel`;
  return routerPostCall(state, url, params);
};

export default { approvePendingRequest, rejectPendingRequest, cancelPendingRequest };
