import { routerPostCall } from '../../axios';

export const declineQuotesUrl = `/accounts/quotes/decline`;

const declineQuotes = (state, params) => {
    const url = declineQuotesUrl;
    return routerPostCall(state, url, params);
};

export default { declineQuotes };
