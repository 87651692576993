import { routerPostCall } from '../../axios';

export const markQuotePaidUrl = `/accounts/payment/markPaid`;

const markAsPaid = (state, params) => {
  const url = markQuotePaidUrl;
  return routerPostCall(state, url, params);
};

export default { markAsPaid };
