import { allowCaching } from '../variables';
import { getSessionStorage } from '../../storage/sessionStorage';
import { createRelativeUrl } from '.';

export const DEFAULT_DURATION_MS = 600000; // 10 mins
export const DEFAULT_CACHE_KEY = 'cacheUntil';
export const INVALIDATE_CACHE = '@@redux-cache/INVALIDATE_CACHE';

export const generateCacheKey = ({ path, cacheParams = {}, state }) => {
  const guestSessionId = getSessionStorage('globalGuestSessionId') || null;
  cacheParams.userKey = state?.auth?.user?.id || guestSessionId;
  const cacheKey = createRelativeUrl({
    path,
    queryObject: cacheParams,
  });

  return cacheKey;
};
const defaultAccessStrategy = (state, reducerKey, cacheKey) => {
  return (
    state &&
    state[reducerKey] &&
    state[reducerKey][DEFAULT_CACHE_KEY] &&
    state[reducerKey][DEFAULT_CACHE_KEY][cacheKey]
  );
};

export const checkCacheValid = (getState, reducerKey, cacheKey = DEFAULT_CACHE_KEY) => {
  const state = getState();

  const cacheUntil = defaultAccessStrategy(state, reducerKey, cacheKey);

  const currentTime = Date.now();
  const cachePayload = cacheUntil?.payload;

  return {
    isCacheValid: !!cacheUntil && cacheUntil?.time > currentTime && allowCaching,
    cache: cachePayload,
  };
};

export const generateCacheTTL = (duration = DEFAULT_DURATION_MS) => {
  return Date.now() + duration;
};

export default {
  checkCacheValid,
  generateCacheTTL,
};
