export default theme => ({
  dutchItem: () => ({
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      // width: theme.spacing(37.5),
      paddingTop: '0px !important',
      height: '16.5rem',
    },
    [theme.breakpoints.up('md')]: {
      // width: '350px',
      height: '16.5rem !important',
      // height: '300px',
      // padding: '0px  !important',
      // margin: '10px 0px',
    },
  }),
  dutchItemContainer: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      // maxWidth: '1500px !important',
      // margin: 'auto',
      // display: 'flex',
      // justifyContent: 'space-between',
      paddingBottom: '4.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      rowGap: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      rowGap: '0.5rem',
    },
  },
  loaderCard: {
    marginTop: '1rem',
  },
});
